import BHV_avg_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/avg_temp/Bahawalpur Avg Temp.png"
import DGK_avg_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/avg_temp/Dera Ghazi KHan Avg Temp.png"
import FSD_avg_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/avg_temp/Faisalabad Avg Temp.png"
import GRW_avg_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/avg_temp/Gujranwala Avg Temp.png"
import LHR_avg_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/avg_temp/Lahore Avg Temp.png"
import MUL_avg_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/avg_temp/Multan Avg Temp.png"
import RWP_avg_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/avg_temp/Rawalpindi Avg Temp.png"
import SWH_avg_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/avg_temp/Sahiwal_AVg Termp.png"
import SGD_avg_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/avg_temp/Sarghoda Avg Temp.png"

import BHV_humidity_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/humidity/Bhawalpur Avg Humidity.png"
import DGK_humidity_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/humidity/Dera GHazi KHan Avg Humidity.png"
import FSD_humidity_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/humidity/Faisalabad Avg Humidity.png"
import GRW_humidity_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/humidity/Gujranwala Avg Humidity.png"
import LHR_humidity_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/humidity/Lahore Avg Humidity.png"
import MUL_humidity_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/humidity/Multan Avg Humidity.png"
import RWP_humidity_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/humidity/Rawalpinid Avg Humidity.png"
import SWH_humidity_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/humidity/Sahiwal Avg Humidity.png"
import SGD_humidity_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/humidity/Sarghoda Avg Humidity.png"

import BHV_max_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/max_temp/Bhawalpur Avg Tmax.png"
import DGK_max_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/max_temp/Dera GHazi Khan Avg Tmax.png"
import FSD_max_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/max_temp/Faisalabad Avg Tmax.png"
import GRW_max_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/max_temp/Gujranwala Avg Tmax.png"
import LHR_max_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/max_temp/Lahore Avg Tmax.png"
import MUL_max_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/max_temp/Multan Avg Tmax.png"
import RWP_max_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/max_temp/Rawalpindi Avg Tmax.png"
import SWH_max_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/max_temp/Sahiwal Avg Tmax.png"
import SGD_max_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/max_temp/Sarghoda Avg Tmax.png"

import BHV_min_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/min_temp/Bahawalpur Avg Tmin.png"
import DGK_min_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/min_temp/Dera Ghazi Khan Avg Tmin.png"
import FSD_min_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/min_temp/Faisalabad Avg Tmin.png"
import GRW_min_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/min_temp/Gujranwala Avg Tmin.png"
import LHR_min_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/min_temp/Lahore Avg Tmin.png"
import MUL_min_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/min_temp/Multan Avg Tmin.png"
import RWP_min_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/min_temp/Rawalpindi Avg Tmin.png"
import SWH_min_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/min_temp/Sahiwal Avg Tmin.png"
import SGD_min_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Element/13Nov23_20Nov23/min_temp/Sarghoda Avg Tmin.png"

const elementWeather = [
  {
    src: BHV_avg_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Avg Temperature", title: "Avg Temperature" }],
    district: "BHV",
    element: "avg_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: DGK_avg_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Avg Temperature", title: "Avg Temperature" }],
    district: "DGK",
    element: "avg_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: FSD_avg_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Avg Temperature", title: "Avg Temperature" }],
    district: "FSD",
    element: "avg_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: GRW_avg_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Avg Temperature", title: "Avg Temperature" }],
    district: "GRW",
    element: "avg_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: LHR_avg_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Avg Temperature", title: "Avg Temperature" }],
    district: "LHR",
    element: "avg_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: MUL_avg_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Avg Temperature", title: "Avg Temperature" }],
    district: "MUL",
    element: "avg_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: RWP_avg_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Avg Temperature", title: "Avg Temperature" }],
    district: "RWP",
    element: "avg_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: SWH_avg_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Avg Temperature", title: "Avg Temperature" }],
    district: "SWH",
    element: "avg_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: SGD_avg_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Avg Temperature", title: "Avg Temperature" }],
    district: "SDG",
    element: "avg_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: BHV_humidity_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Humidity", title: "Humidity" }],
    district: "BHV",
    element: "humidity",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: DGK_humidity_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Humidity", title: "Humidity" }],
    district: "DGK",
    element: "humidity",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: FSD_humidity_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Humidity", title: "Humidity" }],
    district: "FSD",
    element: "humidity",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: GRW_humidity_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Humidity", title: "Humidity" }],
    district: "GRW",
    element: "humidity",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: LHR_humidity_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Humidity", title: "Humidity" }],
    district: "LHR",
    element: "humidity",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: MUL_humidity_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Humidity", title: "Humidity" }],
    district: "MUL",
    element: "humidity",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: RWP_humidity_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Humidity", title: "Humidity" }],
    district: "RWP",
    element: "humidity",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: SWH_humidity_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Humidity", title: "Humidity" }],
    district: "SWH",
    element: "humidity",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: SGD_humidity_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Humidity", title: "Humidity" }],
    district: "SDG",
    element: "humidity",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: BHV_max_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Max Temperature", title: "Max Temperature" }],
    district: "BHV",
    element: "max_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: DGK_max_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Max Temperature", title: "Max Temperature" }],
    district: "DGK",
    element: "max_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: FSD_max_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Max Temperature", title: "Max Temperature" }],
    district: "FSD",
    element: "max_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: GRW_max_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Max Temperature", title: "Max Temperature" }],
    district: "GRW",
    element: "max_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: LHR_max_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Max Temperature", title: "Max Temperature" }],
    district: "LHR",
    element: "max_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: MUL_max_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Max Temperature", title: "Max Temperature" }],
    district: "MUL",
    element: "max_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: RWP_max_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Max Temperature", title: "Max Temperature" }],
    district: "RWP",
    element: "max_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: SWH_max_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Max Temperature", title: "Max Temperature" }],
    district: "SWH",
    element: "max_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: SGD_max_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Max Temperature", title: "Max Temperature" }],
    district: "SDG",
    element: "max_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: BHV_min_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Min Temperature", title: "Min Temperature" }],
    district: "BHV",
    element: "min_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: DGK_min_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Min Temperature", title: "Min Temperature" }],
    district: "DGK",
    element: "min_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: FSD_min_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Min Temperature", title: "Min Temperature" }],
    district: "FSD",
    element: "min_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: GRW_min_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Min Temperature", title: "Min Temperature" }],
    district: "GRW",
    element: "min_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: LHR_min_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Min Temperature", title: "Min Temperature" }],
    district: "LHR",
    element: "min_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: MUL_min_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Min Temperature", title: "Min Temperature" }],
    district: "MUL",
    element: "min_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: RWP_min_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Min Temperature", title: "Min Temperature" }],
    district: "RWP",
    element: "min_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: SWH_min_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Min Temperature", title: "Min Temperature" }],
    district: "SWH",
    element: "min_temp",
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: SGD_min_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Min Temperature", title: "Min Temperature" }],
    district: "SDG",
    element: "min_temp",
    date : "11/13/2023 to 11/20/2023"
  }
];

export default elementWeather;