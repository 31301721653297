import Header from "../utils/Header";
import Footer from "../utils/Footer";
import Main from "./Main";
import ImageWithOverlay from "../utils/ImageWithOverlay";
import WeatherAdvisory from '../../public/images/WeatherAdvisory.jpg';
export default function CompleteWeatherAdvisory() {
  return (
    <>
      <Header />
      <ImageWithOverlay image={WeatherAdvisory} height="300px" titlePartOne="Weather" titlePartTwo="Advisory" />
      <Main />
      <Footer />
    </>
  );
}
