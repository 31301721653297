import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function BreadCrumbs({
  province,
  setProvince,
  choice,
  setChoice,
  dateFilter,
  setDateFilter,
  districtFilter,
  setDistrictFilter,
  elementFilter,
  setElementFilter,
}) {
  const handleProvincesClick = () => {
    setProvince(false);
    setChoice(false);
    setDateFilter("All");
    setDistrictFilter("All");
    setElementFilter("All");
  };
  const handleProvinceClick = () => {
    setChoice(false);
    setDateFilter("All");
    setDistrictFilter("All");
    setElementFilter("All");
  };
  let breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color={province == false ? "text.primary" : "inherit"}
      onClick={handleProvincesClick}
      style={{
        cursor: "pointer",
      }}
    >
      Provinces
    </Link>,
    <Link
      underline="hover"
      key="2"
      color={choice == false ? "text.primary" : "inherit"}
      onClick={handleProvinceClick}
      style={{
        cursor: "pointer",
      }}
    >
      {province.name}
    </Link>,
    <Typography underline="hover" key="3" color={"inherit"}>
      {choice.name}
    </Typography>,
  ];

  if (choice === false) {
    breadcrumbs.pop();
  } else {
    if (choice.id === 1) {
      breadcrumbs.push(
        <Typography underline="hover" key="4" color={"text.primary"}>
          {districtFilter}
        </Typography>
      );
      breadcrumbs.push(
        <Typography underline="hover" key="4" color={"text.primary"}>
          {dateFilter}
        </Typography>
      );
      
    } else {
      breadcrumbs.push(
        <Typography underline="hover" key="4" color={"text.primary"}>
          {districtFilter}
        </Typography>
      );

      breadcrumbs.push(
        <Typography underline="hover" key="5" color={"text.primary"}>
          {elementFilter}
        </Typography>
      );
    }
  }

  if (province === false) {
    breadcrumbs.pop();
  }

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}
