import B from "../../public/land/punjab/soil/Tehsil/Boron1.png";
import EC from "../../public/land/punjab/soil/Tehsil/Electric1.png";
import Fe from "../../public/land/punjab/soil/Tehsil/Iron1.png";
import Mn from "../../public/land/punjab/soil/Tehsil/Maganese1.png";
import OM from "../../public/land/punjab/soil/Tehsil/Organic Matter1.png";
import PH from "../../public/land/punjab/soil/Tehsil/Soil PH1.png";
import P from "../../public/land/punjab/soil/Tehsil/Phosphorus1.png";
import K from "../../public/land/punjab/soil/Tehsil/potassium1.png";
import Zn from "../../public/land/punjab/soil/Tehsil/zinc1.png";

const tehsilSoil = [
  {
    src: B,
    width: 8,
    height: 7,
    tags: [{ value: "Boron", title: "Boron" }],
    district: "PB",
    element: "B",
  },
  {
    src: EC,
    width: 8,
    height: 7,
    tags: [{ value: "EC", title: "EC" }],
    district: "PB",
    element: "EC",
  },
  {
    src: Fe,
    width: 8,
    height: 7,
    tags: [{ value: "Iron", title: "Iron" }],
    district: "PB",
    element: "Fe",
  },
  {
    src: Mn,
    width: 8,
    height: 7,
    tags: [{ value: "Manganese", title: "Manganese" }],
    district: "PB",
    element: "Mn",
  },
  {
    src: OM,
    width: 8,
    height: 7,
    tags: [{ value: "OM", title: "OM" }],
    district: "PB",
    element: "OM",
  },
  {
    src: PH,
    width: 8,
    height: 7,
    tags: [{ value: "pH", title: "pH" }],
    district: "PB",
    element: "pH",
  },
  {
    src: P,
    width: 8,
    height: 7,
    tags: [{ value: "Phosporus", title: "Phosporus" }],
    district: "PB",
    element: "P",
  },
  {
    src: K,
    width: 8,
    height: 7,
    tags: [{ value: "Potassium", title: "Potassium" }],
    district: "PB",
    element: "K",
  },
  {
    src: Zn,
    width: 8,
    height: 7,
    tags: [{ value: "Zinc", title: "Zinc" }],
    district: "PB",
    element: "Zn",
  }
];

export default tehsilSoil;
