import * as L from 'leaflet';
export { default as INTRO_VIDEO } from "../../public/videos/intro.mp4";
export { default as WEATHER_MAPS } from "../../public/images/weather_maps.png";
export { default as SOIL } from "../../public/images/soil.jpg";
export { default as VEGETATION_IMAGE } from "../../public/images/vegetation_and_monitoring.jpeg";
export { default as SMART_SOLUTIONS } from "../../public/images/SmartSolutions.png";
export { default as KISSAN_01 } from "../../public/kissan/kissan01.jpeg";
export { default as KISSAN_02 } from "../../public/kissan/kissan02.jpeg";
export { default as KISSAN_03 } from "../../public/kissan/kissan03.jpeg";
export { default as BACKGROUND_WHOLE } from "../../public/images/background_whole.jpg";
export { default as FARM_RECOMMENDATION } from "../../public/images/farm_recommendation.jpg";
export { default as WHITE_LOGO } from "../../public/images/white_logo.svg";
export { default as LogoAgri } from "../../public/images/LogoAgri.png";
export { default as COLORED_LOGO } from "../../public/images/logo.png";
export { default as COTTON } from "../../public/images/Cotton.jpg";
export { default as LAND_CONS } from "../../public/images/LandCons.jpg";
export { default as DAM } from "../../public/images/dam.png";
export { default as PEST_SCOUTING } from "../../public/images/PestScouting.png";
export { default as PRESS_RELEASE_00 } from "../../public/press/press-release00.mp4";
export { default as PRESS_RELEASE_001 } from "../../public/press/press-release001.mp4";
export { default as PRESS_RELEASE_01 } from "../../public/press/press-release01.mp4";
export { default as PRESS_RELEASE_03 } from "../../public/press/press-release03.mp4";
export { default as PRESS_RELEASE_04 } from "../../public/press/press-release04.mp4";
export { default as PRESS_RELEASE_05 } from "../../public/press/press-release05.mp4";
export { default as PRESS_RELEASE_06 } from "../../public/press/press-release06.mp4";
export { default as PRESS_RELEASE_07 } from "../../public/press/press-release07.mp4";
export { default as PRESS_RELEASE_08 } from "../../public/press/press-release08.mp4";
export { default as PRESS_RELEASE_09 } from "../../public/press/press-release09.mp4";
export { default as PRESS_RELEASE_10 } from "../../public/press/press-release10.mp4";
export { default as PRESS_RELEASE_11 } from "../../public/press/press-release11.mp4";
export { default as THUMBNAIL_00 } from "../../public/press/Thumbnail00.png";
export { default as THUMBNAIL_001 } from "../../public/press/Thumbnail001.png";
export { default as THUMBNAIL_01 } from "../../public/press/Thumbnail01.png";
export { default as THUMBNAIL_03 } from "../../public/press/Thumbnail03.png";
export { default as THUMBNAIL_04 } from "../../public/press/Thumbnail04.png";
export { default as THUMBNAIL_05 } from "../../public/press/Thumbnail05.png";
export { default as THUMBNAIL_06 } from "../../public/press/Thumbnail06.png";
export { default as THUMBNAIL_07 } from "../../public/press/Thumbnail07.png";
export { default as THUMBNAIL_08 } from "../../public/press/Thumbnail08.png";
export { default as THUMBNAIL_09 } from "../../public/press/Thumbnail09.png";
export { default as THUMBNAIL_10 } from "../../public/press/Thumbnail10.png";
export { default as THUMBNAIL_11 } from "../../public/press/Thumbnail11.png";
export { default as RECENT_EVENT_01 } from "../../public/press/recent_event1.jpeg";
export { default as RECENT_EVENT_02 } from "../../public/press/recent_event2.jpeg";
export { default as RECENT_EVENT_03 } from "../../public/press/recent_event3.jpeg";
export { default as RECENT_EVENT_04 } from "../../public/press/recent_event4.jpeg";
export { default as RECENT_EVENT_05 } from "../../public/press/recent_event5.jpeg";
export { default as RECENT_EVENT_06 } from "../../public/press/recent_event6.jpeg";
export { default as RECENT_EVENT_07 } from "../../public/press/recent_event7.jpeg";
export { default as RECENT_EVENT_08 } from "../../public/press/recent_event8.jpeg";
export { default as RECENT_EVENT_09 } from "../../public/press/recent_event9.jpeg";
export { default as PRESS_RELEASE_IMAGE_01 } from "../../public/press/press_release1.jpeg";
export { default as PRESS_RELEASE_IMAGE_02 } from "../../public/press/press_release2.jpeg";
export { default as PRESS_RELEASE_IMAGE_03 } from "../../public/press/press_release3.jpeg";
export { default as PRESS_RELEASE_IMAGE_04 } from "../../public/press/press_release4.jpeg";
export { default as PRESS_RELEASE_IMAGE_05 } from "../../public/press/press_release5.jpeg";
export { default as PRESS_RELEASE_IMAGE_06 } from "../../public/press/press_release6.jpeg";
export { default as PRESS_RELEASE_IMAGE_07 } from "../../public/press/press_release7.jpeg";
export { default as PRESS_RELEASE_IMAGE_08 } from "../../public/press/press_release8.jpeg";
export { default as PRESS_RELEASE_IMAGE_09 } from "../../public/press/press_release9.jpeg";
export { default as PRESS_RELEASE_IMAGE_10 } from "../../public/press/press_release10.jpeg";
export { default as PRESS_RELEASE_IMAGE_11 } from "../../public/press/press_release11.jpeg";
export { default as PRESS_RELEASE_IMAGE_12 } from "../../public/press/press_release12.jpeg";
export { default as PRESS_RELEASE_IMAGE_13 } from "../../public/press/press_release13.jpeg";
export { default as PRESS_RELEASE_IMAGE_14 } from "../../public/press/press_release14.jpeg";
export { default as PRESS_RELEASE_IMAGE_15 } from "../../public/press/press_release15.jpeg";
export { default as PRESS_RELEASE_IMAGE_16 } from "../../public/press/press_release16.jpeg";
export { default as VIDEO_GALLERY_THUMBNAIL11 } from "../../public/videogallery/images/news11.png";
export { default as VIDEO_GALLERY_THUMBNAIL12 } from "../../public/videogallery/images/news12.png";
export { default as GALLERY_VIDEO11 } from "../../public/videogallery/video/video11.mp4";
export { default as GALLERY_VIDEO12 } from "../../public/videogallery/video/video12.mp4";
export default L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
const ASSET_URL = "../../public/";
export const BASE_URL = "https://limspakistan.com/admin/api";
export const END_POINT = "/contactUs";
export const registerFarm = "/registerFarm";
export const getUserFarm = "/getUserFarm";
export const capSiteKey = "6LcU3lgoAAAAANQJflu8EL_EmVZWGIoZirN67Ag2"
//export const capSiteKey = "6LeAblAoAAAAAF1ih3b-3v-oHDKG4AmTV_bSui7K";
// export const INTRO_VIDEO = ASSET_URL + "videos/intro.mp4";
// export const WEATHER_MAPS = ASSET_URL + "images/weather_maps.png";
// export const SOIL = ASSET_URL + "images/soil.jpg";
// export const VEGETATION_IMAGE = ASSET_URL + "images/vegetation_and_monitoring.jpeg";
// export const SMART_SOLUTIONS = ASSET_URL + "images/SmartSolutions.png";
// export const KISSAN_01 = ASSET_URL + "kissan/kissan01.jpeg"
// export const KISSAN_02 = ASSET_URL + "kissan/kissan02.jpeg"
// export const KISSAN_03 = ASSET_URL + "kissan/kissan03.jpeg"
// export const BACKGROUND_WHOLE = ASSET_URL + "images/background_whole.jpg"
// export const FARM_RECOMMENDATION = ASSET_URL + "images/farm_recommendation.jpg"
// export const WHITE_LOGO = ASSET_URL + "images/white_logo.svg"
// export const COLORED_LOGO = ASSET_URL + "images/logo.png"
// export const COTTON = ASSET_URL + "images/Cotton.jpg";
// export const LAND_CONS = ASSET_URL + "images/LandCons.jpg"
// export const DAM = ASSET_URL + "images/dam.png"
// export const PEST_SCOUTING = ASSET_URL + "images/PestScouting.png"
// export const PRESS_RELEASE_00 = ASSET_URL + "press/press-release00.mp4";
// export const PRESS_RELEASE_001 = ASSET_URL + "press/press-release001.mp4";
// export const PRESS_RELEASE_01 = ASSET_URL + "press/press-release01.mp4";
// export const PRESS_RELEASE_03 = ASSET_URL + "press/press-release03.mp4";
// export const PRESS_RELEASE_04 = ASSET_URL + "press/press-release04.mp4";
// export const PRESS_RELEASE_05 = ASSET_URL + "press/press-release05.mp4";
// export const PRESS_RELEASE_06 = ASSET_URL + "press/press-release06.mp4";
// export const PRESS_RELEASE_07 = ASSET_URL + "press/press-release07.mp4";
// export const PRESS_RELEASE_08 = ASSET_URL + "press/press-release08.mp4";
// export const PRESS_RELEASE_09 = ASSET_URL + "press/press-release09.mp4";
// export const PRESS_RELEASE_10 = ASSET_URL + "press/press-release10.mp4";
// export const PRESS_RELEASE_11 = ASSET_URL + "press/press-release11.mp4";
// export const THUMBNAIL_00 = ASSET_URL + "press/Thumbnail00.png";
// export const THUMBNAIL_001 = ASSET_URL + "press/Thumbnail001.png";
// export const THUMBNAIL_01 = ASSET_URL + "press/Thumbnail01.png";
// export const THUMBNAIL_03 = ASSET_URL + "press/Thumbnail03.png";
// export const THUMBNAIL_04 = ASSET_URL + "press/Thumbnail04.png";
// export const THUMBNAIL_05 = ASSET_URL + "press/Thumbnail05.png";
// export const THUMBNAIL_06 = ASSET_URL + "press/Thumbnail06.png";
// export const THUMBNAIL_07 = ASSET_URL + "press/Thumbnail07.png";
// export const THUMBNAIL_08 = ASSET_URL + "press/Thumbnail08.png";
// export const THUMBNAIL_09 = ASSET_URL + "press/Thumbnail09.png";
// export const THUMBNAIL_10 = ASSET_URL + "press/Thumbnail10.png";
// export const THUMBNAIL_11 = ASSET_URL + "press/Thumbnail11.png";
// export const RECENT_EVENT_01 = ASSET_URL + "press/recent_event1.jpeg";
// export const RECENT_EVENT_02 = ASSET_URL + "press/recent_event2.jpeg";
// export const RECENT_EVENT_03 = ASSET_URL + "press/recent_event3.jpeg";
// export const RECENT_EVENT_04 = ASSET_URL + "press/recent_event4.jpeg";
// export const RECENT_EVENT_05 = ASSET_URL + "press/recent_event5.jpeg";
// export const RECENT_EVENT_06 = ASSET_URL + "press/recent_event6.jpeg";
// export const RECENT_EVENT_07 = ASSET_URL + "press/recent_event7.jpeg";
// export const RECENT_EVENT_08 = ASSET_URL + "press/recent_event8.jpeg";
// export const RECENT_EVENT_09 = ASSET_URL + "press/recent_event9.jpeg";
// export const PRESS_RELEASE_IMAGE_01 = ASSET_URL + "press/press_release1.jpeg";
// export const PRESS_RELEASE_IMAGE_02 = ASSET_URL + "press/press_release2.jpeg";
// export const PRESS_RELEASE_IMAGE_03 = ASSET_URL + "press/press_release3.jpeg";
// export const PRESS_RELEASE_IMAGE_04 = ASSET_URL + "press/press_release4.jpeg";
// export const PRESS_RELEASE_IMAGE_05 = ASSET_URL + "press/press_release5.jpeg";
// export const PRESS_RELEASE_IMAGE_06 = ASSET_URL + "press/press_release6.jpeg";
// export const PRESS_RELEASE_IMAGE_07 = ASSET_URL + "press/press_release7.jpeg";
// export const PRESS_RELEASE_IMAGE_08 = ASSET_URL + "press/press_release8.jpeg";
// export const VIDEO_GALLERY_THUMBNAIL11 = ASSET_URL +  "videogallery/images/news11.png";
// export const VIDEO_GALLERY_THUMBNAIL12 = ASSET_URL + "videogallery/images/news12.png";
// export const GALLERY_VIDEO11 = ASSET_URL + "videogallery/video/video11.mp4";
// export const GALLERY_VIDEO12 = ASSET_URL + "videogallery/video/video12.mp4";