import React, { useState, useEffect } from "react";
// import Tesseract from "tesseract.js";
// import { utils as XLSXUtils, write as writeToBuffer } from "xlsx";
// import XLSX from "xlsx";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import * as XLSX from "xlsx";
import Header from "../utils/Header";
import FooterAgri from "../utils/FooterAgri";
import ImageWithOverlayPunjabAgri from "../utils/ImageWithOverlayPunjabAgri";
import AboutUs from "../../public/images/AboutUs.jpg";
import logoLeft from "../../public/images/limsLogoGroup.png";
import logoRight from "../../public/images/punjabAgriGroup.png";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

import ndviSepMap from "../../../src/public/punjabAgri/NDVI_Sep_Map_2023.png";
import ndviSepTable from "../../../src/public/punjabAgri/NDVI_Sep_Table.png";
import ndviOctMap from "../../../src/public/punjabAgri/NDVI_Oct_Map_2023.png";
import ndviOctTable from "../../../src/public/punjabAgri/NDVI_Oct_Table.png";
import ndviNovMap from "../../../src/public/punjabAgri/NDVI_Nov_Map_2023.png";
import ndviNovTable from "../../../src/public/punjabAgri/NDVI_Nov_Table.png";
import ndviDecMap from "../../../src/public/punjabAgri/NDVI_Dec_Map_2023.png";
import ndviDecTable from "../../../src/public/punjabAgri/NDVI_Dec_Table.png";
import excelfile1 from "../../../src/public/excelFiles/lims_landsat_sep.xlsx";
import excelfile2 from "../../../src/public/excelFiles/lims_landsat_oct.xlsx";
import excelfile3 from "../../../src/public/excelFiles/lims_landsat_nov.xlsx";
import excelfile4 from "../../../src/public/excelFiles/lims_landsat_dec.xlsx";
import Box from "@mui/material/Box";

import {
  landsat_sep,
  landsat_oct,
  landsat_nov,
  landsat_dec,
} from "./LandsatData";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// Table styling
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Landsat(props) {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [excelData, setExcelData] = useState([]);

  const [rows, setRows] = useState([]);
  // Excel Data states
  const [dataExcel1, setDataExcel1] = useState(["hello"]);
  const [dataExcel2, setDataExcel2] = useState([]);
  const [dataExcel3, setDataExcel3] = useState([]);
  const [dataExcel4, setDataExcel4] = useState([]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      // loadExcelFile();

      const readExcelFile1 = async () => {
        try {
          const file = require("../../../src/public/excelFiles/lims_landsat_sep.xlsx");
          console.log(" file 1 path is ", file);
          const arrayBuffer = await fetch(file).then((res) =>
            res.arrayBuffer()
          );
          const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          setDataExcel1(jsonData);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      };

      const readExcelFile2 = async () => {
        try {
          const response = await fetch(
            "/v2/static/media/lims_landsat_oct.b805558e924ecd92e32c.xlsx"
          );
          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          setDataExcel2(jsonData);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      };

      const readExcelFile3 = async () => {
        try {
          const response = await fetch(excelfile3);
          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          setDataExcel3(jsonData);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      };

      const readExcelFile4 = async () => {
        try {
          const response = await fetch(excelfile4);
          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          setDataExcel4(jsonData);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      };

      readExcelFile1();
      readExcelFile2();
      readExcelFile3();
      readExcelFile4();
    };
  }, []);

  // console.log("NEW DATA readExcelFile1 function outside useEffect", dataExcel1);
  // console.log("NEW DATA readExcelFile2 function outside useEffect", dataExcel2);

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [selectedMonth, setSelectedMonth] = useState("September");

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  // New Table Style
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const downloadCSV1 = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      landsat_sep.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "landsat_sep.csv");
    document.body.appendChild(link);
    link.click();
  };

  const downloadCSV2 = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      landsat_oct.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "landsat_oct.csv");
    document.body.appendChild(link);
    link.click();
  };
  const downloadCSV3 = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      landsat_nov.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "landsat_nov.csv");
    document.body.appendChild(link);
    link.click();
  };
  const downloadCSV4 = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      landsat_dec.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "landsat_dec.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <Header />
      <ImageWithOverlayPunjabAgri
        image={AboutUs}
        height="300px"
        title="Remote Sensing of Punjab"
        titlePartOne="Remote Sensing of"
        titlePartTwo="Punjab"
        logoLeft={logoLeft}
        logoRight={logoRight}
      />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <img
              src={selectedImage}
              alt="Full Size"
              style={{ maxWidth: "100%" }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <div className="my-4 container">
        <select
          className={
            width < 768 ? "form-select w-50 mx-3" : "form-select w-25 mx-3"
          }
          value={selectedMonth}
          onChange={handleMonthChange}
        >
          <option value="September">September</option>
          <option value="October">October</option>
          <option value="November">November</option>
          <option value="December">December</option>
        </select>

        {selectedMonth === "September" ? (
          <div>
            {/* For Sep */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "25px",
              }}
            >
              <h2 style={{ marginTop: 0 }} className="mx-3">
                LANDSAT NDVI for Sep 2023
              </h2>
              <button
                variant="contained"
                color="primary"
                className="btn btn-primary"
                sx={{
                  backgroundColor: "#007bff",
                  "&:hover": { backgroundColor: "#0056b3" },
                }}
                onClick={downloadCSV1}
              >
                Download CSV
              </button>
            </Box>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div onClick={() => handleImageClick(ndviSepMap)}>
                  <img
                    src={ndviSepMap}
                    alt="Image 1"
                    style={{
                      maxWidth: "100%",
                      height: "670px",
                      cursor: "pointer",
                      width: "100%",
                    }}
                  />
                </div>
              </Grid>

              {/* New excel table 1  */}
              <Grid item xs={12} md={6} className="mt-4 ">
                <div>
                  <TableContainer
                    component={Paper}
                    sx={{
                      height: 650,
                      overflowX: isLargeScreen ? "hidden" : "auto",
                    }}
                  >
                    {landsat_sep.length > 0 ? (
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{ minWidth: 700 }}
                      >
                        {/* <TableHead stickyHeader>
                          <TableRow>
                            {dataExcel1.length > 0 &&
                              Object.keys(dataExcel1[0]).map((key, index) => (
                                <StyledTableCell key={index}>
                                  {key}
                                </StyledTableCell>
                              ))}
                          </TableRow>
                        </TableHead> */}
                        <TableHead stickyHeader>
                          <TableRow>
                            <StyledTableCell>DISTRICT</StyledTableCell>
                            <StyledTableCell>MIN</StyledTableCell>
                            <StyledTableCell>MAX</StyledTableCell>
                            <StyledTableCell>MEAN</StyledTableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {landsat_sep.length > 0 ? (
                            landsat_sep.map((row, index) => (
                              <StyledTableRow key={index}>
                                <StyledTableCell className="w-25 ">
                                  {row.district}
                                </StyledTableCell>
                                <StyledTableCell>{row.min}</StyledTableCell>
                                <StyledTableCell>{row.max}</StyledTableCell>
                                <StyledTableCell>{row.mean}</StyledTableCell>
                              </StyledTableRow>
                            ))
                          ) : (
                            <StyledTableRow>
                              <StyledTableCell
                                colSpan={Object.keys(dataExcel1[0]).length}
                              >
                                <Typography>Loading...</Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : selectedMonth === "October" ? (
          <div>
            {/* For Oct */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "25px",
              }}
            >
              <h2 style={{ marginTop: 0 }} className="mx-3">
                Landsat NDVI for Oct 2023
              </h2>
              <button
                variant="contained"
                color="primary"
                className="btn btn-primary"
                sx={{
                  backgroundColor: "#007bff",
                  "&:hover": { backgroundColor: "#0056b3" },
                }}
                onClick={downloadCSV2}
              >
                Download CSV
              </button>
            </Box>{" "}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div onClick={() => handleImageClick(ndviOctMap)}>
                  <img
                    src={ndviOctMap}
                    alt="Image 1"
                    style={{
                      maxWidth: "100%",
                      height: "670px",
                      cursor: "pointer",
                      width: "100%",
                    }}
                  />
                </div>
              </Grid>
              {/* New excel table 2  */}
              <Grid item xs={12} md={6} className="mt-4">
                <div>
                  <TableContainer component={Paper} sx={{ height: 650 }}>
                    {landsat_oct.length > 0 ? (
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          height: 650,
                          overflowX: isLargeScreen ? "hidden" : "auto",
                        }} // aria-label="customized table"
                      >
                        <TableHead stickyHeader>
                          <TableRow>
                            {landsat_oct.length > 0 &&
                              Object.keys(landsat_oct[0]).map((key, index) => (
                                <StyledTableCell key={index}>
                                  {key.toUpperCase()}
                                </StyledTableCell>
                              ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {landsat_oct.length > 0 ? (
                            landsat_oct.map((row, index) => (
                              <StyledTableRow key={index}>
                                {Object.values(row).map((value, index) => (
                                  <StyledTableCell key={index}>
                                    {value}
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            ))
                          ) : (
                            <StyledTableRow>
                              <StyledTableCell
                                colSpan={Object.keys(landsat_oct[0]).length}
                              >
                                <Typography>Loading...</Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : selectedMonth === "November" ? (
          <div>
            {/* For Nov */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "25px",
              }}
            >
              <h2 style={{ marginTop: 0 }} className="mx-3">
                Landsat NDVI for Nov 2023
              </h2>
              <button
                variant="contained"
                color="primary"
                className="btn btn-primary"
                sx={{
                  backgroundColor: "#007bff",
                  "&:hover": { backgroundColor: "#0056b3" },
                }}
                onClick={downloadCSV3}
              >
                Download CSV
              </button>
            </Box>{" "}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div onClick={() => handleImageClick(ndviNovMap)}>
                  <img
                    src={ndviNovMap}
                    alt="Image 1"
                    style={{
                      height: "670px",
                      maxWidth: "100%",
                      cursor: "pointer",
                      width: "100%",
                    }}
                  />
                </div>
              </Grid>

              {/* New excel table 3  */}
              <Grid item xs={12} md={6} className="mt-4">
                <div>
                  <TableContainer component={Paper} sx={{ height: 650 }}>
                    {landsat_nov.length > 0 ? (
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          height: 650,
                          overflowX: isLargeScreen ? "hidden" : "auto",
                        }} // aria-label="customized table"
                      >
                        <TableHead stickyHeader>
                          <TableRow>
                            {landsat_nov.length > 0 &&
                              Object.keys(landsat_nov[0]).map((key, index) => (
                                <StyledTableCell key={index}>
                                  {key}
                                </StyledTableCell>
                              ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {landsat_nov.length > 0 ? (
                            landsat_nov.map((row, index) => (
                              <StyledTableRow key={index}>
                                {Object.values(row).map((value, index) => (
                                  <StyledTableCell key={index}>
                                    {value}
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            ))
                          ) : (
                            <StyledTableRow>
                              <StyledTableCell
                                colSpan={Object.keys(landsat_nov[0]).length}
                              >
                                <Typography>Loading...</Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div>
            {/* For Dec */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "25px",
              }}
            >
              <h2 style={{ marginTop: 0 }} className="mx-3">
                Landsat NDVI for Dec 2023
              </h2>
              <button
                variant="contained"
                color="primary"
                className="btn btn-primary"
                sx={{
                  backgroundColor: "#007bff",
                  "&:hover": { backgroundColor: "#0056b3" },
                }}
                onClick={downloadCSV4}
              >
                Download CSV
              </button>
            </Box>{" "}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div onClick={() => handleImageClick(ndviDecMap)}>
                  <img
                    src={ndviDecMap}
                    alt="Image 1"
                    style={{
                      height: "670px",
                      maxWidth: "100%",
                      cursor: "pointer",
                      width: "100%",
                    }}
                  />
                </div>
              </Grid>

              {/* New excel table 4  */}
              <Grid item xs={12} md={6} className="mt-4">
                <div>
                  <TableContainer component={Paper} sx={{ height: 650 }}>
                    {landsat_dec.length > 0 ? (
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          height: 650,
                          overflowX: isLargeScreen ? "hidden" : "auto",
                        }} // aria-label="customized table"
                      >
                        <TableHead stickyHeader>
                          <TableRow>
                            {landsat_dec.length > 0 &&
                              Object.keys(landsat_dec[0]).map((key, index) => (
                                <StyledTableCell key={index}>
                                  {key}
                                </StyledTableCell>
                              ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {landsat_dec.length > 0 ? (
                            landsat_dec.map((row, index) => (
                              <StyledTableRow key={index}>
                                {Object.values(row).map((value, index) => (
                                  <StyledTableCell key={index}>
                                    {value}
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            ))
                          ) : (
                            <StyledTableRow>
                              <StyledTableCell
                                colSpan={Object.keys(landsat_dec[0]).length}
                              >
                                <Typography>Loading...</Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      <FooterAgri />
    </>
  );
}
