import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Pagination } from "@mui/material";
import usePagination from "../utils/Pagination";
import useWindowDimensions from "../hooks/getScreenDimensions";
import { Typography } from "@mui/material";
import { Container } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import news1 from "../../public/videogallery/images/news1.png";
import news2 from "../../public/videogallery/images/news2.png";
import news3 from "../../public/videogallery/images/news3.png";
import news4 from "../../public/videogallery/images/news4.png";
import news5 from "../../public/videogallery/images/news5.png";
import news6 from "../../public/videogallery/images/news6.png";
import news7 from "../../public/videogallery/images/news7.png";
import news8 from "../../public/videogallery/images/news8.png";
import news9 from "../../public/videogallery/images/news9.png";
import news10 from "../../public/videogallery/images/news10.png";
import news13 from "../../public/videogallery/images/news13.png";
import news14 from "../../public/videogallery/images/news14.png";
import news15 from "../../public/videogallery/images/news15.png";
import news16 from "../../public/videogallery/images/news16.png";
import news17 from "../../public/videogallery/images/news17.png";
import news18 from "../../public/videogallery/images/news18.png";
import news19 from "../../public/videogallery/images/news19.png";
import news20 from "../../public/videogallery/images/news20.png";

import video1 from "../../public/videogallery/video/video1.mp4";
import video2 from "../../public/videogallery/video/video2.mp4";
import video3 from "../../public/videogallery/video/video3.mp4";
import video4 from "../../public/videogallery/video/video4.mp4";
import video5 from "../../public/videogallery/video/video5.mp4";
import video6 from "../../public/videogallery/video/video6.mp4";
import video7 from "../../public/videogallery/video/video7.mp4";
import video8 from "../../public/videogallery/video/video8.mp4";
import video9 from "../../public/videogallery/video/video9.mp4";
import video10 from "../../public/videogallery/video/video10.mp4";
import video13 from "../../public/videogallery/video/video13.mp4";
import video14 from "../../public/videogallery/video/video14.mp4";
import video15 from "../../public/videogallery/video/video15.mp4";
import video16 from "../../public/videogallery/video/video16.mp4";
import video17 from "../../public/videogallery/video/video17.mp4";
import video18 from "../../public/videogallery/video/video18.mp4";
import video19 from "../../public/videogallery/video/video19.mp4";
import video20 from "../../public/videogallery/video/video20.mp4";

import interview1 from '../../public/interviews/interview.png';

import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import { GALLERY_VIDEO12, VIDEO_GALLERY_THUMBNAIL10, VIDEO_GALLERY_THUMBNAIL12 } from "../utils/constants";

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      border: "1px solid #0E97FF",
      color: "#0E97FF",
    },
    "& .Mui-selected": {
      color: "#0E97FF",
      background: "#0E97FF",
    },
  },
}));

function Videogallery({ image, text, height, title }) {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [selectedButton, setSelectedButton] = useState(1);
  const handleButtonClick = (buttonId) => {
    setSelectedButton(buttonId);
  };
  const buttonStyle = {
    backgroundColor: "white",
    color: "#0E97FF",
    border: "1px dashed #0E97FF",
    borderRadius: "50px",
    fontSize: "12px",
    fontWeight: "700",
    padding: "10px 32px",
    textTransform: "uppercase",
    width: "230px",
    marginRight: "5px",
    marginBottom: '10px',
    flexShrink: 0
  };

  const selectedButtonStyle = {
    backgroundColor: "#0E97FF",
    color: "white",
    border: "1px solid #0E97FF",
    borderRadius: "50px",
    fontSize: "12px",
    fontWeight: "700",
    padding: "10px 32px",
    textTransform: "uppercase",
    width: "230px",
    marginRight: "5px",
    marginBottom: '10px',
    flexShrink: 0
  };
  const cardData = [
    {
      id: 1,
      image: news1,
      title: "Inauguration of LIMS by Prime Minister",
      src: video1,
    },
    {
      id: 7,
      image: news7,
      title: "Launch of model by CM and COAS",
      src: video7,
    }, {
      id: 10,
      image: news10,
      title: "Cotton Day, CM about LIMS",
      src: video10,
    },
    {
      id: 3,
      image: news3,
      title: "Briefing to DG IOFS",
      src: video3,
    },
    {
      id: 2,
      image: news2,
      title: "SIFC Meeting",
      src: video2,
    },
    {
      id: 8,
      image: news8,
      title: "SIFC interview part 1",
      src: video8,
    },
    {
      id: 9,
      image: news9,
      title: "SIFC interview part 2",
      src: video9,
    },

    {
      id: 4,
      image: news4,
      title: "MOU Signing with PCSIR",
      src: video4,
    },
    {
      id: 5,
      image: news5,
      title: "MOU Signing with Zee Saga",
      src: video5,
    },
    {
      id: 6,
      image: news6,
      title: "Spray against white fly attach on cotton crop",
      src: video6,
    },
    {
      id: 13,
      image: news13,
      title: "Dr Waqar Yaseen Project director LIMS",
      src: video13,
    },
    {
      id: 14,
      image: news14,
      title: "Dr Waqar Yaseen Project director LIMS",
      src: video14,
    }, {
      id: 15,
      image: news15,
      title: "Dr Waqar Yaseen Project director LIMS",
      src: video15,
    }, {
      id: 16,
      image: news16,
      title: "Dr Waqar Yaseen Project director LIMS",
      src: video16,
    }, {
      id: 18,
      image: news18,
      title: "Dr Waqar Yaseen Project director LIMS",
      src: video18,
    },
    {
      id: 19,
      image: news19,
      title: "LIMS Training Session For Irrigation DEPT",
      src: video19,
    },
    {
      id: 20,
      image: news20,
      title: "Launch LIMS Desilting Montior Application For PUNJAB Govt",
      src: video20,
    },


  ];
  const interviewData = [
    {
      id: 1,
      image: interview1,
      title: "Interview About LIMS by Suno FM Radio.",
      src: 'https://limspakistan.com/admin/public/uploads/interview1.mp4',
    },
    {
      id: 17,
      image: news17,
      title: "Dr Waqar Yaseen Project director LIMS",
      src: video17,
    }


  ];
  let [page, setPage] = useState(1);
  let [pageInterview, setPageInterview] = useState(1);
  const PER_PAGE = 6;
  const count = Math.ceil(cardData.length / PER_PAGE);
  const countInterview = Math.ceil(interviewData.length / PER_PAGE);
  const _DATA = usePagination(cardData, PER_PAGE);
  const __DATA = usePagination(interviewData, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleChangeInterview = (e, p) => {
    setPageInterview(p);
    __DATA.jump(p);
  };
  const { width } = useWindowDimensions();
  const style = {
    justifyContent: "flex-start",
    display: 'flex',
    flexDirection: 'column'
  };

  const titleStyle = {
    WebkitLineClamp: 2,
    color: "#000",
    fontSize: "15.7649px",
    fontWeight: 500,
    lineHeight: "24px",
    marginBottom: "4px",
    marginTop: "5px",
    fontFamily: "Poppins",
    textAlign: 'justify',
    "@media (max-width: 786px)": {
      marginLeft: "30px",
    },
  };

  const SubHeadingStyle = {
    WebkitLineClamp: 2,
    color: "#5b5b5b",
    fontSize: "13.7649px",
    fontWeight: 500,
    lineHeight: "24px",
    marginBottom: "4px",
    marginTop: "5px",
    fontFamily: "Poppins",
    textAlign: 'justify',
    "@media (max-width: 786px)": {
      marginLeft: "30px",
    },
  };

  const LatestVideos = () => {
    return (
      <>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={12}>
            <Typography mt="10px" variant="h5">
              <strong>Latest videos </strong>
            </Typography>
          </Col>
        </Row>
        <Row
          className="g-4"
          style={{ marginTop: "20px", marginBottom: "50px" }}
        >
          {_DATA.currentData().map((card) => (
            <Col xs={12} md={6} xl={4} key={card.id}>
              <div
                className="item"
                style={{ cursor: "pointer", position: "relative" }}
                onClick={() => {
                  setSelectedVideo(card.src); // Set the selected video URL
                  setOpen(true); // Open the modal
                }}
              >
                <Card style={{ boxShadow: "none" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <img
                      src={card.image} // Use the image property from cardData
                      alt={`Image${card.id}`}
                      style={{
                        width: "100%",
                        height: "250px",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="60"
                      height="60"
                      viewBox="0 0 39 39"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontSize: 48,
                      }}
                      fill="none"
                    >
                      <path
                        d="M37.7565 19.6043C37.7565 29.868 29.4356 38.1885 19.1712 38.1885C8.90673 38.1885 0.585826 29.868 0.585826 19.6043C0.585826 9.3406 8.90673 1.02015 19.1712 1.02015C29.4356 1.02015 37.7565 9.3406 37.7565 19.6043Z"
                        fill="#0E97FF"
                        fillOpacity="0.5"
                        stroke="white"
                        stroke-width="1.17165"
                      />
                      <path
                        d="M14.103 11.5678L14.1031 11.5678L28.5023 19.4869C28.5023 19.4869 28.5024 19.4869 28.5025 19.487C28.5234 19.4986 28.5409 19.5155 28.553 19.5361C28.5653 19.5568 28.5717 19.5804 28.5717 19.6043C28.5717 19.6283 28.5653 19.6519 28.553 19.6726C28.5408 19.6932 28.5233 19.7102 28.5023 19.7218L14.1031 27.6409L14.1028 27.641C14.083 27.6519 14.0608 27.6576 14.0381 27.6576C14.0026 27.6576 13.9686 27.6435 13.9435 27.6184C13.9183 27.5932 13.9042 27.5591 13.9042 27.5235V11.6852C13.9042 11.6852 13.9042 11.6852 13.9042 11.6851C13.9042 11.6619 13.9103 11.639 13.9218 11.6188C13.9334 11.5986 13.95 11.5817 13.9701 11.5698C13.9901 11.558 14.0129 11.5515 14.0363 11.5512C14.0596 11.5508 14.0826 11.5565 14.103 11.5678Z"
                        fill="white"
                        stroke="white"
                        stroke-width="1.17165"
                      />
                    </svg>
                  </div>
                  <div style={style}>
                    <Card.Title style={titleStyle}>
                      {card.title}
                    </Card.Title>

                    {/* <Card.Title style={SubHeadingStyle}>
                      {"12-07-2023"}
                    </Card.Title> */}
                  </div>
                </Card>
              </div>
            </Col>
          ))}
        </Row>
        <Row>
          <Col xs={12} style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              className={classes.ul}
              style={{ marginBottom: "25px" }}
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            ></Pagination>
          </Col>
        </Row>
      </>
    );
  };


  const InterviewData = () => {
    return (
      <>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={12}>
            <Typography mt="10px" variant="h5">
              <strong>Interview About LIMS</strong>
            </Typography>
          </Col>
        </Row>
        <Row
          className="g-4"
          style={{ marginTop: "20px", marginBottom: "50px" }}
        >
          {__DATA.currentData().map((card) => (
            <Col xs={12} md={6} xl={4} key={card.id}>
              <div
                className="item"
                style={{ cursor: "pointer", position: "relative" }}
                onClick={() => {
                  setSelectedVideo(card.src); // Set the selected video URL
                  setOpen(true); // Open the modal
                }}
              >
                <Card style={{ boxShadow: "none" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <img
                      src={card.image} // Use the image property from cardData
                      alt={`Image${card.id}`}
                      style={{
                        width: "100%",
                        height: "250px",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="60"
                      height="60"
                      viewBox="0 0 39 39"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontSize: 48,
                      }}
                      fill="none"
                    >
                      <path
                        d="M37.7565 19.6043C37.7565 29.868 29.4356 38.1885 19.1712 38.1885C8.90673 38.1885 0.585826 29.868 0.585826 19.6043C0.585826 9.3406 8.90673 1.02015 19.1712 1.02015C29.4356 1.02015 37.7565 9.3406 37.7565 19.6043Z"
                        fill="#0E97FF"
                        fillOpacity="0.5"
                        stroke="white"
                        stroke-width="1.17165"
                      />
                      <path
                        d="M14.103 11.5678L14.1031 11.5678L28.5023 19.4869C28.5023 19.4869 28.5024 19.4869 28.5025 19.487C28.5234 19.4986 28.5409 19.5155 28.553 19.5361C28.5653 19.5568 28.5717 19.5804 28.5717 19.6043C28.5717 19.6283 28.5653 19.6519 28.553 19.6726C28.5408 19.6932 28.5233 19.7102 28.5023 19.7218L14.1031 27.6409L14.1028 27.641C14.083 27.6519 14.0608 27.6576 14.0381 27.6576C14.0026 27.6576 13.9686 27.6435 13.9435 27.6184C13.9183 27.5932 13.9042 27.5591 13.9042 27.5235V11.6852C13.9042 11.6852 13.9042 11.6852 13.9042 11.6851C13.9042 11.6619 13.9103 11.639 13.9218 11.6188C13.9334 11.5986 13.95 11.5817 13.9701 11.5698C13.9901 11.558 14.0129 11.5515 14.0363 11.5512C14.0596 11.5508 14.0826 11.5565 14.103 11.5678Z"
                        fill="white"
                        stroke="white"
                        stroke-width="1.17165"
                      />
                    </svg>
                  </div>
                  <div style={style}>
                    <Card.Title style={titleStyle}>
                      {card.title}
                      {/* Add any additional text or information about the video */}
                    </Card.Title>
                  </div>
                </Card>
              </div>
            </Col>
          ))}
        </Row>
        <Row>
          <Col xs={12} style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              className={classes.ul}
              style={{ marginBottom: "25px" }}
              count={countInterview}
              size="large"
              page={pageInterview}
              variant="outlined"
              shape="rounded"
              onChange={handleChangeInterview}
            ></Pagination>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Card className="bg-dark text-white" style={{ marginTop: "150px" }}>
        <Card.Img
          src="https://d3vnf7txgu507y.cloudfront.net/assets/images/videoGalleryBanner.jpg"
          alt="Card image"
          height="350px"
          style={{
            borderRadius: "0px",
            background: "rgba(25, 54, 95, 0.8)",
          }}
        />
        <Card.ImgOverlay>
          <Card.Title style={{ color: "white" }}>
            <Typography
              fontFamily="Poppins"
              variant="h3"
              style={{
                textAlign: "center",
                display: "block",
                fontSize: "40px",
                lineHeight: "46px",
                letterSpacing: ".01em",
                fontWeight: 800,
              }}
              sx={{ marginTop: { xs: "100px", md: "100px" } }}
            >
              Video{" "}
              <span
                style={{
                  WebkitTextStroke: "1px",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Gallery
              </span>
            </Typography>
          </Card.Title>
          <Card.Text></Card.Text>
          <Card.Text color="primary">{text}</Card.Text>
        </Card.ImgOverlay>
      </Card>
      <Container>
        <Box
          style={{
            display: "flex",
            justifyContent: "start",
            marginBottom: "10px",
            marginTop: "25px",
            overflowX: "auto"
          }}
        >
          <Button
            style={selectedButton === 1 ? selectedButtonStyle : buttonStyle}
            onClick={() => handleButtonClick(1)}
          >
            Latest Vidoes
          </Button>

          <Button
            style={selectedButton === 2 ? selectedButtonStyle : buttonStyle}
            onClick={() => handleButtonClick(2)}
          >
            Interview About Lims
          </Button>


        </Box>
        {
          selectedButton == 1 ? <LatestVideos /> : <InterviewData />
        }
      </Container>
      <ModalVideo
        channel="custom"
        url={selectedVideo}
        isOpen={isOpen}
        onClose={() => {
          setSelectedVideo(""); // Clear the selected video URL
          setOpen(false); // Close the modal
        }}
      />
    </>
  );
}

export default Videogallery;
