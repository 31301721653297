import React from "react";
import OwlCarousel from "react-owl-carousel";
import { Card, Grid, Box, Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import {
  GALLERY_VIDEO11,
  GALLERY_VIDEO12,
  PRESS_RELEASE_00,
  PRESS_RELEASE_001,
  PRESS_RELEASE_01,
  PRESS_RELEASE_03,
  PRESS_RELEASE_04,
  PRESS_RELEASE_05,
  PRESS_RELEASE_06,
  PRESS_RELEASE_07,
  PRESS_RELEASE_08,
  PRESS_RELEASE_09,
  PRESS_RELEASE_10,
  PRESS_RELEASE_11,
  VIDEO_GALLERY_THUMBNAIL11,
  VIDEO_GALLERY_THUMBNAIL12,
} from "../utils/constants";
import {
  THUMBNAIL_00,
  THUMBNAIL_001,
  THUMBNAIL_01,
  THUMBNAIL_03,
  THUMBNAIL_04,
  THUMBNAIL_05,
  THUMBNAIL_06,
  THUMBNAIL_07,
  THUMBNAIL_08,
  THUMBNAIL_09,
  THUMBNAIL_10,
  THUMBNAIL_11,
} from "../utils/constants";
import {
  RECENT_EVENT_01,
  RECENT_EVENT_02,
  RECENT_EVENT_03,
  RECENT_EVENT_04,
  RECENT_EVENT_05,
  RECENT_EVENT_06,
  RECENT_EVENT_07,
  RECENT_EVENT_08,
  RECENT_EVENT_09
} from "../utils/constants";
import {
  PRESS_RELEASE_IMAGE_01,
  PRESS_RELEASE_IMAGE_02,
  PRESS_RELEASE_IMAGE_03,
  PRESS_RELEASE_IMAGE_04,
  PRESS_RELEASE_IMAGE_05,
  PRESS_RELEASE_IMAGE_06,
  PRESS_RELEASE_IMAGE_07,
  PRESS_RELEASE_IMAGE_08,
  PRESS_RELEASE_IMAGE_09,
  PRESS_RELEASE_IMAGE_10,
  PRESS_RELEASE_IMAGE_11,
  PRESS_RELEASE_IMAGE_12,
  PRESS_RELEASE_IMAGE_13,
  PRESS_RELEASE_IMAGE_14,
  PRESS_RELEASE_IMAGE_15,
  PRESS_RELEASE_IMAGE_16
} from "../utils/constants";

// const cardsData = Array.from({ length: 10 }, (_, index) => ({
//   id: index + 1,
//   title: `Card ${index + 1}`,
//   content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
//   image: `https://via.placeholder.com/150?text=Image${index + 1}`,
//  }));
const useStyles = makeStyles({
  video: {
    // width: "100%",
    // height: "100%",
    // objectFit: "cover",
    // borderRadius: 10,
  },
});
const cardsData = [
  { id: 0, image: THUMBNAIL_00, source: PRESS_RELEASE_00 },
  { id: 1, image: THUMBNAIL_001, source: PRESS_RELEASE_001 },
  { id: 1, image: THUMBNAIL_01, source: PRESS_RELEASE_01 },
  { id: 3, image: THUMBNAIL_03, source: PRESS_RELEASE_03 },
  { id: 4, image: VIDEO_GALLERY_THUMBNAIL12, source: GALLERY_VIDEO12 },
  { id: 5, image: VIDEO_GALLERY_THUMBNAIL11, source: GALLERY_VIDEO11 },
  { id: 6, image: THUMBNAIL_04, source: PRESS_RELEASE_04 },
  { id: 7, image: THUMBNAIL_05, source: PRESS_RELEASE_05 },
  { id: 8, image: THUMBNAIL_06, source: PRESS_RELEASE_06 },
  { id: 9, image: THUMBNAIL_07, source: PRESS_RELEASE_07 },
  { id: 10, image: THUMBNAIL_08, source: PRESS_RELEASE_08 },
  { id: 11, image: THUMBNAIL_09, source: PRESS_RELEASE_09 },
  { id: 12, image: THUMBNAIL_10, source: PRESS_RELEASE_10 },
  { id: 13, image: THUMBNAIL_11, source: PRESS_RELEASE_11 },
];

const recentEvent = [
  { id: 0, image: THUMBNAIL_00 },
  {
    id: 1,
    image: THUMBNAIL_001,
  },
  {
    id: 2,
    image: RECENT_EVENT_09,
  },
  {
    id: 3,
    image: RECENT_EVENT_08,
  },
  {
    id: 4,
    image: RECENT_EVENT_07,
  },
  { id: 5, image: RECENT_EVENT_01 },
  { id: 6, image: RECENT_EVENT_02 },
  { id: 7, image: RECENT_EVENT_03 },
  { id: 8, image: RECENT_EVENT_04 },
  { id: 9, image: RECENT_EVENT_05 },
  { id: 10, image: RECENT_EVENT_06 },
];



const RecentEventLength = recentEvent.length;
const recentActivities = [
  {
    id: 1,
    image: PRESS_RELEASE_IMAGE_01,
  },
  {
    id: 2,
    image: PRESS_RELEASE_IMAGE_02,
  },
  {
    id: 3,
    image: PRESS_RELEASE_IMAGE_03,
  },
  {
    id: 4,
    image: PRESS_RELEASE_IMAGE_04,
  },
  {
    id: 5,
    image: PRESS_RELEASE_IMAGE_05,
  },
  {
    id: 6,
    image: PRESS_RELEASE_IMAGE_06,
  },
  {
    id: 7,
    image: PRESS_RELEASE_IMAGE_07,
  },
  {
    id: 8,
    image: PRESS_RELEASE_IMAGE_08,
  },
  {
    id: 9,
    image: PRESS_RELEASE_IMAGE_09,
  }, {
    id: 10,
    image: PRESS_RELEASE_IMAGE_10,
  }, {
    id: 11,
    image: PRESS_RELEASE_IMAGE_11,
  }, {
    id: 12,
    image: PRESS_RELEASE_IMAGE_12,
  }, {
    id: 13,
    image: PRESS_RELEASE_IMAGE_13,
  }, {
    id: 14,
    image: PRESS_RELEASE_IMAGE_14,
  }, {
    id: 15,
    image: PRESS_RELEASE_IMAGE_15,
  }, {
    id: 16,
    image: PRESS_RELEASE_IMAGE_16,
  },
];

const recentActivitiesLength = recentActivities.length;
//console.log("RECENT",recentEvent.length)
const playerConfig = {
  file: {
    attributes: {
      controlsList: "nodownload",
    },
  },
};
const playerStyle = {
  borderRadius: "10px", // Set the border radius here
  overflow: "hidden", // Ensure content inside the div is clipped to the border radius
};

const PressReleaseVideo = ({ selectedVideo }) => {
  return (
    <>
      {" "}
      <Grid item xs={10}>
        <video
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: 10,
          }}
          src={selectedVideo}
          loop
          controls={true}
          muted
          controlslist="nodownload"
        />
      </Grid>
    </>
  );
};

const PressReleaseCarousel = ({ options, handleThumbnailClick }) => {
  return (
    <>
      <Grid item xs={12}></Grid>
      <Grid item xs={10}>
        <OwlCarousel className="owl-theme" {...options}>
          {cardsData.map((card, index) => (
            <div
              key={card.id}
              className="item"
              style={{ cursor: "pointer" }}
              onClick={() => handleThumbnailClick(card.source)}
            >
              <Card>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="60"
                  viewBox="0 0 39 39"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    fontSize: 48,
                  }}
                  fill="none"
                >
                  <path
                    d="M37.7565 19.6043C37.7565 29.868 29.4356 38.1885 19.1712 38.1885C8.90673 38.1885 0.585826 29.868 0.585826 19.6043C0.585826 9.3406 8.90673 1.02015 19.1712 1.02015C29.4356 1.02015 37.7565 9.3406 37.7565 19.6043Z"
                    fill="#0E97FF"
                    fillOpacity="0.5"
                    stroke="white"
                    stroke-width="1.17165"
                  />
                  <path
                    d="M14.103 11.5678L14.1031 11.5678L28.5023 19.4869C28.5023 19.4869 28.5024 19.4869 28.5025 19.487C28.5234 19.4986 28.5409 19.5155 28.553 19.5361C28.5653 19.5568 28.5717 19.5804 28.5717 19.6043C28.5717 19.6283 28.5653 19.6519 28.553 19.6726C28.5408 19.6932 28.5233 19.7102 28.5023 19.7218L14.1031 27.6409L14.1028 27.641C14.083 27.6519 14.0608 27.6576 14.0381 27.6576C14.0026 27.6576 13.9686 27.6435 13.9435 27.6184C13.9183 27.5932 13.9042 27.5591 13.9042 27.5235V11.6852C13.9042 11.6852 13.9042 11.6852 13.9042 11.6851C13.9042 11.6619 13.9103 11.639 13.9218 11.6188C13.9334 11.5986 13.95 11.5817 13.9701 11.5698C13.9901 11.558 14.0129 11.5515 14.0363 11.5512C14.0596 11.5508 14.0826 11.5565 14.103 11.5678Z"
                    fill="white"
                    stroke="white"
                    stroke-width="1.17165"
                  />
                </svg>
                <img
                  src={card.image}
                  alt={`Image${card.id}`}
                  style={{
                    width: "100%",
                    height: "150px",
                    objectFit: "cover",
                  }}
                />
              </Card>
            </div>
          ))}
        </OwlCarousel>
      </Grid>
    </>
  );
};

const RecentEvent = ({ options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handlePopUpOpen = () => {
    setIsOpen(true);
  };
  return (
    <>
      {" "}
      <Grid item xs={12}></Grid>
      <Grid item xs={10}>
        <OwlCarousel className="owl-theme" {...options}>
          {recentEvent.map((card, index) => (
            <>
              {RecentEventLength >= 4 ? (
                <>
                  {" "}
                  {index % 2 == 0 && index != RecentEventLength - 1 ? (
                    <Box className="item" style={{}}>
                      <img
                        src={recentEvent[index].image}
                        onClick={handlePopUpOpen}
                        style={{
                          width: "100%",
                          height: "150px",
                          objectFit: "cover",
                          borderRadius: "10px",
                          marginBottom: 10,
                        }}
                      />
                      <img
                        src={recentEvent[index + 1].image}
                        onClick={handlePopUpOpen}
                        style={{
                          width: "100%",
                          height: "150px",
                          objectFit: "cover",
                          borderRadius: "10px",
                          marginBottom: 10,
                        }}
                      />
                    </Box>
                  ) : null}
                </>
              ) : (
                <>
                  <Box className="item" style={{}}>
                    <img
                      src={card.image}
                      onClick={handlePopUpOpen}
                      style={{
                        width: "100%",
                        height: "150px",
                        objectFit: "cover",
                        borderRadius: "10px",
                        marginBottom: 10,
                      }}
                    />
                  </Box>
                </>
              )}
            </>
          ))}
        </OwlCarousel>

        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={[
            { src: THUMBNAIL_00 },
            { src: THUMBNAIL_001 },
            { src: RECENT_EVENT_01 },
            { src: RECENT_EVENT_02 },
            { src: RECENT_EVENT_03 },
            { src: RECENT_EVENT_04 },
            { src: RECENT_EVENT_05 },
            { src: RECENT_EVENT_06 },
            { src: RECENT_EVENT_07 },
            { src: RECENT_EVENT_08 },
            { src: RECENT_EVENT_09 },
          ]}
        />
      </Grid>
    </>
  );
};

const RecentAcitivities = ({ options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handlePopUpOpen = () => {
    setIsOpen(true);
  };
  return (
    <>
      {" "}
      <Grid item xs={12}></Grid>
      <Grid item xs={10}>
        <OwlCarousel className="owl-theme" {...options}>
          {recentActivities.map((card, index) => (
            <>
              {recentActivitiesLength >= 4 ? (
                <>
                  {" "}
                  {index % 2 == 0 && index != recentActivitiesLength - 1 ? (
                    <Box className="item" style={{}}>
                      <img
                        src={recentActivities[index].image}
                        onClick={handlePopUpOpen}
                        style={{
                          width: "100%",
                          height: "150px",
                          objectFit: "cover",
                          borderRadius: "10px",
                          marginBottom: 10,
                        }}
                      />
                      <img
                        src={recentActivities[index + 1].image}
                        onClick={handlePopUpOpen}
                        style={{
                          width: "100%",
                          height: "150px",
                          objectFit: "cover",
                          borderRadius: "10px",
                          marginBottom: 10,
                        }}
                      />
                    </Box>
                  ) : null}
                </>
              ) : (
                <>
                  <Box className="item" style={{}}>
                    <img
                      src={card.image}
                      onClick={handlePopUpOpen}
                      style={{
                        width: "100%",
                        height: "150px",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                    />
                  </Box>
                </>
              )}
            </>
          ))}
        </OwlCarousel>

        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={[
            { src: PRESS_RELEASE_IMAGE_01 },
            { src: PRESS_RELEASE_IMAGE_02 },
            { src: PRESS_RELEASE_IMAGE_03 },
            { src: PRESS_RELEASE_IMAGE_04 },
            { src: PRESS_RELEASE_IMAGE_05 },
            { src: PRESS_RELEASE_IMAGE_06 },
            { src: PRESS_RELEASE_IMAGE_07 },
            { src: PRESS_RELEASE_IMAGE_08 },
            { src: PRESS_RELEASE_IMAGE_09 },
            { src: PRESS_RELEASE_IMAGE_10 },
            { src: PRESS_RELEASE_IMAGE_11 },
            { src: PRESS_RELEASE_IMAGE_12 },
            { src: PRESS_RELEASE_IMAGE_13 },
            { src: PRESS_RELEASE_IMAGE_14 },
            { src: PRESS_RELEASE_IMAGE_15 },
            { src: PRESS_RELEASE_IMAGE_16 },
          ]}
        />
      </Grid>
    </>
  );
};


const Featured = () => {
  const [selectedVideo, setSelectedVideo] = useState(PRESS_RELEASE_00);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const handleThumbnailClick = (videoSource) => {
    setSelectedVideo(videoSource);
  };
  const classes = useStyles();
  const options = {
    margin: 10,
    responsive: {
      0: { items: 2, dots: false },
      600: { items: 2, dots: false },
      900: { items: 3, dots: false },
      1200: { items: 5 },
      1600: { items: 7 },
    },
  };
  const buttonStyle = {
    backgroundColor: "white",
    color: "#0E97FF",
    border: "1px dashed #0E97FF",
    borderRadius: "50px",
    fontSize: "12px",
    fontWeight: "700",
    padding: "10px 32px",
    textTransform: "uppercase",
    width: "200px",
    marginRight: "5px",
    marginBottom: '10px',
    flexShrink: 0
  };

  const selectedButtonStyle = {
    backgroundColor: "#0E97FF",
    color: "white",
    border: "1px solid #0E97FF",
    borderRadius: "50px",
    fontSize: "12px",
    fontWeight: "700",
    padding: "10px 32px",
    textTransform: "uppercase",
    width: "200px",
    marginRight: "5px",
    marginBottom: '10px',
    flexShrink: 0
  };
  const [selectedButton, setSelectedButton] = useState(1);
  const handleButtonClick = (buttonId) => {
    setSelectedButton(buttonId);
  };
  const handlePopUpOpen = () => {
    setIsOpen(true);
  };
  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={10}>
          <Box
            style={{
              display: "flex",
              justifyContent: "start",
              marginBottom: "20px",
              overflowX: "auto"
            }}
          >
            <Button
              style={selectedButton === 1 ? selectedButtonStyle : buttonStyle}
              onClick={() => handleButtonClick(1)}
            >
              Press Release
            </Button>
            <Button
              style={selectedButton === 2 ? selectedButtonStyle : buttonStyle}
              onClick={() => handleButtonClick(2)}
            >
              Recent Events
            </Button>
            <Button
              style={selectedButton === 3 ? selectedButtonStyle : buttonStyle}
              onClick={() => handleButtonClick(3)}
            >
              Recent Activities
            </Button>
          </Box>
        </Grid>
        {selectedButton == 1 ? (
          <>
            <PressReleaseVideo selectedVideo={selectedVideo} />
            <PressReleaseCarousel
              options={options}
              handleThumbnailClick={handleThumbnailClick}
            />
          </>
        ) : selectedButton == 2 ? (
          <RecentEvent options={options} />
        ) : (
          <RecentAcitivities options={options} />
        )}
      </Grid>
    </>
  );
};
export default Featured;
