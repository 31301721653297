import BHV_B from "../../public/land/punjab/soil/Element/Boron/Bahawalpur Boron.png";
import DGK_B from "../../public/land/punjab/soil/Element/Boron/Dera Ghazi Khan Boron.png";
import FSD_B from "../../public/land/punjab/soil/Element/Boron/Faisalabad Boron.png";
import GRW_B from "../../public/land/punjab/soil/Element/Boron/Gujranwala Boron.png";
import LHR_B from "../../public/land/punjab/soil/Element/Boron/Lahore Boron.png";
import MUL_B from "../../public/land/punjab/soil/Element/Boron/Multan Boron.png";
import RWP_B from "../../public/land/punjab/soil/Element/Boron/Rawalpindi Boron.png";
import SWH_B from "../../public/land/punjab/soil/Element/Boron/Sahiwal Boron.png";
import SGD_B from "../../public/land/punjab/soil/Element/Boron/Sarghoda Boron.png";

import BHV_EC from "../../public/land/punjab/soil/Element/Electronic Conductivity/Bahawalpur EC.png";
import DGK_EC from "../../public/land/punjab/soil/Element/Electronic Conductivity/Dera Ghazi Khan EC.png";
import FSD_EC from "../../public/land/punjab/soil/Element/Electronic Conductivity/Faislabad EC.png";
import GRW_EC from "../../public/land/punjab/soil/Element/Electronic Conductivity/Gurjanwala EC.png";
import LHR_EC from "../../public/land/punjab/soil/Element/Electronic Conductivity/Lahore EC.png";
import MUL_EC from "../../public/land/punjab/soil/Element/Electronic Conductivity/Multan EC.png";
import RWP_EC from "../../public/land/punjab/soil/Element/Electronic Conductivity/Rawalpindi EC.png";
import SWH_EC from "../../public/land/punjab/soil/Element/Electronic Conductivity/Sahiwal EC.png";
import SGD_EC from "../../public/land/punjab/soil/Element/Electronic Conductivity/Sarghoda EC.png";

import BHV_Fe from "../../public/land/punjab/soil/Element/Iron/Bahawalpur Iron.png";
import DGK_Fe from "../../public/land/punjab/soil/Element/Iron/Dera Ghazi Khan Iron.png";
import FSD_Fe from "../../public/land/punjab/soil/Element/Iron/Faisalabad Iron.png";
import GRW_Fe from "../../public/land/punjab/soil/Element/Iron/Gujranwala Iron.png";
import LHR_Fe from "../../public/land/punjab/soil/Element/Iron/Lahore Iron.png";
import MUL_Fe from "../../public/land/punjab/soil/Element/Iron/Multan Iron.png";
import RWP_Fe from "../../public/land/punjab/soil/Element/Iron/Rawalpindi Iron.png";
import SWH_Fe from "../../public/land/punjab/soil/Element/Iron/Sahiwal Iron.png";
import SGD_Fe from "../../public/land/punjab/soil/Element/Iron/Sarghoda Iron.png";

import BHV_Mn from "../../public/land/punjab/soil/Element/Maganese/Bahawalpur Maganese.png";
import DGK_Mn from "../../public/land/punjab/soil/Element/Maganese/Dera Ghazi Khan Maganese.png";
import FSD_Mn from "../../public/land/punjab/soil/Element/Maganese/Faislabad Maganese.png";
import GRW_Mn from "../../public/land/punjab/soil/Element/Maganese/Gujranwala Maganese.png";
import LHR_Mn from "../../public/land/punjab/soil/Element/Maganese/Lahore Maganese.png";
import MUL_Mn from "../../public/land/punjab/soil/Element/Maganese/Multan Maganese.png";
import RWP_Mn from "../../public/land/punjab/soil/Element/Maganese/Rawalpindi Maganese.png";
import SWH_Mn from "../../public/land/punjab/soil/Element/Maganese/Sahiwal Maganese.png";
import SGD_Mn from "../../public/land/punjab/soil/Element/Maganese/Sarghoda Maganese.png";

import BHV_OM from "../../public/land/punjab/soil/Element/Organic Matter/Bahawalpur Organic Matter.png";
import DGK_OM from "../../public/land/punjab/soil/Element/Organic Matter/Dera Ghazi Khan Organic Matter.png";
import FSD_OM from "../../public/land/punjab/soil/Element/Organic Matter/Faisalabad Organic Matter.png";
import GRW_OM from "../../public/land/punjab/soil/Element/Organic Matter/Gujranwala Organic Matter.png";
import LHR_OM from "../../public/land/punjab/soil/Element/Organic Matter/Lahore Organic Matter.png";
import MUL_OM from "../../public/land/punjab/soil/Element/Organic Matter/Multan Organic Matter.png";
import RWP_OM from "../../public/land/punjab/soil/Element/Organic Matter/Rawalpindi Organic Matter.png";
import SWH_OM from "../../public/land/punjab/soil/Element/Organic Matter/Sahiwal Organic Matter.png";
import SGD_OM from "../../public/land/punjab/soil/Element/Organic Matter/Sarghoda Organic Matter.png";

import BHV_pH from "../../public/land/punjab/soil/Element/pH/Bahawalpur PH.png";
import DGK_pH from "../../public/land/punjab/soil/Element/pH/Dera Ghazi Khan PH.png";
import FSD_pH from "../../public/land/punjab/soil/Element/pH/Faisalabad PH.png";
import GRW_pH from "../../public/land/punjab/soil/Element/pH/Gujranwala PH.png";
import LHR_pH from "../../public/land/punjab/soil/Element/pH/Lahore PH.png";
import MUL_pH from "../../public/land/punjab/soil/Element/pH/Multan PH.png";
import RWP_pH from "../../public/land/punjab/soil/Element/pH/Rawalpindi PH.png";
import SWH_pH from "../../public/land/punjab/soil/Element/pH/Sahiwal PH.png";
import SGD_pH from "../../public/land/punjab/soil/Element/pH/Sarghoda PH.png";

import BHV_P from "../../public/land/punjab/soil/Element/Phosphorus/Bahawalpur Phosphorus.png";
import DGK_P from "../../public/land/punjab/soil/Element/Phosphorus/Dera Ghazi Khan Phosphorus.png";
import FSD_P from "../../public/land/punjab/soil/Element/Phosphorus/Faisalabad Phosphorus.png";
import GRW_P from "../../public/land/punjab/soil/Element/Phosphorus/Gujranwala Phosphorus.png";
import LHR_P from "../../public/land/punjab/soil/Element/Phosphorus/Lahore Phosphorus.png";
import MUL_P from "../../public/land/punjab/soil/Element/Phosphorus/Multan Phosphorus.png";
import RWP_P from "../../public/land/punjab/soil/Element/Phosphorus/Rawalpindi Phosphorus.png";
import SWH_P from "../../public/land/punjab/soil/Element/Phosphorus/Sahiwal Phosphorus.png";
import SGD_P from "../../public/land/punjab/soil/Element/Phosphorus/Sarghoda Phosphorus.png";

import BHV_K from "../../public/land/punjab/soil/Element/Potassium/Bahawalpur Potassium.png";
import DGK_K from "../../public/land/punjab/soil/Element/Potassium/Dera GHazi Khan Potassium.png";
import FSD_K from "../../public/land/punjab/soil/Element/Potassium/Faislabad Potassium.png";
import GRW_K from "../../public/land/punjab/soil/Element/Potassium/Gujranwala Potassium.png";
import LHR_K from "../../public/land/punjab/soil/Element/Potassium/Lahore Potassium.png";
import MUL_K from "../../public/land/punjab/soil/Element/Potassium/Multan Potassium.png";
import RWP_K from "../../public/land/punjab/soil/Element/Potassium/Rawalpindi Potassium.png";
import SWH_K from "../../public/land/punjab/soil/Element/Potassium/Sahiwal Potassium.png";
import SGD_K from "../../public/land/punjab/soil/Element/Potassium/Sarghoda Potassium.png";

import BHV_Zn from "../../public/land/punjab/soil/Element/Zinc/Bahawalpur Zinc.png";
import DGK_Zn from "../../public/land/punjab/soil/Element/Zinc/Dera Ghazi Khan Zinc.png";
import FSD_Zn from "../../public/land/punjab/soil/Element/Zinc/Faislabad Zinc.png";
import GRW_Zn from "../../public/land/punjab/soil/Element/Zinc/Gujaranwala Zinc.png";
import LHR_Zn from "../../public/land/punjab/soil/Element/Zinc/Lahore Zinc.png";
import MUL_Zn from "../../public/land/punjab/soil/Element/Zinc/Multan Zinc.png";
import RWP_Zn from "../../public/land/punjab/soil/Element/Zinc/Rawalpindi Zinc.png";
import SWH_Zn from "../../public/land/punjab/soil/Element/Zinc/Sahiwal Zinc.png";
import SGD_Zn from "../../public/land/punjab/soil/Element/Zinc/Sarghoda Zinc.png";

const elementSoil = [
  {
    src: BHV_B,
    width: 8,
    height: 7,
    tags: [{ value: "Boron", title: "Boron" }],
    district: "BHV",
    element: "B",
  },
  {
    src: DGK_B,
    width: 8,
    height: 7,
    tags: [{ value: "Boron", title: "Boron" }],
    district: "DGK",
    element: "B",
  },
  {
    src: FSD_B,
    width: 8,
    height: 7,
    tags: [{ value: "Boron", title: "Boron" }],
    district: "FSD",
    element: "B",
  },
  {
    src: GRW_B,
    width: 8,
    height: 7,
    tags: [{ value: "Boron", title: "Boron" }],
    district: "GRW",
    element: "B",
  },
  {
    src: LHR_B,
    width: 8,
    height: 7,
    tags: [{ value: "Boron", title: "Boron" }],
    district: "LHR",
    element: "B",
  },
  {
    src: MUL_B,
    width: 8,
    height: 7,
    tags: [{ value: "Boron", title: "Boron" }],
    district: "MUL",
    element: "B",
  },
  {
    src: RWP_B,
    width: 8,
    height: 7,
    tags: [{ value: "Boron", title: "Boron" }],
    district: "RWP",
    element: "B",
  },
  {
    src: SWH_B,
    width: 8,
    height: 7,
    tags: [{ value: "Boron", title: "Boron" }],
    district: "SWH",
    element: "B",
  },
  {
    src: SGD_B,
    width: 8,
    height: 7,
    tags: [{ value: "Boron", title: "Boron" }],
    district: "SGD",
    element: "B",
  },
  {
    src: BHV_EC,
    width: 8,
    height: 7,
    tags: [{ value: "EC", title: "EC" }],
    district: "BHV",
    element: "EC",
  },
  {
    src: DGK_EC,
    width: 8,
    height: 7,
    tags: [{ value: "EC", title: "EC" }],
    district: "DGK",
    element: "EC",
  },
  {
    src: FSD_EC,
    width: 8,
    height: 7,
    tags: [{ value: "EC", title: "EC" }],
    district: "FSD",
    element: "EC",
  },
  {
    src: GRW_EC,
    width: 8,
    height: 7,
    tags: [{ value: "EC", title: "EC" }],
    district: "GRW",
    element: "EC",
  },
  {
    src: LHR_EC,
    width: 8,
    height: 7,
    tags: [{ value: "EC", title: "EC" }],
    district: "LHR",
    element: "EC",
  },
  {
    src: MUL_EC,
    width: 8,
    height: 7,
    tags: [{ value: "EC", title: "EC" }],
    district: "MUL",
    element: "EC",
  },
  {
    src: RWP_EC,
    width: 8,
    height: 7,
    tags: [{ value: "EC", title: "EC" }],
    district: "RWP",
    element: "EC",
  },
  {
    src: SWH_EC,
    width: 8,
    height: 7,
    tags: [{ value: "EC", title: "EC" }],
    district: "SWH",
    element: "EC",
  },
  {
    src: SGD_EC,
    width: 8,
    height: 7,
    tags: [{ value: "EC", title: "EC" }],
    district: "SGD",
    element: "EC",
  },

  {
    src: BHV_Fe,
    width: 8,
    height: 7,
    tags: [{ value: "Iron", title: "Iron" }],
    district: "BHV",
    element: "Fe",
  },
  {
    src: DGK_Fe,
    width: 8,
    height: 7,
    tags: [{ value: "Iron", title: "Iron" }],
    district: "DGK",
    element: "Fe",
  },
  {
    src: FSD_Fe,
    width: 8,
    height: 7,
    tags: [{ value: "Iron", title: "Iron" }],
    district: "FSD",
    element: "Fe",
  },
  {
    src: GRW_Fe,
    width: 8,
    height: 7,
    tags: [{ value: "Iron", title: "Iron" }],
    district: "GRW",
    element: "Fe",
  },
  {
    src: LHR_Fe,
    width: 8,
    height: 7,
    tags: [{ value: "Iron", title: "Iron" }],
    district: "LHR",
    element: "Fe",
  },
  {
    src: MUL_Fe,
    width: 8,
    height: 7,
    tags: [{ value: "Iron", title: "Iron" }],
    district: "MUL",
    element: "Fe",
  },
  {
    src: RWP_Fe,
    width: 8,
    height: 7,
    tags: [{ value: "Iron", title: "Iron" }],
    district: "RWP",
    element: "Fe",
  },
  {
    src: SWH_Fe,
    width: 8,
    height: 7,
    tags: [{ value: "Iron", title: "Iron" }],
    district: "SWH",
    element: "Fe",
  },
  {
    src: SGD_Fe,
    width: 8,
    height: 7,
    tags: [{ value: "Iron", title: "Iron" }],
    district: "SGD",
    element: "Fe",
  },

  {
    src: BHV_Mn,
    width: 8,
    height: 7,
    tags: [{ value: "Manganese", title: "Manganese" }],
    district: "BHV",
    element: "Mn",
  },
  {
    src: DGK_Mn,
    width: 8,
    height: 7,
    tags: [{ value: "Manganese", title: "Manganese" }],
    district: "DGK",
    element: "Mn",
  },
  {
    src: FSD_Mn,
    width: 8,
    height: 7,
    tags: [{ value: "Manganese", title: "Manganese" }],
    district: "FSD",
    element: "Mn",
  },
  {
    src: GRW_Mn,
    width: 8,
    height: 7,
    tags: [{ value: "Manganese", title: "Manganese" }],
    district: "GRW",
    element: "Mn",
  },
  {
    src: LHR_Mn,
    width: 8,
    height: 7,
    tags: [{ value: "Manganese", title: "Manganese" }],
    district: "LHR",
    element: "Mn",
  },
  {
    src: MUL_Mn,
    width: 8,
    height: 7,
    tags: [{ value: "Manganese", title: "Manganese" }],
    district: "MUL",
    element: "Mn",
  },
  {
    src: RWP_Mn,
    width: 8,
    height: 7,
    tags: [{ value: "Manganese", title: "Manganese" }],
    district: "RWP",
    element: "Mn",
  },
  {
    src: SWH_Mn,
    width: 8,
    height: 7,
    tags: [{ value: "Manganese", title: "Manganese" }],
    district: "SWH",
    element: "Mn",
  },
  {
    src: SGD_Mn,
    width: 8,
    height: 7,
    tags: [{ value: "Manganese", title: "Manganese" }],
    district: "SGD",
    element: "Mn",
  },

  {
    src: BHV_OM,
    width: 8,
    height: 7,
    tags: [{ value: "OM", title: "OM" }],
    district: "BHV",
    element: "OM",
  },
  {
    src: DGK_OM,
    width: 8,
    height: 7,
    tags: [{ value: "OM", title: "OM" }],
    district: "DGK",
    element: "OM",
  },
  {
    src: FSD_OM,
    width: 8,
    height: 7,
    tags: [{ value: "OM", title: "OM" }],
    district: "FSD",
    element: "OM",
  },
  {
    src: GRW_OM,
    width: 8,
    height: 7,
    tags: [{ value: "OM", title: "OM" }],
    district: "GRW",
    element: "OM",
  },
  {
    src: LHR_OM,
    width: 8,
    height: 7,
    tags: [{ value: "OM", title: "OM" }],
    district: "LHR",
    element: "OM",
  },
  {
    src: MUL_OM,
    width: 8,
    height: 7,
    tags: [{ value: "OM", title: "OM" }],
    district: "MUL",
    element: "OM",
  },
  {
    src: RWP_OM,
    width: 8,
    height: 7,
    tags: [{ value: "OM", title: "OM" }],
    district: "RWP",
    element: "OM",
  },
  {
    src: SWH_OM,
    width: 8,
    height: 7,
    tags: [{ value: "OM", title: "OM" }],
    district: "SWH",
    element: "OM",
  },
  {
    src: SGD_OM,
    width: 8,
    height: 7,
    tags: [{ value: "OM", title: "OM" }],
    district: "SGD",
    element: "OM",
  },

  {
    src: BHV_pH,
    width: 8,
    height: 7,
    tags: [{ value: "pH", title: "pH" }],
    district: "BHV",
    element: "pH",
  },
  {
    src: DGK_pH,
    width: 8,
    height: 7,
    tags: [{ value: "pH", title: "pH" }],
    district: "DGK",
    element: "pH",
  },
  {
    src: FSD_pH,
    width: 8,
    height: 7,
    tags: [{ value: "pH", title: "pH" }],
    district: "FSD",
    element: "pH",
  },
  {
    src: GRW_pH,
    width: 8,
    height: 7,
    tags: [{ value: "pH", title: "pH" }],
    district: "GRW",
    element: "pH",
  },
  {
    src: LHR_pH,
    width: 8,
    height: 7,
    tags: [{ value: "pH", title: "pH" }],
    district: "LHR",
    element: "pH",
  },
  {
    src: MUL_pH,
    width: 8,
    height: 7,
    tags: [{ value: "pH", title: "pH" }],
    district: "MUL",
    element: "pH",
  },
  {
    src: RWP_pH,
    width: 8,
    height: 7,
    tags: [{ value: "pH", title: "pH" }],
    district: "RWP",
    element: "pH",
  },
  {
    src: SWH_pH,
    width: 8,
    height: 7,
    tags: [{ value: "pH", title: "pH" }],
    district: "SWH",
    element: "pH",
  },
  {
    src: SGD_pH,
    width: 8,
    height: 7,
    tags: [{ value: "pH", title: "pH" }],
    district: "SGD",
    element: "pH",
  },
  {
    src: BHV_P,
    width: 8,
    height: 7,
    tags: [{ value: "Phosporus", title: "Phosporus" }],
    district: "BHV",
    element: "P",
  },
  {
    src: DGK_P,
    width: 8,
    height: 7,
    tags: [{ value: "Phosporus", title: "Phosporus" }],
    district: "DGK",
    element: "P",
  },
  {
    src: FSD_P,
    width: 8,
    height: 7,
    tags: [{ value: "Phosporus", title: "Phosporus" }],
    district: "FSD",
    element: "P",
  },
  {
    src: GRW_P,
    width: 8,
    height: 7,
    tags: [{ value: "Phosporus", title: "Phosporus" }],
    district: "GRW",
    element: "P",
  },
  {
    src: LHR_P,
    width: 8,
    height: 7,
    tags: [{ value: "Phosporus", title: "Phosporus" }],
    district: "LHR",
    element: "P",
  },
  {
    src: MUL_P,
    width: 8,
    height: 7,
    tags: [{ value: "Phosporus", title: "Phosporus" }],
    district: "MUL",
    element: "P",
  },
  {
    src: RWP_P,
    width: 8,
    height: 7,
    tags: [{ value: "Phosporus", title: "Phosporus" }],
    district: "RWP",
    element: "P",
  },
  {
    src: SWH_P,
    width: 8,
    height: 7,
    tags: [{ value: "Phosporus", title: "Phosporus" }],
    district: "SWH",
    element: "P",
  },
  {
    src: SGD_P,
    width: 8,
    height: 7,
    tags: [{ value: "Phosporus", title: "Phosporus" }],
    district: "SGD",
    element: "P",
  },
  {
    src: BHV_K,
    width: 8,
    height: 7,
    tags: [{ value: "Potassium", title: "Potassium" }],
    district: "BHV",
    element: "K",
  },
  {
    src: DGK_K,
    width: 8,
    height: 7,
    tags: [{ value: "Potassium", title: "Potassium" }],
    district: "DGK",
    element: "K",
  },
  {
    src: FSD_K,
    width: 8,
    height: 7,
    tags: [{ value: "Potassium", title: "Potassium" }],
    district: "FSD",
    element: "K",
  },
  {
    src: GRW_K,
    width: 8,
    height: 7,
    tags: [{ value: "Potassium", title: "Potassium" }],
    district: "GRW",
    element: "K",
  },
  {
    src: LHR_K,
    width: 8,
    height: 7,
    tags: [{ value: "Potassium", title: "Potassium" }],
    district: "LHR",
    element: "K",
  },
  {
    src: MUL_K,
    width: 8,
    height: 7,
    tags: [{ value: "Potassium", title: "Potassium" }],
    district: "MUL",
    element: "K",
  },
  {
    src: RWP_K,
    width: 8,
    height: 7,
    tags: [{ value: "Potassium", title: "Potassium" }],
    district: "RWP",
    element: "K",
  },
  {
    src: SWH_K,
    width: 8,
    height: 7,
    tags: [{ value: "Potassium", title: "Potassium" }],
    district: "SWH",
    element: "K",
  },
  {
    src: SGD_K,
    width: 8,
    height: 7,
    tags: [{ value: "Potassium", title: "Potassium" }],
    district: "SGD",
    element: "K",
  },
  {
    src: BHV_Zn,
    width: 8,
    height: 7,
    tags: [{ value: "Zinc", title: "Zinc" }],
    district: "BHV",
    element: "Zn",
  },
  {
    src: DGK_Zn,
    width: 8,
    height: 7,
    tags: [{ value: "Zinc", title: "Zinc" }],
    district: "DGK",
    element: "Zn",
  },
  {
    src: FSD_Zn,
    width: 8,
    height: 7,
    tags: [{ value: "Zinc", title: "Zinc" }],
    district: "FSD",
    element: "Zn",
  },
  {
    src: GRW_Zn,
    width: 8,
    height: 7,
    tags: [{ value: "Zinc", title: "Zinc" }],
    district: "GRW",
    element: "Zn",
  },
  {
    src: LHR_Zn,
    width: 8,
    height: 7,
    tags: [{ value: "Zinc", title: "Zinc" }],
    district: "LHR",
    element: "Zn",
  },
  {
    src: MUL_Zn,
    width: 8,
    height: 7,
    tags: [{ value: "Zinc", title: "Zinc" }],
    district: "MUL",
    element: "Zn",
  },
  {
    src: RWP_Zn,
    width: 8,
    height: 7,
    tags: [{ value: "Zinc", title: "Zinc" }],
    district: "RWP",
    element: "Zn",
  },
  {
    src: SWH_Zn,
    width: 8,
    height: 7,
    tags: [{ value: "Zinc", title: "Zinc" }],
    district: "SWH",
    element: "Zn",
  },
  {
    src: SGD_Zn,
    width: 8,
    height: 7,
    tags: [{ value: "Zinc", title: "Zinc" }],
    district: "SGD",
    element: "Zn",
  },
];

export default elementSoil;
