import Header from "../utils/Header";
import Footer from "../utils/Footer";
import Main from "./Main";
import ImageWithOverlay from "../utils/ImageWithOverlay";
import AboutUs from '../../public/images/AboutUs.jpg';
export default function CompleteAbout() {
  return (
    <>
      <Header /> 
      <ImageWithOverlay image={AboutUs} height="300px" title="About us" titlePartOne="About" titlePartTwo="Us"  />
      <Main />
      <Footer />
    </>
  );
}
