import {
  Grid,
  Box,
  Typography,
  // Button,
  Fab,
} from "@mui/material";
import { useCallback, useEffect } from "react";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { useWindowScrollPositions } from "../hooks/getScrollPositions";
import Card from "../utils/Card";
import More from "./More";
import { useParams } from "react-router-dom";
import Projects from "./Projects";
import CloudOutlinedIcon from "@mui/icons-material/CloudOutlined";
import GrassOutlinedIcon from "@mui/icons-material/GrassOutlined";
import StayCurrentPortraitOutlinedIcon from "@mui/icons-material/StayCurrentPortraitOutlined";
import ForestOutlinedIcon from "@mui/icons-material/ForestOutlined";
import useWindowDimensions from "../hooks/getScreenDimensions";
import Title from "../utils/Title";
import Featured from "./Featured";
import KissanCorner from "./KissanCorner";
import WaterDropOutlinedIcon from "@mui/icons-material/WaterDropOutlined";
import AgricultureOutlinedIcon from "@mui/icons-material/AgricultureOutlined";
import PestControlOutlinedIcon from "@mui/icons-material/PestControlOutlined";
import TerrainOutlinedIcon from "@mui/icons-material/TerrainOutlined";
import {
  WEATHER_MAPS,
  VEGETATION_IMAGE,
  SOIL,
  SMART_SOLUTIONS,
  DAM,
  LAND_CONS,
  PEST_SCOUTING,
  BACKGROUND_WHOLE,
  COTTON,
} from "../utils/constants";
// import DownloadIcon from "@mui/icons-material/Download";
// import Announcement from "../utils/Announcement";
import { useNavigate } from "react-router-dom";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import { keyframes } from "@emotion/react";
import "../../../src/App.css";
// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import NavigationIcon from "@mui/icons-material/Navigation";
// import { Link } from "react-router-dom";
import * as React from "react";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import QR1 from "../../../src/public/images/QR1.png";

// import Modal from "@mui/material/Modal";
// import CancelIcon from "@mui/icons-material/Cancel";

const cloud = (
  <CloudOutlinedIcon
    fontSize="small"
    style={{ color: "white", fontSize: "50px", opacity: 0.5 }}
  />
);
const veg = (
  <GrassOutlinedIcon
    fontSize="large"
    style={{ color: "white", fontSize: "50px", opacity: 0.5 }}
  />
);
const mob = (
  <StayCurrentPortraitOutlinedIcon
    fontSize="large"
    style={{ color: "white", fontSize: "50px", opacity: 0.5 }}
  />
);
const forest = (
  <ForestOutlinedIcon
    fontSize="large"
    style={{ color: "white", fontSize: "50px", opacity: 0.5 }}
  />
);

const pest = (
  <PestControlOutlinedIcon
    fontSize="small"
    style={{ color: "white", fontSize: "50px", opacity: 0.5 }}
  />
);

const agri = (
  <AgricultureOutlinedIcon
    fontSize="small"
    style={{ color: "white", fontSize: "50px", opacity: 0.5 }}
  />
);

const terrain = (
  <TerrainOutlinedIcon
    fontSize="small"
    style={{ color: "white", fontSize: "50px", opacity: 0.5 }}
  />
);

const water = (
  <WaterDropOutlinedIcon
    fontSize="small"
    style={{ color: "white", fontSize: "50px", opacity: 0.5 }}
  />
);
const ProjectsForLarge = () => {
  return (
    <>
      <Projects image={DAM} des={"Watershed Delineation"} />
      <Projects image={LAND_CONS} des={"Land Consolidation"} />
      <Projects image={PEST_SCOUTING} des={"Pests Monitoring"} />
      <Projects image={SMART_SOLUTIONS} des={"Drone and Aerial Spray"} />
      <Projects image={VEGETATION_IMAGE} des={"Cotton Crop Monitoring"} />
    </>
  );
};

const ProjectsForSmall = ({ width }) => {
  return (
    <>
      <Grid item m="15px">
        <Card
          img={DAM}
          height="460px"
          boxShadow="none"
          basicCard={true}
          basicCardText="Watershed Delineation"
          icon={water}
        />
      </Grid>
      <Grid item m="15px">
        <Card
          img={LAND_CONS}
          height="460px"
          boxShadow="none"
          basicCard={true}
          basicCardText="Land Consolidation"
          icon={agri}
        />
      </Grid>
      {width < 1320 && <Grid item xs={12}></Grid>}
      <Grid item m="15px">
        <Card
          img={PEST_SCOUTING}
          height="460px"
          boxShadow="none"
          basicCard={true}
          basicCardText="Pests Monitoring"
          icon={pest}
        />
      </Grid>
      <Grid item m="15px">
        <Card
          img={SMART_SOLUTIONS}
          height="460px"
          boxShadow="none"
          basicCard={true}
          basicCardText="Drone and Aerial Spray"
          icon={mob}
        />
      </Grid>
      {width < 1320 && <Grid item xs={12}></Grid>}
      <Grid item m="15px">
        <Card
          img={VEGETATION_IMAGE}
          text="Smart Solutions"
          height="460px"
          boxShadow="none"
          basicCard={true}
          basicCardText="Cotton Crop Monitoring"
          icon={terrain}
        />
      </Grid>
    </>
  );
};

export default function Home() {
  const [modalOpen, setModalOpen] = React.useState(true);
  const [openAccordions, setOpenAccordions] = useState([]);

  // const [qrcode1, setqrcode1] = useState(false);
  // const [qrcode2, setqrcode2] = useState(false);

  // const { id } = useParams();
  useEffect(() => {
    console.log(useParams);
  }, []);
  const { width } = useWindowDimensions();
  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const { scrollY } = useWindowScrollPositions();
  const navigate = useNavigate();
  const moveToWeatherAdbisoryPage = () => {
    navigate("/weather-advisory");
  };

  // -------------------------------- New Logic --------------------------------
  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
    setOpenAccordions([]);
  };

  // const [openAccordion, setOpenAccordion] = useState("panel1");

  // const handleAccordionToggle = (panel) => (event, isExpanded) => {
  //   setOpenAccordion(isExpanded ? panel : "");
  // };

  // FAB QR
  const handleModalToggleFab = () => {
    setModalOpen(!modalOpen);
  };

  const modalContent = (
    <div
      style={{
        position: "fixed",
        top: "-2550%",
        left: "950%",
        transform: "translate(-50%, -50%)",
        width: "300px",
        height: "400px",
        padding: "24px",
        borderRadius: "10px",
        backgroundColor: "rgb(80 89 115)",
        overflowY: "hidden",
        zIndex: 999,
      }}
    >
      <KeyboardArrowDownIcon
        className="modal_arrow_close"
        onClick={handleModalToggle}
      />
      <Typography
        variant="h6"
        component="div"
        id="modal-modal-title"
        className=""
        style={{ color: "white" }}
      >
        Desilting Monitor - Punjab
      </Typography>
      <img
        src={QR1}
        className=""
        alt="Mobile Image"
        style={{
          width: "100%",
          height: "auto",
          marginTop: "10px",
          borderRadius: "8px",
        }}
      />
      <Typography
        variant="h6"
        component="div"
        id="modal-modal-title"
        className=""
        style={{
          color: "white",
          fontSize: "14px",
          marginTop: "10px",
        }}
      >
        For Desktop Access, Please{" "}
        <a
          href="http://desilt-monitoring.limspakistan.com/admin/dist/"
          target="_blank"
        >
          {" "}
          Click Here{" "}
        </a>
      </Typography>
    </div>
    // "http://desilt-monitoring.limspakistan.com/admin/mobile.png"
  );

  return (
    <Box
      sx={{
        backgroundImage: `url(${BACKGROUND_WHOLE})`,
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
      }}
    >
      <Title title={"Home"} />

      {/* <Announcement/> */}

      <Grid container justifyContent="center" alignItems="center" mt="50px">
        <Grid item xs={10}>
          <Typography align="center" variant="h4" fontFamily="Poppins">
            <strong>
              Farm on the Palm - Taking agriculture monitoring and
              decision-making to the next level
            </strong>
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography
            align="center"
            mt="50px"
            mb="15px"
            fontFamily="Poppins"
            color="text.secondary"
          >
            <strong>
              Our stakeholders are farmers, agri corporates, government
              departments, researchers and national & international
              organisations interested in climate change, agriculture,
              <br></br>
              food and water security. We use the latest computing technology,
              artificial intelligence, (near) real-time satellite data to
              provide information about: <br></br>
            </strong>
          </Typography>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item m="15px">
          <Card
            img={WEATHER_MAPS}
            text="Weather"
            height="460px"
            boxShadow="none"
            basicCard={true}
            basicCardText="Weather"
            icon={cloud}
          />
        </Grid>
        <Grid item m="15px">
          <Card
            img={SOIL}
            text="Soil"
            height="460px"
            boxShadow="none"
            basicCard={true}
            basicCardText="Soil"
            icon={forest}
          />
        </Grid>
        {width < 1320 && <Grid item xs={12}></Grid>}
        <Grid item m="15px">
          <Card
            img={VEGETATION_IMAGE}
            text="Realtime Vegetation monitoring"
            height="460px"
            boxShadow="none"
            basicCard={true}
            basicCardText="Real time vegetation monitoring"
            icon={veg}
          />
        </Grid>
        <Grid item m="15px">
          <Card
            img={SMART_SOLUTIONS}
            text="Smart Solutions"
            height="460px"
            boxShadow="none"
            basicCard={true}
            basicCardText="Smart Solutions"
            icon={mob}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" mt="5px">
        <Grid
          item
          xs={10}
          style={{
            marginBottom: "5px",
            paddingBottom: "5px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            align="center"
            mt="50px"
            variant="h5"
            fontFamily="Poppins"
            marginBottom={2}
          >
            <strong>Digital solutions for sustainable development</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item m="15px">
          <More
            title="Precision Agriculture"
            description="Precision agriculture (PA) is a farming management concept based on observing, measuring and responding to inter- and intra-field variability in crops."
          />
        </Grid>
        <Grid item m="15px">
          <More
            title="Vegetation and Water Stress Monitoring"
            description="Variation is detected using remote sensing in order to monitor the crop health and identify areas of water stress."
          />
        </Grid>
        {width < 1320 && <Grid item xs={12}></Grid>}
        <Grid item m="15px">
          <More
            title="Recommendations for Fertilization & Irrigation"
            description="Based on stresses optimum requirement of fertilizers and irrigation is recommended for the crops."
          />
        </Grid>
        <Grid item m="15px">
          <More
            title="Diseases & Pests Prediction"
            description="It is to accurately knowing the level or development trend of a certain disease in a certain period."
          />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item m="15px">
          <More
            title="Crop Classiﬁcation"
            description="Crop classification refers to which crops are grown. Crop plants can be classified according to their structural features (botanical), agronomic use, biochemical pathways, life cycle (ontogeny) and climate requirements."
          />
        </Grid>
        <Grid item m="15px">
          <More
            title="Decision Support System for Agriculture"
            description="A Decision Support System (DSS) is a computer-based information system that supports agriculture or farmer’s decision-making activities."
          />
        </Grid>
        {width < 1320 && <Grid item xs={12}></Grid>}
        <Grid item m="15px">
          <More
            title="Yield Modeling & Prediction"
            description="An accurate crop yield prediction model can help farmers to decide on what to grow and when to grow."
          />
        </Grid>
        <Grid item m="15px">
          <More
            title="Optimal Crop Rotation Plan"
            description="Crop rotation is the practice of growing a series of different types of crops in the same area across a sequence of growing seasons."
          />
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className="mt-5"
      >
        <Grid
          item
          xs={10}
          style={{
            borderBottom: "1px solid #0E97FF",
            marginBottom: "5px",
            paddingBottom: "5px",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "5px",
            }}
          >
            <Typography variant="h6" fontFamily="Poppins">
              <strong>Featured Media</strong>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className="mt-2"
      ></Grid>
      <Featured />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className="mt-5"
      >
        <Grid
          item
          xs={10}
          style={{
            borderBottom: "1px solid #0E97FF",
            marginBottom: "5px",
            paddingBottom: "5px",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "5px",
            }}
          >
            <Typography variant="h6" fontFamily="Poppins">
              <strong>Kissan Corner</strong>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className="mt-2"
      ></Grid>
      <KissanCorner />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          marginTop: "100px",
          backgroundImage: `linear-gradient(to bottom, rgba(2, 15, 35, 0.5), rgba(2, 15, 35, 0.5)), url(${COTTON})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid item xs={12}>
          <Typography
            align="center"
            variant="h4"
            mt="130px"
            mb="130px"
            color="white"
            fontFamily="Poppins"
          >
            <strong>
              We are proud to present our
              <br />
              remarkable achievements
              <br />
            </strong>
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" mt="30px">
        <Grid item xs={12}>
          <Typography
            align="center"
            variant="h6"
            mt="80px"
            style={{ color: "#2364C9" }}
          >
            LIMS - COE
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="h5" mt="10px">
            <strong>Overview of Our Project</strong>
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        style={{ marginBottom: "1vh" }}
        alignItems="center"
        mt="30px"
      >
        {width <= 1280 ? (
          <ProjectsForSmall width={width} />
        ) : (
          <ProjectsForLarge />
        )}
      </Grid>

      <Box
        role="presentation"
        sx={{
          display: scrollY === 0 ? "none" : "block",
          position: "fixed",
          bottom: 32,
          right: 32,
          zIndex: 1,
          transition: "transform 0.3s ease-in-out",
          transform: "scale(1)",
          "&:hover": {
            transform: "scale(1.2)",
          },
        }}
      >
        <Fab
          onClick={scrollToTop}
          color="primary"
          size="small"
          aria-label="Scroll back to top"
        >
          <KeyboardDoubleArrowUpIcon style={{ color: "white" }} />
        </Fab>
      </Box>

      {/* FAB QR */}
      <Box
        sx={{
          display: "block",
          position: "fixed",
          bottom: 90,
          left: 22,
          zIndex: 1,
          transition: "transform 0.3s ease-in-out",
          transform: "scale(1)",

          "&:hover": {
            transform: "scale(1.1)",
          },
          "& > :not(style)": { m: 1 },
        }}
      >
        {/* QR Modal */}
        {modalOpen ? (
          <div>{modalContent}</div>
        ) : (
          <div>
            <Fab
              variant="extended"
              size="small"
              color="primary"
              className="px-3 py-4 "
              onClick={handleModalToggleFab}
            >
              <StayCurrentPortraitIcon sx={{ mr: 1 }} />
              {/* StayCurrentPortraitIcon */}
              <button onClick={handleModalToggle} className="remove_styling">
                Desilting Application
              </button>
            </Fab>
          </div>
        )}
      </Box>

      <Box
        role="presentation"
        sx={{
          display: "block",
          position: "fixed",
          bottom: 32,
          left: 22,
          zIndex: 1,
          transition: "transform 0.3s ease-in-out",
          transform: "scale(1)",
          "&:hover": {
            transform: "scale(1.1)",
          },
        }}
      >
        <Fab
          onClick={moveToWeatherAdbisoryPage}
          color="success"
          aria-label="Wheat Advisory"
          variant={"extended"}
        >
          <CampaignOutlinedIcon style={{ color: "white" }} sx={{ mr: 2 }} />
          Weather Advisory
        </Fab>
      </Box>
    </Box>
  );
}
