import * as React from "react";
import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";



const BasicCard = ({text}) => {
  return (
    <Card sx={{ maxWidth: 300, borderRadius: "25%", boxShadow: "none" }}>
      <CardContent>
        <Typography
          align="center"
          sx={{ fontSize: 18 }}
          gutterBottom
          fontFamily="Poppins"
        >
          {text}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default function ActionAreaCard({
  img,
  text,
  boxShadow,
  height,
  borderRadius,
  basicCard,
  basicCardText,
  icon
}) {
  return (
    <div style={{height:'450px'}}>
      <Card
        sx={{
          maxWidth: 300,
          maxHeight: height,
          boxShadow: boxShadow,
          borderRadius: borderRadius,
          position: "relative",
          borderRadius:'25px'
        }}
      >
        <CardActionArea>
          <CardMedia component="img" height="350"  image={img} />
          <CardContent
            style={{
              position: "absolute",
              bottom: "0",
              left: "0",
              right: "0",
              backgroundColor: "rgba(255, 255, 255, 0)",
            }}
          >
          {icon}
          </CardContent>
        </CardActionArea>
      </Card>
      {basicCard && <BasicCard text={basicCardText}/>}
    </div>
  );
}
