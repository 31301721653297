import Header from "../utils/Header";
import Footer from "../utils/Footer";
import Main from "./MainCareer";
import ImageWithOverlay from "../utils/ImageWithOverlay";
import AboutUs from '../../public/images/AboutUs.jpg';
export default function CompleteCareer() {
  return (
    <>
      <Header /> 
      <ImageWithOverlay image={AboutUs} height="300px" title="Careers" titlePartOne="Careers" titlePartTwo=""  />
      <Main />
      <Footer />
    </>
  );
}
