import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CompleteMedia from "./components/media/CompleteMedia";
import WheatRecommendation from "./components/wheat-registration/WheatRecommendation";
import CompleteHome from "./components/home/CompleteHome";
import CompleteAbout from "./components/about/CompleteAbout";
import CompleteContact from "./components/contact/CompleteContact";
import CropMonitoring from "./components/monitoring/CropMonitoring";
import StartMonitoring from "./components/monitoring/StartMonitoring";
import MapView from "./components/advisory";
import RegistrationMapView from "./components/wheat-registration";
import NotFoundPage from "./components/utils/NotFound.js";
import CompleteWeatherAdvisory from "./components/weatherAdvisory/CompleteWeatherAdvisory.js";
import CompletePunjabAgri from "./components/punjab_agri/CompletePunjabAgri.js";
import Landsat from "./components/punjab_agri/Landsat.js";
import Modis from "./components/punjab_agri/Modis.js";
import CompleteCareer from "./components/Careers/CompleteCareer.js";
import Sentinel3 from "./components/punjab_agri/Sentinel3.js";

function App() {
  return (
    <Router>
      <Routes>
        <Route path={`${process.env.PUBLIC_URL}/`} element={<CompleteHome />} />
        <Route
          path={`${process.env.PUBLIC_URL}/home`}
          element={<CompleteHome />}
        />
        <Route path="/" element={<CompleteHome />} />
        <Route path="/home" element={<CompleteHome />} />
        <Route path="/crop-monitoring" element={<CropMonitoring />} />
        <Route path="/about" element={<CompleteAbout />} />
        <Route path="/punjab-agri" element={<CompletePunjabAgri />} />
        <Route path="/punjab-agri/landsat" element={<Landsat />} />
        <Route path="/punjab-agri/modis" element={<Modis />} />
        <Route path="/punjab-agri/sentinel-3" element={<Sentinel3 />} />

        <Route path="/contact" element={<CompleteContact />} />
        <Route
          path="/projects"
          element={
            <NotFoundPage
              title={"Underconstruction!"}
              statusCode={"503 - Page currently unavilable"}
              message={
                "The page you are looking for is in development and will be available shortly. Thank you for your patience."
              }
            />
          }
        />
        <Route
          path="/investors"
          element={
            <NotFoundPage
              title={"Underconstruction!"}
              statusCode={"503 - Page currently unavilable"}
              message={
                "The page you are looking for is in development and will be available shortly. Thank you for your patience."
              }
            />
          }
        />
        <Route
          path="/sports"
          element={
            <NotFoundPage
              title={"Underconstruction!"}
              statusCode={"503 - Page currently unavilable"}
              message={
                "The page you are looking for is in development and will be available shortly. Thank you for your patience."
              }
            />
          }
        />
        <Route
          path="/crs"
          element={
            <NotFoundPage
              title={"Underconstruction!"}
              statusCode={"503 - Page currently unavilable"}
              message={
                "The page you are looking for is in development and will be available shortly. Thank you for your patience."
              }
            />
          }
        />
        <Route
          path="/Careers"
          element={
            <CompleteCareer/>
          }
        />
        <Route path="/media" element={<CompleteMedia />} />
        <Route
          path="/tenders"
          element={
            <NotFoundPage
              title={"Underconstruction!"}
              statusCode={"503 - Page currently unavilable"}
              message={
                "The page you are looking for is in development and will be available shortly. Thank you for your patience."
              }
            />
          }
        />
        <Route path="/advisory" element={<MapView />} />
        <Route path="/wheat-registration" element={<RegistrationMapView />} />
        {/* <Route path="/farm-recommendation" element={<WheatRecommendation/>}/> */}
        <Route path="/start-monitoring" element={<StartMonitoring />} />
        <Route path="/weather-advisory" element={<CompleteWeatherAdvisory />} />
      </Routes>
    </Router>
  );
}

export default App;
