export const landsat_sep01 = [
  { DISTRICT: "Attock", MIN: -0.08, MAX: 0.788, MEAN: 0.3772 },
  { DISTRICT: "Bahawalnagar", MIN: -0.08, MAX: 0.788, MEAN: 0.4031 },
  { DISTRICT: "Bahawalpur", MIN: -0.08, MAX: 0.752, MEAN: 0.2442 },
  { DISTRICT: "Bhakkar", MIN: -0.08, MAX: 0.772, MEAN: 0.2662 },
  { DISTRICT: "Chakwal", MIN: -0.08, MAX: 0.832, MEAN: 0.4083 },
  { DISTRICT: "Chiniot", MIN: 0.044, MAX: 0.748, MEAN: 0.5161 },
  { DISTRICT: "Dera Ghazi Khan", MIN: -0.08, MAX: 0.784, MEAN: 0.2917 },
  { DISTRICT: "Faisalabad", MIN: 0.064, MAX: 0.768, MEAN: 0.4326 },
  { DISTRICT: "Gujranwala", MIN: 0.0, MAX: 0.796, MEAN: 0.6166 },
  { DISTRICT: "Gujrat", MIN: -0.008, MAX: 0.736, MEAN: 0.4836 },
  { DISTRICT: "Hafizabad", MIN: 0.008, MAX: 0.796, MEAN: 0.6314 },
  { DISTRICT: "Jhang", MIN: -0.064, MAX: 0.76, MEAN: 0.4423 },
  { DISTRICT: "Jhelum", MIN: -0.068, MAX: 0.8, MEAN: 0.4158 },
  { DISTRICT: "Kasur", MIN: -0.08, MAX: 0.836, MEAN: 0.5008 },
  { DISTRICT: "Khanewal", MIN: 0.016, MAX: 0.724, MEAN: 0.5122 },
  { DISTRICT: "Khushab", MIN: -0.08, MAX: 0.784, MEAN: 0.3404 },
  { DISTRICT: "Lahore", MIN: -0.06, MAX: 0.808, MEAN: 0.4083 },
  { DISTRICT: "Layyah", MIN: -0.08, MAX: 0.732, MEAN: 0.3316 },
  { DISTRICT: "Lodhran", MIN: -0.056, MAX: 0.76, MEAN: 0.532 },
  { DISTRICT: "Mandi Bahauddin", MIN: -0.016, MAX: 0.768, MEAN: 0.5597 },
  { DISTRICT: "Mianwali", MIN: -0.08, MAX: 0.772, MEAN: 0.3862 },
  { DISTRICT: "Multan", MIN: -0.056, MAX: 0.732, MEAN: 0.4819 },
  { DISTRICT: "Muzaffargarh", MIN: -0.08, MAX: 0.768, MEAN: 0.4287 },
  { DISTRICT: "Nankana Sahib", MIN: 0.088, MAX: 0.784, MEAN: 0.581 },
  { DISTRICT: "Narowal", MIN: 0.108, MAX: 0.864, MEAN: 0.6308 },
  { DISTRICT: "Okara", MIN: -0.08, MAX: 0.752, MEAN: 0.4899 },
  { DISTRICT: "Pakpattan", MIN: -0.08, MAX: 0.716, MEAN: 0.4335 },
  { DISTRICT: "Rahim Yar Khan", MIN: -0.08, MAX: 0.852, MEAN: 0.3918 },
  { DISTRICT: "Rajanpur", MIN: -0.08, MAX: 0.82, MEAN: 0.259 },
  { DISTRICT: "Rawalpindi", MIN: -0.08, MAX: 0.92, MEAN: 0.4911 },
  { DISTRICT: "Sahiwal", MIN: 0.1, MAX: 0.724, MEAN: 0.4593 },
  { DISTRICT: "Sargodha", MIN: 0.02, MAX: 0.74, MEAN: 0.5017 },
  { DISTRICT: "Sheikhupura", MIN: 0.056, MAX: 0.796, MEAN: 0.5892 },
  { DISTRICT: "Sialkot", MIN: -0.076, MAX: 0.792, MEAN: 0.5938 },
  { DISTRICT: "Toba Tek Singh", MIN: 0.028, MAX: 0.756, MEAN: 0.4786 },
  { DISTRICT: "Vehari", MIN: -0.08, MAX: 0.736, MEAN: 0.4404 },
];

export const landsat_sep11 = [
  { DISTRICT: "Attock", MIN: -0.08, MAX: 0.764, MEAN: 0.3247 },
  { DISTRICT: "Bahawalnagar", MIN: -0.08, MAX: 0.9, MEAN: 0.4324 },
  { DISTRICT: "Bahawalpur", MIN: -0.08, MAX: 0.808, MEAN: 0.2571 },
  { DISTRICT: "Bhakkar", MIN: -0.08, MAX: 0.752, MEAN: 0.2373 },
  { DISTRICT: "Chakwal", MIN: -0.08, MAX: 0.82, MEAN: 0.3548 },
  { DISTRICT: "Chiniot", MIN: -0.052, MAX: 0.744, MEAN: 0.5 },
  { DISTRICT: "Dera Ghazi Khan", MIN: -0.08, MAX: 0.848, MEAN: 0.3059 },
  { DISTRICT: "Faisalabad", MIN: 0.068, MAX: 0.764, MEAN: 0.4506 },
  { DISTRICT: "Gujranwala", MIN: -0.02, MAX: 0.808, MEAN: 0.5838 },
  { DISTRICT: "Gujrat", MIN: -0.032, MAX: 0.772, MEAN: 0.4739 },
  { DISTRICT: "Hafizabad", MIN: 0.016, MAX: 0.86, MEAN: 0.5937 },
  { DISTRICT: "Jhang", MIN: -0.08, MAX: 0.832, MEAN: 0.4496 },
  { DISTRICT: "Jhelum", MIN: -0.08, MAX: 0.792, MEAN: 0.3923 },
  { DISTRICT: "Kasur", MIN: -0.004, MAX: 0.764, MEAN: 0.4657 },
  { DISTRICT: "Khanewal", MIN: -0.004, MAX: 0.824, MEAN: 0.5364 },
  { DISTRICT: "Khushab", MIN: -0.08, MAX: 0.764, MEAN: 0.3112 },
  { DISTRICT: "Lahore", MIN: 0.004, MAX: 0.768, MEAN: 0.3803 },
  { DISTRICT: "Layyah", MIN: -0.08, MAX: 0.792, MEAN: 0.3175 },
  { DISTRICT: "Lodhran", MIN: -0.08, MAX: 0.852, MEAN: 0.5866 },
  { DISTRICT: "Mandi Bahauddin", MIN: 0.004, MAX: 0.744, MEAN: 0.5503 },
  { DISTRICT: "Mianwali", MIN: -0.08, MAX: 0.748, MEAN: 0.3266 },
  { DISTRICT: "Multan", MIN: -0.08, MAX: 0.832, MEAN: 0.4963 },
  { DISTRICT: "Muzaffargarh", MIN: -0.08, MAX: 0.82, MEAN: 0.4172 },
  { DISTRICT: "Nankana Sahib", MIN: 0.112, MAX: 0.884, MEAN: 0.5611 },
  { DISTRICT: "Narowal", MIN: 0.128, MAX: 0.836, MEAN: 0.6359 },
  { DISTRICT: "Okara", MIN: -0.08, MAX: 0.8, MEAN: 0.5055 },
  { DISTRICT: "Pakpattan", MIN: -0.08, MAX: 0.8, MEAN: 0.5212 },
  { DISTRICT: "Rahim Yar Khan", MIN: -0.08, MAX: 0.836, MEAN: 0.393 },
  { DISTRICT: "Rajanpur", MIN: -0.08, MAX: 0.86, MEAN: 0.2611 },
  { DISTRICT: "Rawalpindi", MIN: -0.08, MAX: 0.908, MEAN: 0.4576 },
  { DISTRICT: "Sahiwal", MIN: 0.096, MAX: 0.776, MEAN: 0.5256 },
  { DISTRICT: "Sargodha", MIN: -0.08, MAX: 0.74, MEAN: 0.4478 },
  { DISTRICT: "Sheikhupura", MIN: 0.048, MAX: 0.82, MEAN: 0.5612 },
  { DISTRICT: "Sialkot", MIN: -0.06, MAX: 0.836, MEAN: 0.6354 },
  { DISTRICT: "Toba Tek Singh", MIN: 0.048, MAX: 0.828, MEAN: 0.5047 },
  { DISTRICT: "Vehari", MIN: -0.08, MAX: 0.828, MEAN: 0.5442 },
];

export const landsat_sep21 = [
  { DISTRICT: "Attock", MIN: -0.08, MAX: 0.92, MEAN: 0.3745 },
  { DISTRICT: "Bahawalnagar", MIN: -0.08, MAX: 0.92, MEAN: 0.4626 },
  { DISTRICT: "Bahawalpur", MIN: -0.08, MAX: 0.908, MEAN: 0.2582 },
  { DISTRICT: "Bhakkar", MIN: -0.08, MAX: 0.756, MEAN: 0.2364 },
  { DISTRICT: "Chakwal", MIN: -0.08, MAX: 0.92, MEAN: 0.3829 },
  { DISTRICT: "Chiniot", MIN: -0.08, MAX: 0.92, MEAN: 0.6019 },
  { DISTRICT: "Dera Ghazi Khan", MIN: -0.08, MAX: 0.92, MEAN: 0.2915 },
  { DISTRICT: "Faisalabad", MIN: -0.02, MAX: 0.92, MEAN: 0.5287 },
  { DISTRICT: "Gujranwala", MIN: -0.08, MAX: 0.92, MEAN: 0.687 },
  { DISTRICT: "Gujrat", MIN: -0.08, MAX: 0.876, MEAN: 0.5556 },
  { DISTRICT: "Hafizabad", MIN: -0.08, MAX: 0.92, MEAN: 0.741 },
  { DISTRICT: "Jhang", MIN: -0.08, MAX: 0.9, MEAN: 0.4768 },
  { DISTRICT: "Jhelum", MIN: -0.08, MAX: 0.92, MEAN: 0.4528 },
  { DISTRICT: "Kasur", MIN: -0.08, MAX: 0.908, MEAN: 0.544 },
  { DISTRICT: "Khanewal", MIN: -0.08, MAX: 0.888, MEAN: 0.5749 },
  { DISTRICT: "Khushab", MIN: -0.08, MAX: 0.92, MEAN: 0.3415 },
  { DISTRICT: "Lahore", MIN: -0.08, MAX: 0.88, MEAN: 0.4886 },
  { DISTRICT: "Layyah", MIN: -0.08, MAX: 0.804, MEAN: 0.3247 },
  { DISTRICT: "Lodhran", MIN: 0.02, MAX: 0.844, MEAN: 0.6093 },
  { DISTRICT: "Mandi Bahauddin", MIN: -0.08, MAX: 0.92, MEAN: 0.6813 },
  { DISTRICT: "Mianwali", MIN: -0.08, MAX: 0.816, MEAN: 0.3334 },
  { DISTRICT: "Multan", MIN: -0.08, MAX: 0.804, MEAN: 0.5033 },
  { DISTRICT: "Muzaffargarh", MIN: -0.08, MAX: 0.868, MEAN: 0.4309 },
  { DISTRICT: "Nankana Sahib", MIN: -0.08, MAX: 0.92, MEAN: 0.7176 },
  { DISTRICT: "Narowal", MIN: -0.08, MAX: 0.86, MEAN: 0.6814 },
  { DISTRICT: "Okara", MIN: -0.08, MAX: 0.92, MEAN: 0.5846 },
  { DISTRICT: "Pakpattan", MIN: -0.08, MAX: 0.904, MEAN: 0.5841 },
  { DISTRICT: "Rahim Yar Khan", MIN: -0.08, MAX: 0.852, MEAN: 0.3938 },
  { DISTRICT: "Rajanpur", MIN: -0.08, MAX: 0.88, MEAN: 0.2616 },
  { DISTRICT: "Rawalpindi", MIN: -0.08, MAX: 0.92, MEAN: 0.5162 },
  { DISTRICT: "Sahiwal", MIN: 0.084, MAX: 0.896, MEAN: 0.5691 },
  { DISTRICT: "Sargodha", MIN: -0.08, MAX: 0.92, MEAN: 0.5348 },
  { DISTRICT: "Sheikhupura", MIN: -0.08, MAX: 0.92, MEAN: 0.6876 },
  { DISTRICT: "Sialkot", MIN: -0.08, MAX: 0.884, MEAN: 0.6868 },
  { DISTRICT: "Toba Tek Singh", MIN: -0.04, MAX: 0.896, MEAN: 0.5413 },
  { DISTRICT: "Vehari", MIN: -0.08, MAX: 0.92, MEAN: 0.6136 },
];

export const landsat_oct01 = [
  { DISTRICT: "Attock", MIN: -0.08, MAX: 0.92, MEAN: 0.3824 },
  { DISTRICT: "Bahawalnagar", MIN: -0.08, MAX: 0.86, MEAN: 0.428 },
  { DISTRICT: "Bahawalpur", MIN: -0.08, MAX: 0.8, MEAN: 0.2418 },
  { DISTRICT: "Bhakkar", MIN: -0.08, MAX: 0.768, MEAN: 0.2542 },
  { DISTRICT: "Chakwal", MIN: -0.08, MAX: 0.92, MEAN: 0.3731 },
  { DISTRICT: "Chiniot", MIN: 0.012, MAX: 0.832, MEAN: 0.5455 },
  { DISTRICT: "Dera Ghazi Khan", MIN: -0.08, MAX: 0.748, MEAN: 0.2642 },
  { DISTRICT: "Faisalabad", MIN: -0.068, MAX: 0.8, MEAN: 0.4925 },
  { DISTRICT: "Gujranwala", MIN: -0.08, MAX: 0.832, MEAN: 0.5904 },
  { DISTRICT: "Gujrat", MIN: -0.08, MAX: 0.852, MEAN: 0.4913 },
  { DISTRICT: "Hafizabad", MIN: -0.056, MAX: 0.884, MEAN: 0.6685 },
  { DISTRICT: "Jhang", MIN: -0.08, MAX: 0.836, MEAN: 0.4645 },
  { DISTRICT: "Jhelum", MIN: -0.08, MAX: 0.9, MEAN: 0.4181 },
  { DISTRICT: "Kasur", MIN: -0.048, MAX: 0.808, MEAN: 0.5118 },
  { DISTRICT: "Khanewal", MIN: -0.08, MAX: 0.764, MEAN: 0.5181 },
  { DISTRICT: "Khushab", MIN: -0.08, MAX: 0.868, MEAN: 0.3555 },
  { DISTRICT: "Lahore", MIN: -0.08, MAX: 0.764, MEAN: 0.4285 },
  { DISTRICT: "Layyah", MIN: -0.08, MAX: 0.78, MEAN: 0.3021 },
  { DISTRICT: "Lodhran", MIN: 0.02, MAX: 0.796, MEAN: 0.5407 },
  { DISTRICT: "Mandi Bahauddin", MIN: -0.08, MAX: 0.88, MEAN: 0.6253 },
  { DISTRICT: "Mianwali", MIN: -0.08, MAX: 0.864, MEAN: 0.3481 },
  { DISTRICT: "Multan", MIN: -0.072, MAX: 0.756, MEAN: 0.4599 },
  { DISTRICT: "Muzaffargarh", MIN: -0.08, MAX: 0.76, MEAN: 0.3845 },
  { DISTRICT: "Nankana Sahib", MIN: 0.088, MAX: 0.828, MEAN: 0.6208 },
  { DISTRICT: "Narowal", MIN: 0.092, MAX: 0.888, MEAN: 0.6303 },
  { DISTRICT: "Okara", MIN: -0.08, MAX: 0.796, MEAN: 0.5083 },
  { DISTRICT: "Pakpattan", MIN: -0.08, MAX: 0.772, MEAN: 0.5143 },
  { DISTRICT: "Rahim Yar Khan", MIN: -0.08, MAX: 0.732, MEAN: 0.3419 },
  { DISTRICT: "Rajanpur", MIN: -0.08, MAX: 0.732, MEAN: 0.2277 },
  { DISTRICT: "Rawalpindi", MIN: -0.08, MAX: 0.9, MEAN: 0.4837 },
  { DISTRICT: "Sahiwal", MIN: 0.092, MAX: 0.784, MEAN: 0.5155 },
  { DISTRICT: "Sargodha", MIN: -0.08, MAX: 0.856, MEAN: 0.5071 },
  { DISTRICT: "Sheikhupura", MIN: -0.06, MAX: 0.848, MEAN: 0.6108 },
  { DISTRICT: "Sialkot", MIN: -0.08, MAX: 0.852, MEAN: 0.6248 },
  { DISTRICT: "Toba Tek Singh", MIN: 0.008, MAX: 0.808, MEAN: 0.5133 },
  { DISTRICT: "Vehari", MIN: -0.08, MAX: 0.8, MEAN: 0.5606 },
];

export const landsat_oct11 = [
  { DISTRICT: "Attock", MIN: -0.08, MAX: 0.82, MEAN: 0.347 },
  { DISTRICT: "Bahawalnagar", MIN: -0.08, MAX: 0.828, MEAN: 0.3987 },
  { DISTRICT: "Bahawalpur", MIN: -0.08, MAX: 0.856, MEAN: 0.2377 },
  { DISTRICT: "Bhakkar", MIN: -0.08, MAX: 0.7, MEAN: 0.2503 },
  { DISTRICT: "Chakwal", MIN: -0.08, MAX: 0.828, MEAN: 0.332 },
  { DISTRICT: "Chiniot", MIN: -0.08, MAX: 0.756, MEAN: 0.4676 },
  { DISTRICT: "Dera Ghazi Khan", MIN: -0.08, MAX: 0.776, MEAN: 0.2485 },
  { DISTRICT: "Faisalabad", MIN: -0.08, MAX: 0.74, MEAN: 0.4378 },
  { DISTRICT: "Gujranwala", MIN: -0.08, MAX: 0.884, MEAN: 0.4867 },
  { DISTRICT: "Gujrat", MIN: -0.08, MAX: 0.78, MEAN: 0.4363 },
  { DISTRICT: "Hafizabad", MIN: -0.08, MAX: 0.772, MEAN: 0.5391 },
  { DISTRICT: "Jhang", MIN: -0.08, MAX: 0.76, MEAN: 0.4236 },
  { DISTRICT: "Jhelum", MIN: -0.08, MAX: 0.804, MEAN: 0.3591 },
  { DISTRICT: "Kasur", MIN: -0.08, MAX: 0.752, MEAN: 0.4502 },
  { DISTRICT: "Khanewal", MIN: -0.08, MAX: 0.804, MEAN: 0.4922 },
  { DISTRICT: "Khushab", MIN: -0.08, MAX: 0.792, MEAN: 0.344 },
  { DISTRICT: "Lahore", MIN: -0.08, MAX: 0.724, MEAN: 0.3897 },
  { DISTRICT: "Layyah", MIN: -0.08, MAX: 0.756, MEAN: 0.2806 },
  { DISTRICT: "Lodhran", MIN: 0.004, MAX: 0.836, MEAN: 0.5458 },
  { DISTRICT: "Mandi Bahauddin", MIN: -0.08, MAX: 0.756, MEAN: 0.5164 },
  { DISTRICT: "Mianwali", MIN: -0.08, MAX: 0.776, MEAN: 0.3149 },
  { DISTRICT: "Multan", MIN: -0.08, MAX: 0.784, MEAN: 0.4518 },
  { DISTRICT: "Muzaffargarh", MIN: -0.08, MAX: 0.836, MEAN: 0.3746 },
  { DISTRICT: "Nankana Sahib", MIN: 0.06, MAX: 0.736, MEAN: 0.506 },
  { DISTRICT: "Narowal", MIN: 0.048, MAX: 0.896, MEAN: 0.6143 },
  { DISTRICT: "Okara", MIN: -0.08, MAX: 0.748, MEAN: 0.4329 },
  { DISTRICT: "Pakpattan", MIN: -0.08, MAX: 0.744, MEAN: 0.4609 },
  { DISTRICT: "Rahim Yar Khan", MIN: -0.08, MAX: 0.796, MEAN: 0.3364 },
  { DISTRICT: "Rajanpur", MIN: -0.08, MAX: 0.748, MEAN: 0.2126 },
  { DISTRICT: "Rawalpindi", MIN: -0.08, MAX: 0.88, MEAN: 0.4226 },
  { DISTRICT: "Sahiwal", MIN: 0.112, MAX: 0.744, MEAN: 0.4612 },
  { DISTRICT: "Sargodha", MIN: -0.08, MAX: 0.76, MEAN: 0.4433 },
  { DISTRICT: "Sheikhupura", MIN: -0.08, MAX: 0.892, MEAN: 0.5144 },
  { DISTRICT: "Sialkot", MIN: -0.08, MAX: 0.876, MEAN: 0.6038 },
  { DISTRICT: "Toba Tek Singh", MIN: 0.004, MAX: 0.748, MEAN: 0.4691 },
  { DISTRICT: "Vehari", MIN: -0.08, MAX: 0.832, MEAN: 0.5422 },
];

export const landsat_oct21 = [
  { DISTRICT: "Attock", MIN: -0.08, MAX: 0.876, MEAN: 0.3485 },
  { DISTRICT: "Bahawalnagar", MIN: -0.08, MAX: 0.768, MEAN: 0.3492 },
  { DISTRICT: "Bahawalpur", MIN: -0.052, MAX: 0.8, MEAN: 0.2234 },
  { DISTRICT: "Bhakkar", MIN: -0.08, MAX: 0.74, MEAN: 0.2441 },
  { DISTRICT: "Chakwal", MIN: -0.08, MAX: 0.848, MEAN: 0.3496 },
  { DISTRICT: "Chiniot", MIN: -0.08, MAX: 0.78, MEAN: 0.509 },
  { DISTRICT: "Dera Ghazi Khan", MIN: -0.08, MAX: 0.708, MEAN: 0.236 },
  { DISTRICT: "Faisalabad", MIN: -0.08, MAX: 0.768, MEAN: 0.4735 },
  { DISTRICT: "Gujranwala", MIN: -0.08, MAX: 0.88, MEAN: 0.4793 },
  { DISTRICT: "Gujrat", MIN: -0.08, MAX: 0.888, MEAN: 0.4869 },
  { DISTRICT: "Hafizabad", MIN: -0.08, MAX: 0.784, MEAN: 0.5459 },
  { DISTRICT: "Jhang", MIN: -0.08, MAX: 0.78, MEAN: 0.4129 },
  { DISTRICT: "Jhelum", MIN: -0.08, MAX: 0.824, MEAN: 0.4179 },
  { DISTRICT: "Kasur", MIN: -0.08, MAX: 0.848, MEAN: 0.4682 },
  { DISTRICT: "Khanewal", MIN: -0.08, MAX: 0.732, MEAN: 0.4249 },
  { DISTRICT: "Khushab", MIN: -0.08, MAX: 0.792, MEAN: 0.3576 },
  { DISTRICT: "Lahore", MIN: -0.08, MAX: 0.792, MEAN: 0.427 },
  { DISTRICT: "Layyah", MIN: -0.08, MAX: 0.712, MEAN: 0.2647 },
  { DISTRICT: "Lodhran", MIN: 0.064, MAX: 0.752, MEAN: 0.4914 },
  { DISTRICT: "Mandi Bahauddin", MIN: -0.08, MAX: 0.892, MEAN: 0.5884 },
  { DISTRICT: "Mianwali", MIN: -0.08, MAX: 0.804, MEAN: 0.3109 },
  { DISTRICT: "Multan", MIN: -0.08, MAX: 0.72, MEAN: 0.4059 },
  { DISTRICT: "Muzaffargarh", MIN: -0.08, MAX: 0.792, MEAN: 0.3432 },
  { DISTRICT: "Nankana Sahib", MIN: -0.08, MAX: 0.796, MEAN: 0.5136 },
  { DISTRICT: "Narowal", MIN: 0.048, MAX: 0.9, MEAN: 0.5914 },
  { DISTRICT: "Okara", MIN: -0.08, MAX: 0.844, MEAN: 0.4283 },
  { DISTRICT: "Pakpattan", MIN: -0.08, MAX: 0.716, MEAN: 0.4286 },
  { DISTRICT: "Rahim Yar Khan", MIN: -0.08, MAX: 0.788, MEAN: 0.3285 },
  { DISTRICT: "Rajanpur", MIN: -0.08, MAX: 0.736, MEAN: 0.2051 },
  { DISTRICT: "Rawalpindi", MIN: -0.08, MAX: 0.92, MEAN: 0.4546 },
  { DISTRICT: "Sahiwal", MIN: 0.08, MAX: 0.8, MEAN: 0.4279 },
  { DISTRICT: "Sargodha", MIN: -0.08, MAX: 0.816, MEAN: 0.494 },
  { DISTRICT: "Sheikhupura", MIN: -0.08, MAX: 0.908, MEAN: 0.5158 },
  { DISTRICT: "Sialkot", MIN: -0.08, MAX: 0.872, MEAN: 0.586 },
  { DISTRICT: "Toba Tek Singh", MIN: -0.004, MAX: 0.744, MEAN: 0.4702 },
  { DISTRICT: "Vehari", MIN: -0.08, MAX: 0.732, MEAN: 0.4804 },
];

export const landsat_nov01 = [
  { DISTRICT: "Attock", MIN: -0.08, MAX: 0.86, MEAN: 0.2341 },
  { DISTRICT: "Bahawalnagar", MIN: -0.08, MAX: 0.644, MEAN: 0.2681 },
  { DISTRICT: "Bahawalpur", MIN: -0.04, MAX: 0.8, MEAN: 0.1979 },
  { DISTRICT: "Bhakkar", MIN: -0.08, MAX: 0.632, MEAN: 0.187 },
  { DISTRICT: "Chakwal", MIN: -0.08, MAX: 0.884, MEAN: 0.2347 },
  { DISTRICT: "Chiniot", MIN: -0.08, MAX: 0.612, MEAN: 0.3647 },
  { DISTRICT: "Dera Ghazi Khan", MIN: -0.08, MAX: 0.676, MEAN: 0.1852 },
  { DISTRICT: "Faisalabad", MIN: -0.012, MAX: 0.628, MEAN: 0.3573 },
  { DISTRICT: "Gujranwala", MIN: -0.08, MAX: 0.676, MEAN: 0.305 },
  { DISTRICT: "Gujrat", MIN: -0.08, MAX: 0.74, MEAN: 0.3297 },
  { DISTRICT: "Hafizabad", MIN: -0.08, MAX: 0.532, MEAN: 0.3127 },
  { DISTRICT: "Jhang", MIN: -0.08, MAX: 0.656, MEAN: 0.2953 },
  { DISTRICT: "Jhelum", MIN: -0.08, MAX: 0.888, MEAN: 0.2861 },
  { DISTRICT: "Kasur", MIN: -0.08, MAX: 0.8, MEAN: 0.3364 },
  { DISTRICT: "Khanewal", MIN: -0.072, MAX: 0.684, MEAN: 0.308 },
  { DISTRICT: "Khushab", MIN: -0.08, MAX: 0.696, MEAN: 0.2398 },
  { DISTRICT: "Lahore", MIN: -0.036, MAX: 0.688, MEAN: 0.2809 },
  { DISTRICT: "Layyah", MIN: -0.08, MAX: 0.656, MEAN: 0.226 },
  { DISTRICT: "Lodhran", MIN: 0.02, MAX: 0.668, MEAN: 0.369 },
  { DISTRICT: "Mandi Bahauddin", MIN: -0.08, MAX: 0.656, MEAN: 0.3865 },
  { DISTRICT: "Mianwali", MIN: -0.08, MAX: 0.764, MEAN: 0.228 },
  { DISTRICT: "Multan", MIN: -0.08, MAX: 0.676, MEAN: 0.3066 },
  { DISTRICT: "Muzaffargarh", MIN: -0.08, MAX: 0.74, MEAN: 0.2743 },
  { DISTRICT: "Nankana Sahib", MIN: 0.044, MAX: 0.632, MEAN: 0.3514 },
  { DISTRICT: "Narowal", MIN: 0.048, MAX: 0.72, MEAN: 0.4292 },
  { DISTRICT: "Okara", MIN: -0.08, MAX: 0.668, MEAN: 0.3294 },
  { DISTRICT: "Pakpattan", MIN: -0.02, MAX: 0.584, MEAN: 0.3229 },
  { DISTRICT: "Rahim Yar Khan", MIN: -0.08, MAX: 0.8, MEAN: 0.2945 },
  { DISTRICT: "Rajanpur", MIN: -0.08, MAX: 0.74, MEAN: 0.1843 },
  { DISTRICT: "Rawalpindi", MIN: -0.08, MAX: 0.92, MEAN: 0.3495 },
  { DISTRICT: "Sahiwal", MIN: 0.028, MAX: 0.72, MEAN: 0.3043 },
  { DISTRICT: "Sargodha", MIN: -0.08, MAX: 0.744, MEAN: 0.3725 },
  { DISTRICT: "Sheikhupura", MIN: -0.08, MAX: 0.64, MEAN: 0.3302 },
  { DISTRICT: "Sialkot", MIN: -0.08, MAX: 0.736, MEAN: 0.4184 },
  { DISTRICT: "Toba Tek Singh", MIN: -0.08, MAX: 0.652, MEAN: 0.3399 },
  { DISTRICT: "Vehari", MIN: -0.08, MAX: 0.692, MEAN: 0.3709 },
];

export const landsat_nov11 = [
  { DISTRICT: "Attock", MIN: -0.08, MAX: 0.86, MEAN: 0.3087 },
  { DISTRICT: "Bahawalnagar", MIN: -0.08, MAX: 0.688, MEAN: 0.242 },
  { DISTRICT: "Bahawalpur", MIN: -0.076, MAX: 0.768, MEAN: 0.1938 },
  { DISTRICT: "Bhakkar", MIN: -0.08, MAX: 0.692, MEAN: 0.293 },
  { DISTRICT: "Chakwal", MIN: -0.08, MAX: 0.868, MEAN: 0.3097 },
  { DISTRICT: "Chiniot", MIN: -0.08, MAX: 0.66, MEAN: 0.4073 },
  { DISTRICT: "Dera Ghazi Khan", MIN: -0.08, MAX: 0.716, MEAN: 0.2429 },
  { DISTRICT: "Faisalabad", MIN: -0.08, MAX: 0.676, MEAN: 0.4293 },
  { DISTRICT: "Gujranwala", MIN: -0.08, MAX: 0.728, MEAN: 0.3332 },
  { DISTRICT: "Gujrat", MIN: -0.08, MAX: 0.816, MEAN: 0.3916 },
  { DISTRICT: "Hafizabad", MIN: -0.08, MAX: 0.592, MEAN: 0.3364 },
  { DISTRICT: "Jhang", MIN: -0.08, MAX: 0.72, MEAN: 0.3635 },
  { DISTRICT: "Jhelum", MIN: -0.08, MAX: 0.876, MEAN: 0.3556 },
  { DISTRICT: "Kasur", MIN: -0.08, MAX: 0.824, MEAN: 0.4349 },
  { DISTRICT: "Khanewal", MIN: -0.08, MAX: 0.708, MEAN: 0.3684 },
  { DISTRICT: "Khushab", MIN: -0.08, MAX: 0.708, MEAN: 0.3322 },
  { DISTRICT: "Lahore", MIN: -0.08, MAX: 0.668, MEAN: 0.3809 },
  { DISTRICT: "Layyah", MIN: -0.08, MAX: 0.664, MEAN: 0.2774 },
  { DISTRICT: "Lodhran", MIN: 0.06, MAX: 0.66, MEAN: 0.3581 },
  { DISTRICT: "Mandi Bahauddin", MIN: -0.08, MAX: 0.724, MEAN: 0.4603 },
  { DISTRICT: "Mianwali", MIN: -0.08, MAX: 0.772, MEAN: 0.308 },
  { DISTRICT: "Multan", MIN: -0.08, MAX: 0.732, MEAN: 0.3657 },
  { DISTRICT: "Muzaffargarh", MIN: -0.08, MAX: 0.784, MEAN: 0.3335 },
  { DISTRICT: "Nankana Sahib", MIN: -0.08, MAX: 0.644, MEAN: 0.3428 },
  { DISTRICT: "Narowal", MIN: 0.044, MAX: 0.708, MEAN: 0.356 },
  { DISTRICT: "Okara", MIN: -0.08, MAX: 0.692, MEAN: 0.3851 },
  { DISTRICT: "Pakpattan", MIN: -0.08, MAX: 0.62, MEAN: 0.3589 },
  { DISTRICT: "Rahim Yar Khan", MIN: -0.08, MAX: 0.792, MEAN: 0.2924 },
  { DISTRICT: "Rajanpur", MIN: -0.08, MAX: 0.792, MEAN: 0.2134 },
  { DISTRICT: "Rawalpindi", MIN: -0.08, MAX: 0.92, MEAN: 0.3923 },
  { DISTRICT: "Sahiwal", MIN: -0.08, MAX: 0.796, MEAN: 0.3957 },
  { DISTRICT: "Sargodha", MIN: -0.08, MAX: 0.764, MEAN: 0.4269 },
  { DISTRICT: "Sheikhupura", MIN: -0.08, MAX: 0.768, MEAN: 0.3547 },
  { DISTRICT: "Sialkot", MIN: -0.08, MAX: 0.7, MEAN: 0.398 },
  { DISTRICT: "Toba Tek Singh", MIN: -0.036, MAX: 0.724, MEAN: 0.4317 },
  { DISTRICT: "Vehari", MIN: -0.08, MAX: 0.7, MEAN: 0.3919 },
];

export const landsat_nov21 = [
    { DISTRICT: "Attock", MIN: -0.0800, MAX: 0.9200, MEAN: 0.3101 },
    { DISTRICT: "Bahawalnagar", MIN: -0.0560, MAX: 0.6480, MEAN: 0.2323 },
    { DISTRICT: "Bahawalpur", MIN: -0.0680, MAX: 0.7480, MEAN: 0.1857 },
    { DISTRICT: "Bhakkar", MIN: -0.0800, MAX: 0.6360, MEAN: 0.2166 },
    { DISTRICT: "Chakwal", MIN: -0.0800, MAX: 0.8840, MEAN: 0.2716 },
    { DISTRICT: "Chiniot", MIN: -0.0800, MAX: 0.6720, MEAN: 0.3741 },
    { DISTRICT: "Dera Ghazi Khan", MIN: -0.0800, MAX: 0.6160, MEAN: 0.1905 },
    { DISTRICT: "Faisalabad", MIN: -0.0720, MAX: 0.6800, MEAN: 0.3567 },
    { DISTRICT: "Gujranwala", MIN: -0.0800, MAX: 0.7880, MEAN: 0.2722 },
    { DISTRICT: "Gujrat", MIN: -0.0800, MAX: 0.7760, MEAN: 0.3587 },
    { DISTRICT: "Hafizabad", MIN: -0.0800, MAX: 0.5280, MEAN: 0.2354 },
    { DISTRICT: "Jhang", MIN: -0.0800, MAX: 0.6760, MEAN: 0.2818 },
    { DISTRICT: "Jhelum", MIN: -0.0800, MAX: 0.8520, MEAN: 0.3440 },
    { DISTRICT: "Kasur", MIN: -0.0800, MAX: 0.8400, MEAN: 0.3771 },
    { DISTRICT: "Khanewal", MIN: -0.0800, MAX: 0.6200, MEAN: 0.2905 },
    { DISTRICT: "Khushab", MIN: -0.0800, MAX: 0.6720, MEAN: 0.2742 },
    { DISTRICT: "Lahore", MIN: -0.0640, MAX: 0.6960, MEAN: 0.3261 },
    { DISTRICT: "Layyah", MIN: -0.0800, MAX: 0.6560, MEAN: 0.2051 },
    { DISTRICT: "Lodhran", MIN: 0.0440, MAX: 0.5840, MEAN: 0.2979 },
    { DISTRICT: "Mandi Bahauddin", MIN: -0.0800, MAX: 0.6680, MEAN: 0.3432 },
    { DISTRICT: "Mianwali", MIN: -0.0800, MAX: 0.7080, MEAN: 0.2432 },
    { DISTRICT: "Multan", MIN: -0.0800, MAX: 0.7080, MEAN: 0.2997 },
    { DISTRICT: "Muzaffargarh", MIN: -0.0800, MAX: 0.7120, MEAN: 0.2752 },
    { DISTRICT: "Nankana Sahib", MIN: 0.0000, MAX: 0.6560, MEAN: 0.2914 },
    { DISTRICT: "Narowal", MIN: 0.0000, MAX: 0.6840, MEAN: 0.2745 },
    { DISTRICT: "Okara", MIN: -0.0800, MAX: 0.7200, MEAN: 0.3960 },
    { DISTRICT: "Pakpattan", MIN: -0.0560, MAX: 0.6160, MEAN: 0.3401 },
    { DISTRICT: "Rahim Yar Khan", MIN: -0.0800, MAX: 0.7600, MEAN: 0.2628 },
    { DISTRICT: "Rajanpur", MIN: -0.0800, MAX: 0.6680, MEAN: 0.1886 },
    { DISTRICT: "Rawalpindi", MIN: -0.0800, MAX: 0.9200, MEAN: 0.4053 },
    { DISTRICT: "Sahiwal", MIN: -0.0480, MAX: 0.7680, MEAN: 0.3545 },
    { DISTRICT: "Sargodha", MIN: -0.0800, MAX: 0.7640, MEAN: 0.3852 },
    { DISTRICT: "Sheikhupura", MIN: -0.0800, MAX: 0.6960, MEAN: 0.2959 },
    { DISTRICT: "Sialkot", MIN: -0.0800, MAX: 0.7440, MEAN: 0.3384 },
    { DISTRICT: "Toba Tek Singh", MIN: -0.0800, MAX: 0.6480, MEAN: 0.3417 },
    { DISTRICT: "Vehari", MIN: -0.0800, MAX: 0.6080, MEAN: 0.3450 }
  ];


  export const landsat_dec01 = [
    { DISTRICT: "Attock", MIN: -0.0800, MAX: 0.8840, MEAN: 0.3518 },
    { DISTRICT: "Bahawalnagar", MIN: -0.0800, MAX: 0.6640, MEAN: 0.2393 },
    { DISTRICT: "Bahawalpur", MIN: -0.0800, MAX: 0.7840, MEAN: 0.1952 },
    { DISTRICT: "Bhakkar", MIN: -0.0800, MAX: 0.6120, MEAN: 0.2419 },
    { DISTRICT: "Chakwal", MIN: -0.0800, MAX: 0.8840, MEAN: 0.3587 },
    { DISTRICT: "Chiniot", MIN: -0.0520, MAX: 0.7360, MEAN: 0.4085 },
    { DISTRICT: "Dera Ghazi Khan", MIN: -0.0800, MAX: 0.6800, MEAN: 0.2395 },
    { DISTRICT: "Faisalabad", MIN: -0.0800, MAX: 0.7280, MEAN: 0.3744 },
    { DISTRICT: "Gujranwala", MIN: -0.0800, MAX: 0.7360, MEAN: 0.3354 },
    { DISTRICT: "Gujrat", MIN: -0.0800, MAX: 0.7520, MEAN: 0.3969 },
    { DISTRICT: "Hafizabad", MIN: -0.0800, MAX: 0.6600, MEAN: 0.3122 },
    { DISTRICT: "Jhang", MIN: -0.0800, MAX: 0.6840, MEAN: 0.3057 },
    { DISTRICT: "Jhelum", MIN: -0.0800, MAX: 0.8440, MEAN: 0.3976 },
    { DISTRICT: "Kasur", MIN: -0.0800, MAX: 0.8120, MEAN: 0.4140 },
    { DISTRICT: "Khanewal", MIN: -0.0800, MAX: 0.6360, MEAN: 0.3127 },
    { DISTRICT: "Khushab", MIN: -0.0800, MAX: 0.7000, MEAN: 0.3121 },
    { DISTRICT: "Lahore", MIN: -0.0400, MAX: 0.7240, MEAN: 0.3575 },
    { DISTRICT: "Layyah", MIN: -0.0800, MAX: 0.6840, MEAN: 0.2436 },
    { DISTRICT: "Lodhran", MIN: 0.0760, MAX: 0.5880, MEAN: 0.3240 },
    { DISTRICT: "Mandi Bahauddin", MIN: -0.0800, MAX: 0.7640, MEAN: 0.4327 },
    { DISTRICT: "Mianwali", MIN: -0.0800, MAX: 0.8040, MEAN: 0.2878 },
    { DISTRICT: "Multan", MIN: -0.0800, MAX: 0.7040, MEAN: 0.3041 },
    { DISTRICT: "Muzaffargarh", MIN: -0.0800, MAX: 0.6840, MEAN: 0.2927 },
    { DISTRICT: "Nankana Sahib", MIN: 0.0320, MAX: 0.6760, MEAN: 0.3231 },
    { DISTRICT: "Narowal", MIN: 0.0480, MAX: 0.6600, MEAN: 0.3436 },
    { DISTRICT: "Okara", MIN: -0.0800, MAX: 0.7760, MEAN: 0.4216 },
    { DISTRICT: "Pakpattan", MIN: -0.0800, MAX: 0.7000, MEAN: 0.3633 },
    { DISTRICT: "Rahim Yar Khan", MIN: -0.0800, MAX: 0.8200, MEAN: 0.2885 },
    { DISTRICT: "Rajanpur", MIN: -0.0800, MAX: 0.7120, MEAN: 0.2445 },
    { DISTRICT: "Rawalpindi", MIN: -0.0800, MAX: 0.9200, MEAN: 0.4253 },
    { DISTRICT: "Sahiwal", MIN: 0.0160, MAX: 0.7320, MEAN: 0.3551 },
    { DISTRICT: "Sargodha", MIN: -0.0800, MAX: 0.7800, MEAN: 0.4388 },
    { DISTRICT: "Sheikhupura", MIN: -0.0800, MAX: 0.7320, MEAN: 0.3088 },
    { DISTRICT: "Sialkot", MIN: -0.0800, MAX: 0.7760, MEAN: 0.3663 },
    { DISTRICT: "Toba Tek Singh", MIN: -0.0800, MAX: 0.7320, MEAN: 0.3392 },
    { DISTRICT: "Vehari", MIN: -0.0800, MAX: 0.6200, MEAN: 0.3367 }
  ];
  

  export const landsat_dec11 = [
  { DISTRICT: "Attock", MIN: -0.0800, MAX: 0.8280, MEAN: 0.3269 },
  { DISTRICT: "Bahawalnagar", MIN: -0.0800, MAX: 0.7240, MEAN: 0.3133 },
  { DISTRICT: "Bahawalpur", MIN: -0.0800, MAX: 0.7360, MEAN: 0.2157 },
  { DISTRICT: "Bhakkar", MIN: -0.0800, MAX: 0.5680, MEAN: 0.2259 },
  { DISTRICT: "Chakwal", MIN: -0.0800, MAX: 0.8640, MEAN: 0.3398 },
  { DISTRICT: "Chiniot", MIN: -0.0560, MAX: 0.6960, MEAN: 0.3742 },
  { DISTRICT: "Dera Ghazi Khan", MIN: -0.0800, MAX: 0.6640, MEAN: 0.2514 },
  { DISTRICT: "Faisalabad", MIN: 0.0040, MAX: 0.6680, MEAN: 0.3548 },
  { DISTRICT: "Gujranwala", MIN: -0.0800, MAX: 0.7280, MEAN: 0.3581 },
  { DISTRICT: "Gujrat", MIN: -0.0800, MAX: 0.7520, MEAN: 0.3807 },
  { DISTRICT: "Hafizabad", MIN: -0.0760, MAX: 0.7800, MEAN: 0.3325 },
  { DISTRICT: "Jhang", MIN: -0.0800, MAX: 0.6840, MEAN: 0.3039 },
  { DISTRICT: "Jhelum", MIN: -0.0800, MAX: 0.8320, MEAN: 0.3754 },
  { DISTRICT: "Kasur", MIN: -0.0800, MAX: 0.7800, MEAN: 0.4341 },
  { DISTRICT: "Khanewal", MIN: -0.0800, MAX: 0.6680, MEAN: 0.3822 },
  { DISTRICT: "Khushab", MIN: -0.0800, MAX: 0.8400, MEAN: 0.2814 },
  { DISTRICT: "Lahore", MIN: -0.0800, MAX: 0.7160, MEAN: 0.3422 },
  { DISTRICT: "Layyah", MIN: -0.0800, MAX: 0.6440, MEAN: 0.2507 },
  { DISTRICT: "Lodhran", MIN: 0.0760, MAX: 0.6560, MEAN: 0.4039 },
  { DISTRICT: "Mandi Bahauddin", MIN: -0.0800, MAX: 0.7120, MEAN: 0.3717 },
  { DISTRICT: "Mianwali", MIN: -0.0800, MAX: 0.7520, MEAN: 0.2811 },
  { DISTRICT: "Multan", MIN: -0.0800, MAX: 0.7120, MEAN: 0.3631 },
  { DISTRICT: "Muzaffargarh", MIN: -0.0800, MAX: 0.6600, MEAN: 0.3209 },
  { DISTRICT: "Nankana Sahib", MIN: -0.0240, MAX: 0.6840, MEAN: 0.3642 },
  { DISTRICT: "Narowal", MIN: 0.0120, MAX: 0.7240, MEAN: 0.3141 },
  { DISTRICT: "Okara", MIN: -0.0800, MAX: 0.7640, MEAN: 0.4831 },
  { DISTRICT: "Pakpattan", MIN: -0.0040, MAX: 0.7200, MEAN: 0.4093 },
  { DISTRICT: "Rahim Yar Khan", MIN: -0.0800, MAX: 0.7560, MEAN: 0.2979 },
  { DISTRICT: "Rajanpur", MIN: -0.0800, MAX: 0.7680, MEAN: 0.2535 },
  { DISTRICT: "Rawalpindi", MIN: -0.0800, MAX: 0.9200, MEAN: 0.4208 },
  { DISTRICT: "Sahiwal", MIN: 0.0760, MAX: 0.7200, MEAN: 0.4081 },
  { DISTRICT: "Sargodha", MIN: -0.0800, MAX: 0.7600, MEAN: 0.4055 },
  { DISTRICT: "Sheikhupura", MIN: -0.0800, MAX: 0.7320, MEAN: 0.3452 },
  { DISTRICT: "Sialkot", MIN: -0.0800, MAX: 0.7240, MEAN: 0.3329 },
  { DISTRICT: "Toba Tek Singh", MIN: -0.0480, MAX: 0.7400, MEAN: 0.3624 },
  { DISTRICT: "Vehari", MIN: -0.0800, MAX: 0.6480, MEAN: 0.3857 }
];

export const landsat_dec21 = [
    { DISTRICT: "Attock", MIN: -0.0800, MAX: 0.8880, MEAN: 0.3490 },
    { DISTRICT: "Bahawalnagar", MIN: -0.0800, MAX: 0.8800, MEAN: 0.4392 },
    { DISTRICT: "Bahawalpur", MIN: -0.0800, MAX: 0.9200, MEAN: 0.2556 },
    { DISTRICT: "Bhakkar", MIN: -0.0800, MAX: 0.6960, MEAN: 0.2654 },
    { DISTRICT: "Chakwal", MIN: -0.0800, MAX: 0.9200, MEAN: 0.3519 },
    { DISTRICT: "Chiniot", MIN: -0.0760, MAX: 0.7720, MEAN: 0.4209 },
    { DISTRICT: "Dera Ghazi Khan", MIN: -0.0800, MAX: 0.7920, MEAN: 0.2850 },
    { DISTRICT: "Faisalabad", MIN: -0.0160, MAX: 0.8200, MEAN: 0.4155 },
    { DISTRICT: "Gujranwala", MIN: -0.0800, MAX: 0.8120, MEAN: 0.4315 },
    { DISTRICT: "Gujrat", MIN: -0.0800, MAX: 0.7360, MEAN: 0.3806 },
    { DISTRICT: "Hafizabad", MIN: -0.0800, MAX: 0.8040, MEAN: 0.4407 },
    { DISTRICT: "Jhang", MIN: -0.0800, MAX: 0.7520, MEAN: 0.3509 },
    { DISTRICT: "Jhelum", MIN: -0.0800, MAX: 0.8080, MEAN: 0.3710 },
    { DISTRICT: "Kasur", MIN: -0.0800, MAX: 0.8480, MEAN: 0.4721 },
    { DISTRICT: "Khanewal", MIN: -0.0800, MAX: 0.8320, MEAN: 0.4610 },
    { DISTRICT: "Khushab", MIN: -0.0800, MAX: 0.7480, MEAN: 0.3027 },
    { DISTRICT: "Lahore", MIN: -0.0520, MAX: 0.7400, MEAN: 0.3754 },
    { DISTRICT: "Layyah", MIN: -0.0800, MAX: 0.7360, MEAN: 0.3128 },
    { DISTRICT: "Lodhran", MIN: 0.0760, MAX: 0.8040, MEAN: 0.4827 },
    { DISTRICT: "Mandi Bahauddin", MIN: -0.0800, MAX: 0.7440, MEAN: 0.3841 },
    { DISTRICT: "Mianwali", MIN: -0.0800, MAX: 0.8480, MEAN: 0.3248 },
    { DISTRICT: "Multan", MIN: -0.0760, MAX: 0.7400, MEAN: 0.4271 },
    { DISTRICT: "Muzaffargarh", MIN: -0.0800, MAX: 0.7600, MEAN: 0.3899 },
    { DISTRICT: "Nankana Sahib", MIN: 0.0200, MAX: 0.7920, MEAN: 0.4520 },
    { DISTRICT: "Narowal", MIN: 0.0200, MAX: 0.7320, MEAN: 0.3374 },
    { DISTRICT: "Okara", MIN: -0.0800, MAX: 0.8400, MEAN: 0.5576 },
    { DISTRICT: "Pakpattan", MIN: -0.0200, MAX: 0.8600, MEAN: 0.5248 },
    { DISTRICT: "Rahim Yar Khan", MIN: -0.0800, MAX: 0.8360, MEAN: 0.3378 },
    { DISTRICT: "Rajanpur", MIN: -0.0800, MAX: 0.8320, MEAN: 0.2740 },
    { DISTRICT: "Rawalpindi", MIN: -0.0800, MAX: 0.9200, MEAN: 0.4166 },
    { DISTRICT: "Sahiwal", MIN: 0.0480, MAX: 0.8400, MEAN: 0.5046 },
    { DISTRICT: "Sargodha", MIN: -0.0800, MAX: 0.7840, MEAN: 0.4541 },
    { DISTRICT: "Sheikhupura", MIN: -0.0800, MAX: 0.7960, MEAN: 0.4243 },
    { DISTRICT: "Sialkot", MIN: -0.0800, MAX: 0.6800, MEAN: 0.3320 },
    { DISTRICT: "Toba Tek Singh", MIN: 0.0000, MAX: 0.7720, MEAN: 0.4442 },
    { DISTRICT: "Vehari", MIN: -0.0800, MAX: 0.8640, MEAN: 0.4719 }
  ];
  