import React from 'react';
import notFound from '../../public/images/not_found.jpg'
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
    containerStyle : {
        position: 'relative',
        height: '100vh',
      },
    notFoundStyle : {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '410px',
        width: '100%',
        textAlign: 'center',
      },
    notFound404Style : {
        height: '180px',
        position: 'relative',
        zIndex: '-1',
        '@media (max-width: 1280px)': {
            height: '100px',
          },
        '@media (max-width: 600px)': {
            height: '70px',
          }
      },
    h1Style : {
        fontFamily: 'Poppins',
        fontSize: '100px',
        margin: '0px',
        fontWeight: '900',
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        background: `url('${notFound}') no-repeat`,
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        '@media (max-width: 1280px)': {
            fontSize: '55px',
          },
        '@media (max-width: 600px)': {
            fontSize: '30px',
          },
      },
    h2Style : {
        fontFamily: 'Poppins',
        color: '#000',
        fontSize: '22px',
        fontWeight: '700',
        textTransform: 'uppercase',
        marginTop: '0px',
        '@media (max-width: 1280px)': {
            fontSize: '15px',
          },
        '@media (max-width: 600px)': {
            fontSize: '12px',
          }
      },
    pStyle : {
        fontFamily: 'Poppins',
        color: '#000',
        fontSize: '14px',
        fontWeight: '400',
        marginBottom: '20px',
        marginTop: '0px',
        '@media (max-width: 1280px)': {
            fontSize: '12px',
          },
        '@media (max-width: 600px)': {
            fontSize: '10px',
          }
      },
    linkStyle : {
        fontFamily: 'Poppins',
        fontSize: '14px',
        textDecoration: 'none',
        textTransform: 'uppercase',
        background: '#0046D5',
        display: 'inline-block',
        padding: '15px 30px',
        borderRadius: '40px',
        color: '#fff',
        fontWeight: '700',
        boxShadow: '0px 4px 15px -5px #0046D5',
        '@media (max-width: 1280px)': {
            fontSize: '12px',
          },
        '@media (max-width: 600px)': {
            fontSize: '10px',
          }
    }
}));
export default function NotFoundPage({title,statusCode,message}){
    const classes = useStyles();
    return (
        <div className={classes.containerStyle}>
          <div className={classes.notFoundStyle} >
            <div className={classes.notFound404Style}>
              <h1 className={classes.h1Style}>{title}</h1>
            </div>
            <h2 className={classes.h2Style}>{statusCode}</h2>
            <p className={classes.pStyle}>
              {message}
            </p>
            <a className={classes.linkStyle} href="/">
              Go To Home page
            </a>
          </div>
        </div>
      );
}