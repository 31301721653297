import React, { useEffect } from "react";
import Header from '../utils/Header'
import Footer from '../utils/Footer';
import Videogallery from './VideoGallery';
export default function CompleteMedia() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
    return (
      <>
        <Header />
        <Videogallery/>
        <Footer />
      </>
    );
}
  