import humidity_3Oct23_12Oct23 from "../../public/land/punjab/weather/Tehsil/3Oct23_12Oct23/Avg Humidity1-min.png"
import max_temp_3Oct23_12Oct23 from "../../public/land/punjab/weather/Tehsil/3Oct23_12Oct23/Avg Max Temp1-min.png"
import min_temp_3Oct23_12Oct23 from "../../public/land/punjab/weather/Tehsil/3Oct23_12Oct23/Avg Min Temp1-min.png"
import rainfall_3Oct23_12Oct23 from "../../public/land/punjab/weather/Tehsil/3Oct23_12Oct23/Avg Rainfall1-min.png"
import humidity_13Nov23_20Nov23 from "../../public/land/punjab/weather/Tehsil/13Nov23_20Nov23/Avg Humidity.png"
import max_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Tehsil/13Nov23_20Nov23/Avg Max Temp.png"
import min_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Tehsil/13Nov23_20Nov23/Avg Min Temp.png"
import avg_temp_13Nov23_20Nov23 from "../../public/land/punjab/weather/Tehsil/13Nov23_20Nov23/Avg Temperature.png"
import humidity_21Nov23_27Nov23 from "../../public/land/punjab/weather/Tehsil/21Nov23_27Nov23/Humidity.png"
import max_temp_21Nov23_27Nov23 from "../../public/land/punjab/weather/Tehsil/21Nov23_27Nov23/Tmax.png"
import min_temp_21Nov23_27Nov23 from "../../public/land/punjab/weather/Tehsil/21Nov23_27Nov23/Tmin.png"
import avg_temp_21Nov23_27Nov23 from "../../public/land/punjab/weather/Tehsil/21Nov23_27Nov23/Avg Temp.png"
import humidity_28Nov23_4Dec23 from "../../public/land/punjab/weather/Tehsil/28Nov23_4Dec23/Avg Humidity.png"
import max_temp_28Nov23_4Dec23 from "../../public/land/punjab/weather/Tehsil/28Nov23_4Dec23/Avg Max Temperature.png"
import min_temp_28Nov23_4Dec23 from "../../public/land/punjab/weather/Tehsil/28Nov23_4Dec23/Avg Min Temperature.png"
import avg_temp_28Nov23_4Dec23 from "../../public/land/punjab/weather/Tehsil/28Nov23_4Dec23/Avg Temperature.png"
import rainfall_28Nov23_4Dec23 from "../../public/land/punjab/weather/Tehsil/28Nov23_4Dec23/Avg Rainfall.png"
const tehsilWeather = [
  {
    src: humidity_28Nov23_4Dec23,
    width: 8,
    height: 7,
    tags: [{ value: "Humidity", title: "Humidity" }],
    date : "11/28/2023 to 12/4/2023"
  },
  {
    src: max_temp_28Nov23_4Dec23,
    width: 8,
    height: 7,
    tags: [{ value: "Max Temperature", title: "Max Temperature" }],
    date : "11/28/2023 to 12/4/2023"
  },
  {
    src: min_temp_28Nov23_4Dec23,
    width: 8,
    height: 7,
    tags: [{ value: "Min Temperature", title: "Min Temperature" }],
    date : "11/28/2023 to 12/4/2023"
  },
  {
    src: avg_temp_28Nov23_4Dec23,
    width: 8,
    height: 7,
    tags: [{ value: "Avg Temperature", title: "Avg Temperature" }],
    date : "11/28/2023 to 12/4/2023"
  },
  {
    src: rainfall_28Nov23_4Dec23,
    width: 8,
    height: 7,
    tags: [{ value: "Avg Rainfall", title: "Avg Rainfall" }],
    date : "11/28/2023 to 12/4/2023"
  }
  ,
  {
    src: humidity_21Nov23_27Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Humidity", title: "Humidity" }],
    date : "11/21/2023 to 11/27/2023"
  },
  {
    src: max_temp_21Nov23_27Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Max Temperature", title: "Max Temperature" }],
    date : "11/21/2023 to 11/27/2023"
  },
  {
    src: min_temp_21Nov23_27Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Min Temperature", title: "Min Temperature" }],
    date : "11/21/2023 to 11/27/2023"
  },
  {
    src: avg_temp_21Nov23_27Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Avg Temperature", title: "Avg Temperature" }],
    date : "11/21/2023 to 11/27/2023"
  },
  ,
  {
    src: humidity_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Humidity", title: "Humidity" }],
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: max_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Max Temperature", title: "Max Temperature" }],
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: min_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Min Temperature", title: "Min Temperature" }],
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: avg_temp_13Nov23_20Nov23,
    width: 8,
    height: 7,
    tags: [{ value: "Avg Temperature", title: "Avg Temperature" }],
    date : "11/13/2023 to 11/20/2023"
  },
  {
    src: humidity_3Oct23_12Oct23,
    width: 8,
    height: 7,
    tags: [{ value: "Humidity", title: "Humidity" }],
    date : "10/3/2023 to 10/12/2023"
  },
  {
    src: max_temp_3Oct23_12Oct23,
    width: 8,
    height: 7,
    tags: [{ value: "Max Temperature", title: "Max Temperature" }],
    date : "10/3/2023 to 10/12/2023"
  },
  {
    src: min_temp_3Oct23_12Oct23,
    width: 8,
    height: 7,
    tags: [{ value: "Min Temperature", title: "Min Temperature" }],
    date : "10/3/2023 to 10/12/2023"
  },
  {
    src: rainfall_3Oct23_12Oct23,
    width: 8,
    height: 7,
    tags: [{ value: "Rainfall", title: "Rainfall" }],
    date : "10/3/2023 to 10/12/2023"
  }
];
export default tehsilWeather;