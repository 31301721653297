import React, { useEffect } from "react";
import icon from "../utils/constants";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import { GeoSearchControl, OpenStreetMapProvider,EsriProvider } from "leaflet-geosearch";
const center = [30.33084, 71.24750];
const purpleOptions = { color: "white" };


export default function LeafletgeoSearch() {
  const map = useMap();
  useEffect(() => {
 
    const provider = new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({
      provider,
      position:'topright',
      showPopup: false,
      retainZoomLevel: true, 
      animateZoom: false,
      autoClose: false,
      searchLabel: 'Enter District name or Lat,Lon',
      keepResult: false,
      marker: {
        icon
      }
    });

    map.addControl(searchControl);

    return () => map.removeControl(searchControl);
  }, []);

  return null;
}
