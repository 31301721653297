export const landsat_sep = [
  { district: "Attock", min: -0.1734, max: 0.5011, mean: 0.1669 },
  { district: "Bahawalnagar", min: -0.1734, max: 0.5011, mean: 0.1669 },
  { district: "Bahawalpur", min: -0.1184, max: 0.4824, mean: 0.2215 },
  { district: "Bhakkar", min: -0.1451, max: 0.498, mean: 0.1499 },
  { district: "Chakwal", min: -0.0959, max: 0.4609, mean: 0.1334 },
  { district: "Chiniot", min: -0.1444, max: 0.4653, mean: 0.1757 },
  { district: "Dera Ghazi Khan", min: -0.081, max: 0.4854, mean: 0.2778 },
  { district: "Faisalabad", min: -0.1387, max: 0.4495, mean: 0.1588 },
  { district: "Gujranwala", min: -0.0722, max: 0.4864, mean: 0.2509 },
  { district: "Gujrat", min: -0.1014, max: 0.6432, mean: 0.2911 },
  { district: "Hafizabad", min: -0.0962, max: 0.5537, mean: 0.2352 },
  { district: "Jhang", min: -0.0892, max: 0.5577, mean: 0.3154 },
  { district: "Jhelum", min: -0.08, max: 0.5037, mean: 0.2342 },
  { district: "Kasur", min: -0.2497, max: 0.4596, mean: 0.1893 },
  { district: "Khanewal", min: -0.1105, max: 0.5439, mean: 0.2656 },
  { district: "Khushab", min: -0.0917, max: 0.4852, mean: 0.2639 },
  { district: "Lahore", min: -0.1582, max: 0.4518, mean: 0.167 },
  { district: "Layyah", min: -0.104, max: 0.552, mean: 0.2104 },
  { district: "Lodhran", min: -0.1082, max: 0.4663, mean: 0.1664 },
  { district: "Mandi Bahauddin", min: -0.1133, max: 0.4904, mean: 0.2849 },
  { district: "Mianwali", min: -0.1139, max: 0.6394, mean: 0.2808 },
  { district: "Multan", min: -0.1283, max: 0.456, mean: 0.1634 },
  { district: "Muzaffargarh", min: -0.1193, max: 0.4813, mean: 0.2354 },
  { district: "Nankana Sahib", min: -0.1183, max: 0.4704, mean: 0.2008 },
  { district: "Narowal", min: -0.0456, max: 0.4873, mean: 0.3094 },
  { district: "Okara", min: -0.0772, max: 0.5067, mean: 0.3046 },
  { district: "Pakpattan", min: -0.0852, max: 0.477, mean: 0.2729 },
  { district: "Rahim Yar Khan", min: -0.0936, max: 0.4653, mean: 0.2667 },
  { district: "Rajanpur", min: -0.1077, max: 0.4572, mean: 0.1995 },
  { district: "Rawalpindi", min: -0.1253, max: 0.4634, mean: 0.1361 },
  { district: "Sahiwal", min: -0.1544, max: 0.6115, mean: 0.2208 },
  { district: "Sargodha", min: -0.0614, max: 0.4897, mean: 0.2675 },
  { district: "Sheikhupura", min: -0.1146, max: 0.577, mean: 0.2411 },
  { district: "Sialkot", min: -0.0801, max: 0.5581, mean: 0.3008 },
  { district: "Toba Tek Singh", min: -0.11, max: 0.5354, mean: 0.2974 },
  { district: "Vehari", min: -0.0681, max: 0.478, mean: 0.2649 },
];

// Data for landsat_oct
export const landsat_oct = [
  { district: "Attock", min: -0.1575, max: 0.4922, mean: 0.1594 },
  { district: "Bahawalnagar", min: -0.1575, max: 0.4922, mean: 0.1594 },
  { district: "Bahawalpur", min: -0.1214, max: 0.4866, mean: 0.1945 },
  { district: "Bhakkar", min: -0.1797, max: 0.4829, mean: 0.1252 },
  { district: "Chakwal", min: -0.155, max: 0.46, mean: 0.1396 },
  { district: "Chiniot", min: -0.1459, max: 0.5285, mean: 0.1543 },
  { district: "Dera Ghazi Khan", min: -0.1039, max: 0.4806, mean: 0.239 },
  { district: "Faisalabad", min: -0.1727, max: 0.4714, mean: 0.1401 },
  { district: "Gujranwala", min: -0.1044, max: 0.4748, mean: 0.223 },
  { district: "Gujrat", min: -0.1235, max: 0.5113, mean: 0.2405 },
  { district: "Hafizabad", min: -0.1479, max: 0.4934, mean: 0.2139 },
  { district: "Jhang", min: -0.106, max: 0.5264, mean: 0.2736 },
  { district: "Jhelum", min: -0.1091, max: 0.4724, mean: 0.2098 },
  { district: "Kasur", min: -0.3003, max: 0.487, mean: 0.1662 },
  { district: "Khanewal", min: -0.136, max: 0.5034, mean: 0.2393 },
  { district: "Khushab", min: -0.0899, max: 0.4589, mean: 0.2297 },
  { district: "Lahore", min: -0.2545, max: 0.493, mean: 0.1699 },
  { district: "Layyah", min: -0.1181, max: 0.4828, mean: 0.1906 },
  { district: "Lodhran", min: -0.1752, max: 0.4819, mean: 0.155 },
  { district: "Mandi Bahauddin", min: -0.0972, max: 0.4752, mean: 0.2481 },
  { district: "Mianwali", min: -0.1452, max: 0.5866, mean: 0.2536 },
  { district: "Multan", min: -0.1471, max: 0.4548, mean: 0.1504 },
  { district: "Muzaffargarh", min: -0.1251, max: 0.4714, mean: 0.2136 },
  { district: "Nankana Sahib", min: -0.1974, max: 0.4927, mean: 0.1855 },
  { district: "Narowal", min: -0.0949, max: 0.5037, mean: 0.2736 },
  { district: "Okara", min: -0.094, max: 0.5187, mean: 0.2599 },
  { district: "Pakpattan", min: -0.0977, max: 0.4898, mean: 0.2389 },
  { district: "Rahim Yar Khan", min: -0.0941, max: 0.4814, mean: 0.2398 },
  { district: "Rajanpur", min: -0.205, max: 0.6961, mean: 0.176 },
  { district: "Rawalpindi", min: -0.2194, max: 0.4955, mean: 0.1224 },
  { district: "Sahiwal", min: -0.2302, max: 0.5989, mean: 0.1979 },
  { district: "Sargodha", min: -0.0751, max: 0.4607, mean: 0.2245 },
  { district: "Sheikhupura", min: -0.1416, max: 0.5518, mean: 0.2172 },
  { district: "Sialkot", min: -0.1023, max: 0.6199, mean: 0.2583 },
  { district: "Toba Tek Singh", min: -0.1448, max: 0.5239, mean: 0.2657 },
  { district: "Vehari", min: -0.0733, max: 0.4653, mean: 0.2275 },
];

// ------------  landsat_nov ------------

export const landsat_nov = [
  { DISTRICT: "Attock", MIN: -0.1802, MAX: 0.5056, MEAN: 0.1429 },
  { DISTRICT: "Bahawalnagar", MIN: -0.1802, MAX: 0.5056, MEAN: 0.1429 },
  { DISTRICT: "Bahawalpur", MIN: -0.1426, MAX: 0.5161, MEAN: 0.1195 },
  { DISTRICT: "Bhakkar", MIN: -0.1278, MAX: 0.4837, MEAN: 0.099 },
  { DISTRICT: "Chakwal", MIN: -0.1248, MAX: 0.451, MEAN: 0.1141 },
  { DISTRICT: "Chiniot", MIN: -0.1917, MAX: 0.5523, MEAN: 0.1463 },
  { DISTRICT: "Dera Ghazi Khan", MIN: -0.1103, MAX: 0.4923, MEAN: 0.1967 },
  { DISTRICT: "Faisalabad", MIN: -0.177, MAX: 0.4443, MEAN: 0.1033 },
  { DISTRICT: "Gujranwala", MIN: -0.0999, MAX: 0.4976, MEAN: 0.1841 },
  { DISTRICT: "Gujrat", MIN: -0.1538, MAX: 0.5024, MEAN: 0.1189 },
  { DISTRICT: "Hafizabad", MIN: -0.1678, MAX: 0.7106, MEAN: 0.1655 },
  { DISTRICT: "Jhang", MIN: -0.1428, MAX: 0.4872, MEAN: 0.1203 },
  { DISTRICT: "Jhelum", MIN: -0.1472, MAX: 0.4787, MEAN: 0.1522 },
  { DISTRICT: "Kasur", MIN: -0.3816, MAX: 0.5146, MEAN: 0.1651 },
  { DISTRICT: "Khanewal", MIN: -0.1308, MAX: 0.5031, MEAN: 0.1838 },
  { DISTRICT: "Khushab", MIN: -0.1042, MAX: 0.4661, MEAN: 0.1487 },
  { DISTRICT: "Lahore", MIN: -0.1949, MAX: 0.5158, MEAN: 0.1401 },
  { DISTRICT: "Layyah", MIN: -0.1306, MAX: 0.6884, MEAN: 0.1436 },
  { DISTRICT: "Lodhran", MIN: -0.1087, MAX: 0.456, MEAN: 0.1031 },
  { DISTRICT: "Mandi Bahauddin", MIN: -0.0938, MAX: 0.4777, MEAN: 0.1562 },
  { DISTRICT: "Mianwali", MIN: -0.1588, MAX: 0.7254, MEAN: 0.1863 },
  { DISTRICT: "Multan", MIN: -0.1079, MAX: 0.4238, MEAN: 0.1174 },
  { DISTRICT: "Muzaffargarh", MIN: -0.0847, MAX: 0.4495, MEAN: 0.1308 },
  { DISTRICT: "Nankana Sahib", MIN: -0.1065, MAX: 0.4288, MEAN: 0.1152 },
  { DISTRICT: "Narowal", MIN: -0.1227, MAX: 0.492, MEAN: 0.1301 },
  { DISTRICT: "Okara", MIN: -0.1421, MAX: 0.507, MEAN: 0.1358 },
  { DISTRICT: "Pakpattan", MIN: -0.1372, MAX: 0.5112, MEAN: 0.1831 },
  { DISTRICT: "Rahim Yar Khan", MIN: -0.1021, MAX: 0.4988, MEAN: 0.1679 },
  { DISTRICT: "Rajanpur", MIN: -0.1418, MAX: 0.4474, MEAN: 0.1345 },
  { DISTRICT: "Rawalpindi", MIN: -0.1038, MAX: 0.4528, MEAN: 0.1002 },
  { DISTRICT: "Sahiwal", MIN: -0.2154, MAX: 0.5927, MEAN: 0.1735 },
  { DISTRICT: "Sargodha", MIN: -0.1252, MAX: 0.4981, MEAN: 0.1734 },
  { DISTRICT: "Sheikhupura", MIN: -0.1668, MAX: 0.6879, MEAN: 0.1898 },
  { DISTRICT: "Sialkot", MIN: -0.149, MAX: 0.487, MEAN: 0.1248 },
  { DISTRICT: "Toba Tek Singh", MIN: -0.1203, MAX: 0.5076, MEAN: 0.1415 },
  { DISTRICT: "Vehari", MIN: -0.1047, MAX: 0.4754, MEAN: 0.1809 },
];

// ------------  landsat_dec ------------

export const landsat_dec = [
  { DISTRICT: "Attock", MIN: -0.1746, MAX: 0.5135, MEAN: 0.1614 },
  { DISTRICT: "Bahawalnagar", MIN: -0.1746, MAX: 0.5135, MEAN: 0.1614 },
  { DISTRICT: "Bahawalpur", MIN: -0.1823, MAX: 0.5127, MEAN: 0.1591 },
  { DISTRICT: "Bhakkar", MIN: -0.1809, MAX: 0.5145, MEAN: 0.1179 },
  { DISTRICT: "Chakwal", MIN: -0.1362, MAX: 0.5116, MEAN: 0.1218 },
  { DISTRICT: "Chiniot", MIN: -0.1952, MAX: 0.5311, MEAN: 0.1637 },
  { DISTRICT: "Dera Ghazi Khan", MIN: -0.1505, MAX: 0.5222, MEAN: 0.1826 },
  { DISTRICT: "Faisalabad", MIN: -0.2032, MAX: 0.5032, MEAN: 0.1259 },
  { DISTRICT: "Gujranwala", MIN: -0.1849, MAX: 0.5108, MEAN: 0.1753 },
  { DISTRICT: "Gujrat", MIN: -0.1294, MAX: 0.4998, MEAN: 0.1533 },
  { DISTRICT: "Hafizabad", MIN: -0.1142, MAX: 0.5153, MEAN: 0.177 },
  { DISTRICT: "Jhang", MIN: -0.1406, MAX: 0.5017, MEAN: 0.1393 },
  { DISTRICT: "Jhelum", MIN: -0.1524, MAX: 0.5293, MEAN: 0.1468 },
  { DISTRICT: "Kasur", MIN: -0.4068, MAX: 0.5136, MEAN: 0.171 },
  { DISTRICT: "Khanewal", MIN: -0.1685, MAX: 0.5081, MEAN: 0.2042 },
  { DISTRICT: "Khushab", MIN: -0.1553, MAX: 0.5053, MEAN: 0.1834 },
  { DISTRICT: "Lahore", MIN: -0.2399, MAX: 0.519, MEAN: 0.1434 },
  { DISTRICT: "Layyah", MIN: -0.1535, MAX: 0.4974, MEAN: 0.1552 },
  { DISTRICT: "Lodhran", MIN: -0.1542, MAX: 0.5055, MEAN: 0.1257 },
  { DISTRICT: "Mandi Bahauddin", MIN: -0.1364, MAX: 0.502, MEAN: 0.1914 },
  { DISTRICT: "Mianwali", MIN: -0.1385, MAX: 0.5203, MEAN: 0.1657 },
  { DISTRICT: "Multan", MIN: -0.1763, MAX: 0.5163, MEAN: 0.1387 },
  { DISTRICT: "Muzaffargarh", MIN: -0.1217, MAX: 0.5059, MEAN: 0.1663 },
  { DISTRICT: "Nankana Sahib", MIN: -0.164, MAX: 0.5043, MEAN: 0.1542 },
  { DISTRICT: "Narowal", MIN: -0.1377, MAX: 0.5042, MEAN: 0.1613 },
  { DISTRICT: "Okara", MIN: -0.125, MAX: 0.5124, MEAN: 0.1405 },
  { DISTRICT: "Pakpattan", MIN: -0.1244, MAX: 0.5129, MEAN: 0.2308 },
  { DISTRICT: "Rahim Yar Khan", MIN: -0.1159, MAX: 0.5192, MEAN: 0.2082 },
  { DISTRICT: "Rajanpur", MIN: -0.1528, MAX: 0.5417, MEAN: 0.1586 },
  { DISTRICT: "Rawalpindi", MIN: -0.1493, MAX: 0.4981, MEAN: 0.1306 },
  { DISTRICT: "Sahiwal", MIN: -0.1651, MAX: 0.7983, MEAN: 0.1904 },
  { DISTRICT: "Sargodha", MIN: -0.1304, MAX: 0.5108, MEAN: 0.199 },
  { DISTRICT: "Sheikhupura", MIN: -0.1551, MAX: 0.9574, MEAN: 0.1888 },
  { DISTRICT: "Sialkot", MIN: -0.1673, MAX: 0.5655, MEAN: 0.1478 },
  { DISTRICT: "Toba Tek Singh", MIN: -0.1321, MAX: 0.5274, MEAN: 0.1456 },
  { DISTRICT: "Vehari", MIN: -0.1242, MAX: 0.5251, MEAN: 0.1785 },
];
