import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Soil from "./Soil";
import Weather from "./Weather";

export default function Province({
  province,
  choice,
  dateFilter,
  setDateFilter,
  districtFilter,
  setDistrictFilter,
  elementFilter,
  setElementFilter
}) {
  return (
    <>
      {choice.id == 1 ? (
        <Weather
          province={province}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          districtFilter={districtFilter}
          setDistrictFilter={setDistrictFilter}
        />
      ) : (
          <Soil
            province={province}
            districtFilter={districtFilter}
            setDistrictFilter={setDistrictFilter}
            elementFilter={elementFilter}
            setElementFilter={setElementFilter}
          />
      )}
    </>
  );
}

{
  /* <Grid item xs={10}>
          {province.id == 1 ? (
            <Gallery images={PunjabImages} enableImageSelection={false} rowHeight={370}/>
          ) : province.id == 2 ? (
            <Gallery images={SindhImages} enableImageSelection={false} />
          ) : province.id == 3 ? (
            <Gallery images={BalochistanImages} enableImageSelection={false} />
          ) : (
            <Gallery images={KPKImages} enableImageSelection={false} />
          )}
        </Grid> */
}
