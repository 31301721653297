import Header from "../utils/Header";
import Footer from "../utils/Footer";
import FooterAgri from "../utils/FooterAgri";
// import Main from "./Main";
import ImageWithOverlayPunjabAgri from "../utils/ImageWithOverlayPunjabAgri";
import AboutUs from "../../public/images/AboutUs.jpg";
import PunjabAri from "./PunjabAgri";
import logoLeft from "../../public/images/limsLogoGroup.png";
import logoRight from "../../public/images/punjabAgriGroup.png";
export default function CompleteAbout() {
  return (
    <>
      <Header />
      <ImageWithOverlayPunjabAgri
        image={AboutUs}
        height="300px"
        title="Remote Sensing of Punjab"
        titlePartOne="Remote Sensing of"
        titlePartTwo="Punjab"
        logoLeft={logoLeft}
        logoRight={logoRight}
      />
      <PunjabAri />
      <FooterAgri />
    </>
  );
}
