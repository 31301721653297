import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
export default function CustomSelect({
  dateFilter,
  setDateFilter,
  districtFilter,
  setDistrictFilter,
  elementFilter,
  setElementFilter,
}) {
  const [open, setOpen] = React.useState(false);
  const handleDateFilter = (event) => {
    setDateFilter(event.target.value);
  };
  const handleDistrictFilter = (event) => {
    setDistrictFilter(event.target.value);
  };
  const handleElementFilter = (event) => {
    setElementFilter(event.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  return (
    <div>
      <Button onClick={handleClickOpen} style={{ color: "black" }}>
        <FilterAltOutlinedIcon fontSize={"large"} />
        Filter
      </Button>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>Select Filter</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
            {dateFilter == null ? (
              <>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <InputLabel id="demo-dialog-select-label">
                    District
                  </InputLabel>
                  <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    value={districtFilter}
                    onChange={handleDistrictFilter}
                    input={<OutlinedInput label="District" />}
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    <MenuItem value={"BHV"}>Bahawalpur</MenuItem>
                    <MenuItem value={"DGK"}>Dera Gazi Khan</MenuItem>
                    <MenuItem value={"FSD"}>Faislabad</MenuItem>
                    <MenuItem value={"GRW"}>Gujranwala</MenuItem>
                    <MenuItem value={"LHR"}>Lahore</MenuItem>
                    <MenuItem value={"MUL"}>Multan</MenuItem>
                    <MenuItem value={"RWP"}>Rawalpindi</MenuItem>
                    <MenuItem value={"SWH"}>Sahiwal</MenuItem>
                    <MenuItem value={"SGD"}>Sarghoda</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <InputLabel id="demo-dialog-select-label">Element</InputLabel>
                  <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    value={elementFilter}
                    onChange={handleElementFilter}
                    input={<OutlinedInput label="District" />}
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    <MenuItem value={"B"}>Boron</MenuItem>
                    <MenuItem value={"EC"}>Electronic Conductivity</MenuItem>
                    <MenuItem value={"Fe"}>Iron</MenuItem>
                    <MenuItem value={"Mn"}>Maganese</MenuItem>
                    <MenuItem value={"OM"}>Organic Matter</MenuItem>
                    <MenuItem value={"pH"}>PH</MenuItem>
                    <MenuItem value={"P"}>Phosporus</MenuItem>
                    <MenuItem value={"K"}>Potassium</MenuItem>
                    <MenuItem value={"Zn"}>Zinc</MenuItem>
                  </Select>
                </FormControl>
              </>
            ) : (
              <>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <InputLabel id="demo-dialog-select-label">Date</InputLabel>
                  <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    value={dateFilter}
                    onChange={handleDateFilter}
                    input={<OutlinedInput label="Date" />}
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    <MenuItem value={"10/3/2023 to 10/12/2023"}>
                      10/3/2023 to 10/12/2023
                    </MenuItem>
                    <MenuItem value={"11/13/2023 to 11/20/2023"}>
                      11/13/2023 to 11/20/2023
                    </MenuItem>
                    <MenuItem value={'11/21/2023 to 11/27/2023'}>11/21/2023 to 11/27/2023</MenuItem>
                    <MenuItem value={'11/28/2023 to 12/4/2023'}>11/28/2023 to 12/4/2023</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <InputLabel id="demo-dialog-select-label">
                    District
                  </InputLabel>
                  <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    value={districtFilter}
                    onChange={handleDistrictFilter}
                    input={<OutlinedInput label="District" />}
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    <MenuItem value={"BHV"}>Bahawalpur</MenuItem>
                    <MenuItem value={"DGK"}>Dera Gazi Khan</MenuItem>
                    <MenuItem value={"FSD"}>Faislabad</MenuItem>
                    <MenuItem value={"GRW"}>Gujranwala</MenuItem>
                    <MenuItem value={"LHR"}>Lahore</MenuItem>
                    <MenuItem value={"MUL"}>Multan</MenuItem>
                    <MenuItem value={"RWP"}>Rawalpindi</MenuItem>
                    <MenuItem value={"SWH"}>Sahiwal</MenuItem>
                    <MenuItem value={"SGD"}>Sarghoda</MenuItem>
                  </Select>
                </FormControl>
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}