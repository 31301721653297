import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import * as XLSX from "xlsx";
import Box from "@mui/material/Box";
import Header from "../utils/Header";
import FooterAgri from "../utils/FooterAgri";
import ImageWithOverlayPunjabAgri from "../utils/ImageWithOverlayPunjabAgri";
import AboutUs from "../../public/images/AboutUs.jpg";
import logoLeft from "../../public/images/limsLogoGroup.png";
import logoRight from "../../public/images/punjabAgriGroup.png";

import {
  landsat_sep01,
  landsat_sep11,
  landsat_sep21,
  landsat_oct01,
  landsat_oct11,
  landsat_oct21,
  landsat_nov01,
  landsat_nov11,
  landsat_nov21,
  landsat_dec01,
  landsat_dec11,
  landsat_dec21,
} from "./Sentinel03Data";

import sentinel01SepMap from "../../../src/public/punjabAgri/sentinel_01_Sep.png";
import sentinel11SepMap from "../../../src/public/punjabAgri/sentinel_11_Sep.png";
import sentinel21SepMap from "../../../src/public/punjabAgri/sentinel_21_Sep.png";
import sentinel01SepTable from "../../../src/public/punjabAgri/sentinel_table_01_Sep.png";
import sentinel11SepTable from "../../../src/public/punjabAgri/sentinel_table_11_Sep.png";
import sentinel21SepTable from "../../../src/public/punjabAgri/sentinel_table_21_Sep.png";

import sentinel01OctMap from "../../../src/public/punjabAgri/sentinel_map_01_Oct.png";
import sentinel11OctMap from "../../../src/public/punjabAgri/sentinel_map_11_Oct.png";
import sentinel21OctMap from "../../../src/public/punjabAgri/sentinel_map_21_Oct.png";
import sentinel01OctTable from "../../../src/public/punjabAgri/sentinel_table_01_Oct.png";
import sentinel11OctTable from "../../../src/public/punjabAgri/sentinel_table_11_Oct.png";
import sentinel21OctTable from "../../../src/public/punjabAgri/sentinel_table_21_Oct.png";

import sentinel01NovMap from "../../../src/public/punjabAgri/sentinel_map_01_Nov.png";
import sentinel11NovMap from "../../../src/public/punjabAgri/sentinel_map_11_Nov.png";
import sentinel21NovMap from "../../../src/public/punjabAgri/sentinel_map_21_Nov.png";
import sentinel01NovTable from "../../../src/public/punjabAgri/sentinel_table_01_Nov.png";
import sentinel11NovTable from "../../../src/public/punjabAgri/sentinel_table_11_Nov.png";
import sentinel21NovTable from "../../../src/public/punjabAgri/sentinel_table_21_Nov.png";

import sentinel01DecMap from "../../../src/public/punjabAgri/sentinel_map_01_Dec.png";
import sentinel11DecMap from "../../../src/public/punjabAgri/sentinel_map_11_Dec.png";
import sentinel21DecMap from "../../../src/public/punjabAgri/sentinel_map_21_Dec.png";
import sentinel01DecTable from "../../../src/public/punjabAgri/sentinel_table_01_Dec.png";
import sentinel11DecTable from "../../../src/public/punjabAgri/sentinel_table_11_Dec.png";
import sentinel21DecTable from "../../../src/public/punjabAgri/sentinel_table_21_Dec.png";

import excelfile1 from "../../../src/public/excelFiles/lims_sentinel3_sep01.xlsx";
import excelfile2 from "../../../src/public/excelFiles/lims_sentinel3_sep11.xlsx";
import excelfile3 from "../../../src/public/excelFiles/lims_sentinel3_sep21.xlsx";
import excelfile4 from "../../../src/public/excelFiles/lims_sentinel3_oct01.xlsx";
import excelfile5 from "../../../src/public/excelFiles/lims_sentinel3_oct11.xlsx";
import excelfile6 from "../../../src/public/excelFiles/lims_sentinel3_oct21.xlsx";

import excelfile7 from "../../../src/public/excelFiles/lims_sentinel3_nov01.xlsx";
import excelfile8 from "../../../src/public/excelFiles/lims_sentinel3_nov11.xlsx";
import excelfile9 from "../../../src/public/excelFiles/lims_sentinel3_nov21.xlsx";
import excelfile10 from "../../../src/public/excelFiles/lims_sentinel3_dec01.xlsx";
import excelfile11 from "../../../src/public/excelFiles/lims_sentinel3_dec11.xlsx";
import excelfile12 from "../../../src/public/excelFiles/lims_sentinel3_dec21.xlsx";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function Sentinel3(props) {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [width, setWidth] = useState(window.innerWidth);

  const [dataExcel1, setDataExcel1] = useState([]);
  const [dataExcel2, setDataExcel2] = useState([]);
  const [dataExcel3, setDataExcel3] = useState([]);
  const [dataExcel4, setDataExcel4] = useState([]);
  const [dataExcel5, setDataExcel5] = useState([]);
  const [dataExcel6, setDataExcel6] = useState([]);

  const [dataExcel7, setDataExcel7] = useState([]);
  const [dataExcel8, setDataExcel8] = useState([]);
  const [dataExcel9, setDataExcel9] = useState([]);
  const [dataExcel10, setDataExcel10] = useState([]);
  const [dataExcel11, setDataExcel11] = useState([]);
  const [dataExcel12, setDataExcel12] = useState([]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);

      const readExcelFile1 = async () => {
        try {
          const response = await fetch(excelfile1);
          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          setDataExcel1(jsonData);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      };

      const readExcelFile2 = async () => {
        try {
          const response = await fetch(excelfile2);
          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          setDataExcel2(jsonData);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      };

      const readExcelFile3 = async () => {
        try {
          const response = await fetch(excelfile3);
          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          setDataExcel3(jsonData);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      };

      const readExcelFile4 = async () => {
        try {
          const response = await fetch(excelfile4);
          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          setDataExcel4(jsonData);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      };

      const readExcelFile5 = async () => {
        try {
          const response = await fetch(excelfile5);
          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          setDataExcel5(jsonData);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      };

      const readExcelFile6 = async () => {
        try {
          const response = await fetch(excelfile6);
          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          setDataExcel6(jsonData);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      };

      const readExcelFile7 = async () => {
        try {
          const response = await fetch(excelfile7);
          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          setDataExcel7(jsonData);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      };

      const readExcelFile8 = async () => {
        try {
          const response = await fetch(excelfile8);
          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          setDataExcel8(jsonData);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      };

      const readExcelFile9 = async () => {
        try {
          const response = await fetch(excelfile9);
          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          setDataExcel9(jsonData);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      };

      const readExcelFile10 = async () => {
        try {
          const response = await fetch(excelfile10);
          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          setDataExcel10(jsonData);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      };

      const readExcelFile11 = async () => {
        try {
          const response = await fetch(excelfile11);
          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          setDataExcel11(jsonData);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      };

      const readExcelFile12 = async () => {
        try {
          const response = await fetch(excelfile12);
          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          setDataExcel12(jsonData);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      };

      readExcelFile1();
      readExcelFile2();
      readExcelFile3();
      readExcelFile4();
      readExcelFile5();
      readExcelFile6();

      readExcelFile7();
      readExcelFile8();
      readExcelFile9();
      readExcelFile10();
      readExcelFile11();
      readExcelFile12();
    };
  }, []);

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [selectedMonth, setSelectedMonth] = useState("September");

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  // New Table Style
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const downloadCSV1 = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      landsat_sep01.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sentinel3_sep01.csv");
    document.body.appendChild(link);
    link.click();
  };

  const downloadCSV2 = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      landsat_sep11.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sentinel3_sep11.csv");
    document.body.appendChild(link);
    link.click();
  };

  const downloadCSV3 = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      landsat_sep21.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sentinel3_sep21.csv");
    document.body.appendChild(link);
    link.click();
  };

  const downloadCSV4 = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      landsat_oct01.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sentinel3_oct01.csv");
    document.body.appendChild(link);
    link.click();
  };

  const downloadCSV5 = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      landsat_oct11.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sentinel3_oct11.csv");
    document.body.appendChild(link);
    link.click();
  };

  const downloadCSV6 = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      landsat_oct21.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sentinel3_oct21.csv");
    document.body.appendChild(link);
    link.click();
  };

  const downloadCSV7 = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      landsat_nov01.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sentinel3_nov01.csv");
    document.body.appendChild(link);
    link.click();
  };

  const downloadCSV8 = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      landsat_nov11.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sentinel3_nov11.csv");
    document.body.appendChild(link);
    link.click();
  };

  const downloadCSV9 = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      landsat_nov21.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sentinel3_nov21.csv");
    document.body.appendChild(link);
    link.click();
  };

  const downloadCSV10 = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      landsat_dec01.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sentinel3_dec01.csv");
    document.body.appendChild(link);
    link.click();
  };

  const downloadCSV11 = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      landsat_dec11.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sentinel3_dec11.csv");
    document.body.appendChild(link);
    link.click();
  };

  const downloadCSV12 = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      landsat_dec21.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sentinel3_dec21.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <Header />
      <ImageWithOverlayPunjabAgri
        image={AboutUs}
        height="300px"
        title="Remote Sensing of Punjab"
        titlePartOne="Remote Sensing of"
        titlePartTwo="Punjab"
        logoLeft={logoLeft}
        logoRight={logoRight}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <img
              src={selectedImage}
              alt="Full Size"
              style={{ maxWidth: "100%" }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* ---------------------------------------------- For Sep ----------------------------------------------  */}
      {/* For Sep part 1 */}

      <div className="my-4 container">
        <select
          className={
            width < 768 ? "form-select w-50 mx-3" : "form-select w-25 mx-3"
          }
          value={selectedMonth}
          onChange={handleMonthChange}
        >
          <option value="September">September</option>
          <option value="October">October</option>
          <option value="November">November</option>
          <option value="December">December</option>
        </select>

        {selectedMonth === "September" ? (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "25px",
              }}
            >
              <h2 style={{ marginTop: 0 }} className="mx-2">
                Sentinel 3 NDVI for 01 Sep 2023
              </h2>
              <button
                variant="contained"
                color="primary"
                className="btn btn-primary"
                sx={{
                  backgroundColor: "#007bff",
                  "&:hover": { backgroundColor: "#0056b3" },
                }}
                onClick={downloadCSV1}
              >
                Download CSV
              </button>
            </Box>{" "}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div onClick={() => handleImageClick(sentinel01SepMap)}>
                  <img
                    src={sentinel01SepMap}
                    alt="Image 1"
                    style={{
                      maxWidth: "100%",
                      height: "670px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} className="text-center">
                <div
                  className=" mt-4"
                  // onClick={() => handleImageClick(sentinel01SepTable)}
                >
                  <TableContainer component={Paper} sx={{ height: 650 }}>
                    {landsat_sep01.length > 0 ? (
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          height: 650,
                          overflowX: isLargeScreen ? "hidden" : "auto",
                        }} // aria-label="customized table"
                      >
                        <TableHead stickyHeader>
                          <TableRow>
                            {landsat_sep01.length > 0 &&
                              Object.keys(landsat_sep01[0]).map(
                                (key, index) => (
                                  <StyledTableCell key={index}>
                                    {key}
                                  </StyledTableCell>
                                )
                              )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {landsat_sep01.length > 0 ? (
                            landsat_sep01.map((row, index) => (
                              <StyledTableRow key={index}>
                                {Object.values(row).map((value, index) => (
                                  <StyledTableCell key={index}>
                                    {value}
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            ))
                          ) : (
                            <StyledTableRow>
                              <StyledTableCell
                                colSpan={Object.keys(landsat_sep01[0]).length}
                              >
                                <Typography>Loading...</Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
            {/* For Sep part 2 */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "25px",
              }}
              className="mt-5"
            >
              <h2 style={{ marginTop: 0 }} className="mx-2">
                Sentinel 3 NDVI for 11 Sep 2023
              </h2>
              <button
                variant="contained"
                color="primary"
                className="btn btn-primary"
                sx={{
                  backgroundColor: "#007bff",
                  "&:hover": { backgroundColor: "#0056b3" },
                }}
                onClick={downloadCSV2}
              >
                Download CSV
              </button>
            </Box>{" "}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div onClick={() => handleImageClick(sentinel11SepMap)}>
                  <img
                    src={sentinel11SepMap}
                    alt="Image 1"
                    style={{
                      maxWidth: "100%",
                      height: "670px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} className="text-center">
                <div
                  className="mt-4 "
                  // onClick={() => handleImageClick(sentinel11SepTable)}
                >
                  <TableContainer component={Paper} sx={{ height: 650 }}>
                    {landsat_sep11.length > 0 ? (
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          height: 650,
                          overflowX: isLargeScreen ? "hidden" : "auto",
                        }} // aria-label="customized table"
                      >
                        <TableHead stickyHeader>
                          <TableRow>
                            {landsat_sep11.length > 0 &&
                              Object.keys(landsat_sep11[0]).map(
                                (key, index) => (
                                  <StyledTableCell key={index}>
                                    {key}
                                  </StyledTableCell>
                                )
                              )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {landsat_sep11.length > 0 ? (
                            landsat_sep11.map((row, index) => (
                              <StyledTableRow key={index}>
                                {Object.values(row).map((value, index) => (
                                  <StyledTableCell key={index}>
                                    {value}
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            ))
                          ) : (
                            <StyledTableRow>
                              <StyledTableCell
                                colSpan={Object.keys(landsat_sep11[0]).length}
                              >
                                <Typography>Loading...</Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
            {/* For Sep part 3 */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "25px",
              }}
              className="mt-5"
            >
              <h2 style={{ marginTop: 0 }} className="mx-2">
                Sentinel 3 NDVI for 21 Sep 2023
              </h2>
              <button
                variant="contained"
                color="primary"
                className="btn btn-primary"
                sx={{
                  backgroundColor: "#007bff",
                  "&:hover": { backgroundColor: "#0056b3" },
                }}
                onClick={downloadCSV3}
              >
                Download CSV
              </button>
            </Box>{" "}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div onClick={() => handleImageClick(sentinel21SepMap)}>
                  <img
                    src={sentinel21SepMap}
                    alt="Image 1"
                    style={{
                      height: "670px",
                      maxWidth: "100%",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} className="text-center">
                <div
                  className="mt-4"
                  // onClick={() => handleImageClick(sentinel21SepTable)}
                >
                  <TableContainer component={Paper} sx={{ height: 650 }}>
                    {landsat_sep21.length > 0 ? (
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          height: 650,
                          overflowX: isLargeScreen ? "hidden" : "auto",
                        }} // aria-label="customized table"
                      >
                        <TableHead stickyHeader>
                          <TableRow>
                            {landsat_sep21.length > 0 &&
                              Object.keys(landsat_sep21[0]).map(
                                (key, index) => (
                                  <StyledTableCell key={index}>
                                    {key}
                                  </StyledTableCell>
                                )
                              )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {landsat_sep21.length > 0 ? (
                            landsat_sep21.map((row, index) => (
                              <StyledTableRow key={index}>
                                {Object.values(row).map((value, index) => (
                                  <StyledTableCell key={index}>
                                    {value}
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            ))
                          ) : (
                            <StyledTableRow>
                              <StyledTableCell
                                colSpan={Object.keys(landsat_sep21[0]).length}
                              >
                                <Typography>Loading...</Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </>
        ) : selectedMonth === "October" ? (
          // {/* ----------------------------------------------   2nd Drop down (For Oct) ----------------------------------------------  */}
          // {/* For Oct part 1 */}
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "25px",
              }}
            >
              <h2 style={{ marginTop: 0 }} className="mx-2">
                Sentinel 3 NDVI for 01 Oct 2023
              </h2>
              <button
                variant="contained"
                color="primary"
                className="btn btn-primary"
                sx={{
                  backgroundColor: "#007bff",
                  "&:hover": { backgroundColor: "#0056b3" },
                }}
                onClick={downloadCSV4}
              >
                Download CSV
              </button>
            </Box>{" "}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div onClick={() => handleImageClick(sentinel01OctMap)}>
                  <img
                    src={sentinel01OctMap}
                    alt="Image 1"
                    style={{
                      maxWidth: "100%",
                      height: "670px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={6} className="text-center">
                <div
                  className="mt-4"
                  // onClick={() => handleImageClick(sentinel01OctTable)}
                >
                  <TableContainer component={Paper} sx={{ height: 650 }}>
                    {landsat_oct01.length > 0 ? (
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          height: 650,
                          overflowX: isLargeScreen ? "hidden" : "auto",
                        }} // aria-label="customized table"
                      >
                        <TableHead stickyHeader>
                          <TableRow>
                            {landsat_oct01.length > 0 &&
                              Object.keys(landsat_oct01[0]).map(
                                (key, index) => (
                                  <StyledTableCell key={index}>
                                    {key}
                                  </StyledTableCell>
                                )
                              )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {landsat_oct01.length > 0 ? (
                            landsat_oct01.map((row, index) => (
                              <StyledTableRow key={index}>
                                {Object.values(row).map((value, index) => (
                                  <StyledTableCell key={index}>
                                    {value}
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            ))
                          ) : (
                            <StyledTableRow>
                              <StyledTableCell
                                colSpan={Object.keys(landsat_oct01[0]).length}
                              >
                                <Typography>Loading...</Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
            {/* For Oct part 2 */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "25px",
              }}
              className="mt-5"
            >
              <h2 style={{ marginTop: 0 }} className="mx-2">
                Sentinel 3 NDVI for 11 Oct 2023
              </h2>
              <button
                variant="contained"
                color="primary"
                className="btn btn-primary"
                sx={{
                  backgroundColor: "#007bff",
                  "&:hover": { backgroundColor: "#0056b3" },
                }}
                onClick={downloadCSV5}
              >
                Download CSV
              </button>
            </Box>{" "}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div onClick={() => handleImageClick(sentinel11OctMap)}>
                  <img
                    src={sentinel11OctMap}
                    alt="Image 1"
                    style={{
                      maxWidth: "100%",
                      height: "670px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} className="text-center">
                <div
                  className="mt-4 "
                  // onClick={() => handleImageClick(sentinel11OctTable)}
                >
                  <TableContainer component={Paper} sx={{ height: 650 }}>
                    {landsat_oct11.length > 0 ? (
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          height: 650,
                          overflowX: isLargeScreen ? "hidden" : "auto",
                        }} // aria-label="customized table"
                      >
                        <TableHead stickyHeader>
                          <TableRow>
                            {landsat_oct11.length > 0 &&
                              Object.keys(landsat_oct11[0]).map(
                                (key, index) => (
                                  <StyledTableCell key={index}>
                                    {key}
                                  </StyledTableCell>
                                )
                              )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {landsat_oct11.length > 0 ? (
                            landsat_oct11.map((row, index) => (
                              <StyledTableRow key={index}>
                                {Object.values(row).map((value, index) => (
                                  <StyledTableCell key={index}>
                                    {value}
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            ))
                          ) : (
                            <StyledTableRow>
                              <StyledTableCell
                                colSpan={Object.keys(landsat_oct11[0]).length}
                              >
                                <Typography>Loading...</Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
            {/* For Oct part 3 */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "25px",
              }}
              className="mt-5"
            >
              <h2 style={{ marginTop: 0 }} className="mx-2">
                Sentinel 3 NDVI for 21 Oct 2023
              </h2>
              <button
                variant="contained"
                color="primary"
                className="btn btn-primary"
                sx={{
                  backgroundColor: "#007bff",
                  "&:hover": { backgroundColor: "#0056b3" },
                }}
                onClick={downloadCSV6}
              >
                Download CSV
              </button>
            </Box>{" "}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div onClick={() => handleImageClick(sentinel21OctMap)}>
                  <img
                    src={sentinel21OctMap}
                    alt="Image 1"
                    style={{
                      height: "670px",
                      maxWidth: "100%",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} className="text-center">
                <div
                  className="mt-4"
                  // onClick={() => handleImageClick(sentinel21OctTable)}
                >
                  <TableContainer component={Paper} sx={{ height: 650 }}>
                    {landsat_oct21.length > 0 ? (
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          height: 650,
                          overflowX: isLargeScreen ? "hidden" : "auto",
                        }} // aria-label="customized table"
                      >
                        <TableHead stickyHeader>
                          <TableRow>
                            {landsat_oct21.length > 0 &&
                              Object.keys(landsat_oct21[0]).map(
                                (key, index) => (
                                  <StyledTableCell key={index}>
                                    {key}
                                  </StyledTableCell>
                                )
                              )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {landsat_oct21.length > 0 ? (
                            landsat_oct21.map((row, index) => (
                              <StyledTableRow key={index}>
                                {Object.values(row).map((value, index) => (
                                  <StyledTableCell key={index}>
                                    {value}
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            ))
                          ) : (
                            <StyledTableRow>
                              <StyledTableCell
                                colSpan={Object.keys(landsat_oct21[0]).length}
                              >
                                <Typography>Loading...</Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </>
        ) : selectedMonth === "November" ? (
          // {/*------------------------------------------------------  For November ------------------------------------------------------   */}
          //   {/* For Nov part 1 */}

          <div>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "25px",
              }}
              className="mt-5"
            >
              <h2 style={{ marginTop: 0 }} className="mx-2">
                Sentinel 3 NDVI for 01 Nov 2023
              </h2>
              <button
                variant="contained"
                color="primary"
                className="btn btn-primary"
                sx={{
                  backgroundColor: "#007bff",
                  "&:hover": { backgroundColor: "#0056b3" },
                }}
                onClick={downloadCSV7}
              >
                Download CSV
              </button>
            </Box>{" "}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div onClick={() => handleImageClick(sentinel01NovMap)}>
                  <img
                    src={sentinel01NovMap}
                    alt="Image 1"
                    style={{
                      maxWidth: "100%",
                      height: "670px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} className="text-center">
                <div
                  className="mt-4"
                  // onClick={() => handleImageClick(sentinel01NovTable)}
                >
                  <TableContainer component={Paper} sx={{ height: 650 }}>
                    {landsat_nov01.length > 0 ? (
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          height: 650,
                          overflowX: isLargeScreen ? "hidden" : "auto",
                        }}
                        // aria-label="customized table"
                      >
                        <TableHead stickyHeader>
                          <TableRow>
                            {landsat_nov01.length > 0 &&
                              Object.keys(landsat_nov01[0]).map(
                                (key, index) => (
                                  <StyledTableCell key={index}>
                                    {key}
                                  </StyledTableCell>
                                )
                              )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {landsat_nov01.length > 0 ? (
                            landsat_nov01.map((row, index) => (
                              <StyledTableRow key={index}>
                                {Object.values(row).map((value, index) => (
                                  <StyledTableCell key={index}>
                                    {value}
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            ))
                          ) : (
                            <StyledTableRow>
                              <StyledTableCell
                                colSpan={Object.keys(landsat_nov01[0]).length}
                              >
                                <Typography>Loading...</Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
            {/* For Nov part 2 */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "25px",
              }}
              className="mt-5"
            >
              <h2 style={{ marginTop: 0 }} className="mx-2">
                Sentinel 3 NDVI for 11 Nov 2023
              </h2>
              <button
                variant="contained"
                color="primary"
                className="btn btn-primary"
                sx={{
                  backgroundColor: "#007bff",
                  "&:hover": { backgroundColor: "#0056b3" },
                }}
                onClick={downloadCSV8}
              >
                Download CSV
              </button>
            </Box>{" "}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div onClick={() => handleImageClick(sentinel11NovMap)}>
                  <img
                    src={sentinel11NovMap}
                    alt="Image 1"
                    style={{
                      maxWidth: "100%",
                      height: "670px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} className="text-center">
                <div
                  className="mt-4 "
                  // onClick={() => handleImageClick(sentinel11NovTable)}
                >
                  <TableContainer component={Paper} sx={{ height: 650 }}>
                    {landsat_nov11.length > 0 ? (
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          height: 650,
                          overflowX: isLargeScreen ? "hidden" : "auto",
                        }} // aria-label="customized table"
                      >
                        <TableHead stickyHeader>
                          <TableRow>
                            {landsat_nov11.length > 0 &&
                              Object.keys(landsat_nov11[0]).map(
                                (key, index) => (
                                  <StyledTableCell key={index}>
                                    {key}
                                  </StyledTableCell>
                                )
                              )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {landsat_nov11.length > 0 ? (
                            landsat_nov11.map((row, index) => (
                              <StyledTableRow key={index}>
                                {Object.values(row).map((value, index) => (
                                  <StyledTableCell key={index}>
                                    {value}
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            ))
                          ) : (
                            <StyledTableRow>
                              <StyledTableCell
                                colSpan={Object.keys(landsat_nov11[0]).length}
                              >
                                <Typography>Loading...</Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
            {/* For Nov part 3 */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "25px",
              }}
              className="mt-5"
            >
              <h2 style={{ marginTop: 0 }} className="mx-2">
                Sentinel 3 NDVI for 21 Nov 2023
              </h2>
              <button
                variant="contained"
                color="primary"
                className="btn btn-primary"
                sx={{
                  backgroundColor: "#007bff",
                  "&:hover": { backgroundColor: "#0056b3" },
                }}
                onClick={downloadCSV9}
              >
                Download CSV
              </button>
            </Box>{" "}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div onClick={() => handleImageClick(sentinel21NovMap)}>
                  <img
                    src={sentinel21NovMap}
                    alt="Image 1"
                    style={{
                      height: "670px",
                      maxWidth: "100%",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} className="text-center">
                <div
                  className="mt-4"
                  // onClick={() => handleImageClick(sentinel21NovTable)}
                >
                  <TableContainer component={Paper} sx={{ height: 650 }}>
                    {landsat_nov21.length > 0 ? (
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          height: 650,
                          overflowX: isLargeScreen ? "hidden" : "auto",
                        }} // aria-label="customized table"
                      >
                        <TableHead stickyHeader>
                          <TableRow>
                            {landsat_nov21.length > 0 &&
                              Object.keys(landsat_nov21[0]).map(
                                (key, index) => (
                                  <StyledTableCell key={index}>
                                    {key}
                                  </StyledTableCell>
                                )
                              )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {landsat_nov21.length > 0 ? (
                            landsat_nov21.map((row, index) => (
                              <StyledTableRow key={index}>
                                {Object.values(row).map((value, index) => (
                                  <StyledTableCell key={index}>
                                    {value}
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            ))
                          ) : (
                            <StyledTableRow>
                              <StyledTableCell
                                colSpan={Object.keys(landsat_nov21[0]).length}
                              >
                                <Typography>Loading...</Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          // {/*------------------------------------------------------  For December ------------------------------------------------------   */}
          //   {/* For Dec part 1 */}
          <div>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "25px",
              }}
              className="mt-5"
            >
              <h2 style={{ marginTop: 0 }} className="mx-2">
                Sentinel 3 NDVI for 01 Dec 2023
              </h2>
              <button
                variant="contained"
                color="primary"
                className="btn btn-primary"
                sx={{
                  backgroundColor: "#007bff",
                  "&:hover": { backgroundColor: "#0056b3" },
                }}
                onClick={downloadCSV10}
              >
                Download CSV
              </button>
            </Box>{" "}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div onClick={() => handleImageClick(sentinel01DecMap)}>
                  <img
                    src={sentinel01DecMap}
                    alt="Image 1"
                    style={{
                      maxWidth: "100%",
                      height: "670px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} className="text-center">
                <div
                  className="mt-4"
                  // onClick={() => handleImageClick(sentinel01DecTable)}
                >
                  <TableContainer component={Paper} sx={{ height: 650 }}>
                    {landsat_dec01.length > 0 ? (
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          height: 650,
                          overflowX: isLargeScreen ? "hidden" : "auto",
                        }} // aria-label="customized table"
                      >
                        <TableHead stickyHeader>
                          <TableRow>
                            {landsat_dec01.length > 0 &&
                              Object.keys(landsat_dec01[0]).map(
                                (key, index) => (
                                  <StyledTableCell key={index}>
                                    {key}
                                  </StyledTableCell>
                                )
                              )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {landsat_dec01.length > 0 ? (
                            landsat_dec01.map((row, index) => (
                              <StyledTableRow key={index}>
                                {Object.values(row).map((value, index) => (
                                  <StyledTableCell key={index}>
                                    {value}
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            ))
                          ) : (
                            <StyledTableRow>
                              <StyledTableCell
                                colSpan={Object.keys(landsat_dec01[0]).length}
                              >
                                <Typography>Loading...</Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
            {/* For Dec part 2 */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "25px",
              }}
              className="mt-5"
            >
              <h2 style={{ marginTop: 0 }} className="mx-2">
                Sentinel 3 NDVI for 11 Dec 2023
              </h2>
              <button
                variant="contained"
                color="primary"
                className="btn btn-primary"
                sx={{
                  backgroundColor: "#007bff",
                  "&:hover": { backgroundColor: "#0056b3" },
                }}
                onClick={downloadCSV11}
              >
                Download CSV
              </button>
            </Box>{" "}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div onClick={() => handleImageClick(sentinel11DecMap)}>
                  <img
                    src={sentinel11DecMap}
                    alt="Image 1"
                    style={{
                      maxWidth: "100%",
                      height: "670px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} className="text-center">
                <div
                  className="mt-4 "
                  // onClick={() => handleImageClick(sentinel11DecTable)}
                >
                  <TableContainer component={Paper} sx={{ height: 650 }}>
                    {landsat_dec11.length > 0 ? (
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          height: 650,
                          overflowX: isLargeScreen ? "hidden" : "auto",
                        }} // aria-label="customized table"
                      >
                        <TableHead stickyHeader>
                          <TableRow>
                            {landsat_dec11.length > 0 &&
                              Object.keys(landsat_dec11[0]).map(
                                (key, index) => (
                                  <StyledTableCell key={index}>
                                    {key}
                                  </StyledTableCell>
                                )
                              )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {landsat_dec11.length > 0 ? (
                            landsat_dec11.map((row, index) => (
                              <StyledTableRow key={index}>
                                {Object.values(row).map((value, index) => (
                                  <StyledTableCell key={index}>
                                    {value}
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            ))
                          ) : (
                            <StyledTableRow>
                              <StyledTableCell
                                colSpan={Object.keys(landsat_dec11[0]).length}
                              >
                                <Typography>Loading...</Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
            {/* For Dec part 3 */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "25px",
              }}
              className="mt-5"
            >
              <h2 style={{ marginTop: 0 }} className="mx-2">
                Sentinel 3 NDVI for 21 Dec 2023
              </h2>
              <button
                variant="contained"
                color="primary"
                className="btn btn-primary"
                sx={{
                  backgroundColor: "#007bff",
                  "&:hover": { backgroundColor: "#0056b3" },
                }}
                onClick={downloadCSV12}
              >
                Download CSV
              </button>
            </Box>{" "}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div onClick={() => handleImageClick(sentinel21DecMap)}>
                  <img
                    src={sentinel21DecMap}
                    alt="Image 1"
                    style={{
                      height: "670px",
                      maxWidth: "100%",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={6} className="text-center">
                <div
                  className="mt-4"
                  // onClick={() => handleImageClick(sentinel21DecTable)}
                >
                  <TableContainer component={Paper} sx={{ height: 650 }}>
                    {landsat_dec21.length > 0 ? (
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          height: 650,
                          overflowX: isLargeScreen ? "hidden" : "auto",
                        }} // aria-label="customized table"
                      >
                        <TableHead stickyHeader>
                          <TableRow>
                            {landsat_dec21.length > 0 &&
                              Object.keys(landsat_dec21[0]).map(
                                (key, index) => (
                                  <StyledTableCell key={index}>
                                    {key}
                                  </StyledTableCell>
                                )
                              )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {landsat_dec21.length > 0 ? (
                            landsat_dec21.map((row, index) => (
                              <StyledTableRow key={index}>
                                {Object.values(row).map((value, index) => (
                                  <StyledTableCell key={index}>
                                    {value}
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            ))
                          ) : (
                            <StyledTableRow>
                              <StyledTableCell
                                colSpan={Object.keys(landsat_dec21[0]).length}
                              >
                                <Typography>Loading...</Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      <FooterAgri />
    </>
  );
}
