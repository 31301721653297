import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import punjabAgriModis from "../../../src/public/punjabAgri/modis_14_Sep_2023.png";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Punjab_Agri() {
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(null);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleNavigation = () => {
    if (selectedTab === 0) {
      navigate("/punjab-agri/landsat");
    } else if (selectedTab === 1) {
      navigate("/punjab-agri/modis");
    } else if (selectedTab === 2) {
      navigate("/punjab-agri/sentinel-3");
    }
  };

  useEffect(() => {
    handleNavigation();
  }, [selectedTab]);

  return (
    <div className="container mt-5">
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={4}>
          <div onClick={() => setSelectedTab(0)} style={{ cursor: "pointer" }}>
            <img
              src={punjabAgriModis}
              alt="Image 1"
              style={{ maxWidth: "100%" }}
            />
            <Typography
              variant="body1"
              style={{ fontWeight: "bold" }}
              className="mx-3"
            >
              LANDSAT (30m Resolution)
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div
            onClick={() => setSelectedTab(1)}
            style={{ cursor: "pointer" }}
            className=" "
          >
            <img
              src={punjabAgriModis}
              alt="Image 2"
              style={{ width: "100%" }}
              className=" "
            />
            <Typography
              variant="body1"
              style={{ fontWeight: "bold" }}
              className="mx-3"
            >
              MODIS (250m Resolution)
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div onClick={() => setSelectedTab(2)} style={{ cursor: "pointer" }}>
            <img
              src={punjabAgriModis}
              alt="Image 3"
              style={{ maxWidth: "100%" }}
              className=""
            />
            <Typography
              variant="body1"
              style={{ fontWeight: "bold" }}
              className="mx-3"
            >
              SENTINEL 3 (300m Resolution){" "}
            </Typography>
          </div>
        </Grid>

        {/* ------------- Textual Content ------------- */}
        {selectedTab !== 0 && selectedTab !== 1 && selectedTab !== 2 && (
          <div className="container mx-4 my-5">
            <h1>Normalized Difference Vegetation Index (NDVI)</h1>
            <p>
              Normalized Difference Vegetation Index (NDVI) is referred to as
              <b> Crop Stress Insects and Diseases </b>on LIMS Crop Monitoring.
              NDVI measures the greenness and the density of the vegetation
              captured in a satellite image. Healthy vegetation has a very
              characteristic spectral reflectance curve which we can benefit
              from by calculating the difference between two bands – visible red
              and near-infrared.
            </p>
            <p>
              NDVI is frequently used and implemented index, that is computed as
              normalized ratio between red and near-infrared bands using
              multispectral data. The NDVI index is a means of determining how
              green the vegetation is. It detects and quantifies the presence of
              living green plants using reflected light in visible and
              near-infrared wavelengths.
            </p>
            <p>
              It is a measure of the state of plant health based on how the
              plant reflects light at specific frequencies (some waves are
              absorbed, and others are reflected). Chlorophyll (a health
              indicator pigment) strongly absorbs visible light, and the
              cellular structure of the leaves strongly reflects near-infrared
              light. When the plant becomes dehydrated, sick, afflicted with
              disease, etc., the spongy layer deteriorates, and the plant
              absorbs more of the near-infrared light, rather than reflecting
              it. Thus, observing how NIR changes compared to red light provides
              an accurate indication of the presence of chlorophyll, which
              correlates with plant health.
            </p>

            <h2 className="mt-5">
              Normalized Difference Vegetation Index: Change Detection
            </h2>
            <p>
              NDVI of a crop or a plant calculated regularly over periods of
              time can reveal a lot about the changes in their conditions. In
              other words, we can use NDVI to estimate plant health remotely.
            </p>
            <p>
              A sudden drop in the NDVI values may be a symptom of crop health
              deterioration.
            </p>
            <p>
              The value drop can also correspond to regular changes, such as the
              time of harvesting, which is why NDVI should be counter-checked
              against other available data. Correct NDVI values interpretation
              can help agronomists raise healthier yields, save money on
              fertilizers, and take better care of the environment. Among the
              typical spectral vegetation indices, NDVI is one of the most
              suitable to track crop development dynamics since it measures
              photosynthetically active biomass in plants. However, this
              vegetation index is quite sensitive to soil brightness and
              atmospheric effects, mitigated in other indices like SAVI.
            </p>

            <h2 className="mt-5">NDVI Formula</h2>
            <p>NDVI = (NIR – RED) / (NIR + RED)</p>
            <p>
              <b>Where:</b>
            </p>
            <ul>
              <li>
                <b>NIR</b> – light reflected in the near-infrared spectrum.
              </li>
              <li>
                <b>RED </b>– light reflected in the red range of the spectrum.
              </li>
            </ul>

            <h2 className="mt-5">Index Range</h2>
            <ul>
              <li>
                NDVI defines values from <b> -1.0 to 1.0,</b> where negative
                values are mainly formed from clouds, water and snow, and values
                close to zero are primarily formed from rocks and bare soil.
              </li>
              <li>
                Very small values ( <b>0.1 or less</b>) of the NDVI function
                correspond to empty areas of rocks, sand, or snow.
              </li>
              <li>
                Moderate values (from <b>0.2 to 0.3</b>) represent shrubs and
                meadows, while large values (from <b>0.6 to 0.8</b>) indicate
                temperate and tropical forests.
              </li>
            </ul>

            <p>
              <b>Key fact:</b> NDVI is the most common vegetation index in
              remote sensing. It can be used throughout the whole crop
              production season except when vegetation cover is too scarce, so
              its spectral reflectance is too low.
            </p>

            <p>
              <b>When to use:</b> NDVI values are the most accurate in the
              middle of the season at the stage of active crop growth.
            </p>

            <h2 className="mt-5">Application of NDVI in Agriculture</h2>
            <ul>
              <li>
                Precision agriculture platforms take advantage of NDVI to guide
                crop scouts to problem areas in the field and improve the
                precision of fertilizer application and irrigation, among other
                field treatment activities.
              </li>
              <li>
                NDVI is preferable for global vegetation monitoring since it
                helps to compensate for changes in lighting conditions, surface
                slope, exposure, and other external factors.
              </li>
            </ul>
          </div>
        )}
      </Grid>

      {/* Render other images based on  tab */}
      {/* {selectedTab === 0 && <Landsat />}
      {selectedTab === 1 && <Modis />}
      {selectedTab === 2 && <Sentinel3 />} */}
    </div>
  );
}

export default Punjab_Agri;
