import { Typography } from "@mui/material";
import Card from "react-bootstrap/Card";

function ImgOverlayExample({
  image,
  text,
  height,
  titlePartOne,
  titlePartTwo,
  logoLeft,
  logoRight,
}) {
  return (
    <div style={{ position: "relative", marginTop: "100px" }}>
      <img
        src={logoLeft}
        alt="Logo Left"
        style={{
          position: "absolute",
          top: 10,
          left: 16,
          height: "100px",
          width: "100px",
          zIndex: "1",
        }}
      />
      <img
        src={logoRight}
        alt="Logo Right"
        style={{
          position: "absolute",
          top: 15,  
          right: 16,
          height: "90px",
          width: "90px",
          zIndex: "1",
          borderRadius: "80px",
        }}
      />

      <Card className="bg-dark text-white" style={{ marginTop: "100px" }}>
        <Card.Img
          src={image}
          alt="Card image"
          height={height}
          position="relative"
        />
        <Card.ImgOverlay
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(2, 15, 35, 0.5), rgba(2, 15, 35, 0.5))`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: 0,
            width: "100%",
          }}
        >
          <Card.Title style={{ color: "white" }}>
            <Typography
              fontFamily="Poppins"
              variant="h3"
              style={{
                textAlign: "center",
                display: "block",
                fontSize: "35px",
                lineHeight: "46px",
                letterSpacing: ".01em",
                fontWeight: 800,
              }}
              sx={{ marginTop: { xs: "100px", md: "100px" } }}
            >
              {titlePartOne}{" "}
              <span
                style={{
                  WebkitTextStroke: "1px",
                  WebkitTextFillColor: "transparent",
                }}
              >
                {titlePartTwo}
              </span>
            </Typography>
          </Card.Title>
          <Card.Text></Card.Text>
          <Card.Text color="primary">{text}</Card.Text>
        </Card.ImgOverlay>
      </Card>
    </div>
  );
}

export default ImgOverlayExample;
