import { Grid, Box, Typography } from "@mui/material";
import tehsilWeather from "./TehsilWeatherArray";
import useWindowDimensions from "../hooks/getScreenDimensions";
import CustomSelect from "./CustomSelect";
import "yet-another-react-lightbox/styles.css";
import Lightbox from "yet-another-react-lightbox";
import Date from "./Date";
import { Suspense, useState } from "react";
import CustomGallery from "./CustomGallery";
import Loader from "../utils/Loader";
import District from "./District";
import elementWeather from "./ElementWeatherArray";
import Marquee from "react-fast-marquee";
const CustomWeather = ({ province, weatherDate, tehsilImages }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handlePopUpOpen = () => {
    setIsOpen(true);
  };
  let customWeatherImages = tehsilImages.filter((weather) => {
    return weather.date === weatherDate;
  });
  const lightboxSources = customWeatherImages.map((weather) => ({
    src: weather.src,
  }));
  return (
    <>
      <Grid
        item
        xs={10}
        mt={1}
        mb={1}
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          borderBottom: "1px solid #0E97FF",
          marginBottom: "5px",
          paddingBottom: "5px",
        }}
      >
        <Typography variant="h7" fontFamily="Poppins">
          <strong>{weatherDate}</strong>
        </Typography>
      </Grid>
      <Grid item xs={10} fontSize={25}>
        {province.id == 1 ? (
          <Suspense fallback={<Loader />}>
            <CustomGallery
              images={customWeatherImages}
              onClick={handlePopUpOpen}
              flexBasis={"25%"}
            />
          </Suspense>
        ) : null}
      </Grid>
      <Lightbox
        open={isOpen}
        close={() => setIsOpen(false)}
        slides={lightboxSources}
      />
    </>
  );
};

const getNameFromCode = (code) => {
  switch (code) {
    case "BHV":
      return "Bahawalpur";
    case "DGK":
      return "Dera Gazi Khan";
    case "FSD":
      return "Faislabad";
    case "GRW":
      return "Gujranwala";
    case "LHR":
      return "Lahore";
    case "MUL":
      return "Multan";
    case "RWP":
      return "Rawalpindi";
    case "SWH":
      return "Sahiwal";
    case "SGD":
      return "Sarghoda";
    default:
      return "Unknown";
  }
};

const uniqueKeys = (arr) => {
  const uniqueValues = Array.from(new Set(arr.map((obj) => obj.date)));
  return uniqueValues;
};

const isPresent = (arr, date) => {
  return arr.some((obj) => obj.date === date);
};

export default function Weather({
  province,
  dateFilter,
  setDateFilter,
  districtFilter,
  setDistrictFilter,
}) {
  const { width } = useWindowDimensions();
  let tehsilImages;
  let elementImages;
  if (dateFilter === "All") {
    tehsilImages = tehsilWeather;
  } else {
    tehsilImages = tehsilWeather.filter((weather) => {
      return dateFilter === "All" || weather.date === dateFilter;
    });
  }

  if (dateFilter === "All" && districtFilter === "All") {
    elementImages = elementWeather;
  } else {
    elementImages = elementWeather.filter((weather) => {
      return (
        (districtFilter === "All" || weather.district === districtFilter) &&
        (dateFilter === "All" || weather.date === dateFilter)
      );
    });
  }

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" mt={3}>
        <Grid item xs={12}></Grid>
        {/* <Grid item xs={10} dir={"rtl"}>
            <Marquee speed={25} pauseOnHover={true} style={{
              overflowY:'hidden',
              paddingBottom:'5px'
            }}>
            <Typography variant="h4" fontFamily={"Jameel Noori Nastaleeq Regular"}>
            پاکستان کے مختلف اضلاع کے ہفتہ وار موسمی تبدیلیوں کی اطلاعات۔ نیچے دی گئی معلومات کو دیکھ کر اپنی زمین تیار اور کاشت کریں۔
            </Typography>
            </Marquee>
        </Grid> */}
        <Grid
          item
          xs={10}
          mt={3}
          mb={1}
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            marginBottom: "5px",
            paddingBottom: "5px",
          }}
        >
          {width < 500 ? (
            <CustomSelect
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
              districtFilter={districtFilter}
              setDistrictFilter={setDistrictFilter}
            />
          ) : (
            <Box style={{ display: "flex" }}>
              <Date dateFilter={dateFilter} setDateFilter={setDateFilter} />
              <District
                districtFilter={districtFilter}
                setDistrictFilter={setDistrictFilter}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      {districtFilter === "All" ? (
        <>
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              item
              xs={10}
              mt={1}
              mb={1}
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                marginBottom: "5px",
                paddingBottom: "5px",
              }}
            >
              <Typography variant="h5" fontFamily="Poppins">
                <strong>{"Punjab Tehsils"}</strong>
              </Typography>
            </Grid>
          </Grid>

          {(() => {
            const keys = uniqueKeys(tehsilImages);
            return keys.map((key) =>
              isPresent(tehsilImages, key) ? (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  mt={1}
                  key={key}
                >
                  <CustomWeather
                    province={province}
                    weatherDate={key}
                    tehsilImages={tehsilImages}
                  />
                </Grid>
              ) : null
            );
          })()}
        </>
      ) : null}
      {districtFilter !== "All" ? (
        <>
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              item
              xs={10}
              mt={1}
              mb={1}
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                marginBottom: "5px",
                paddingBottom: "5px",
              }}
            >
              <Typography variant="h5" fontFamily="Poppins">
                <strong>{getNameFromCode(districtFilter)}</strong>
              </Typography>
            </Grid>
          </Grid>
          {(() => {
            const keys = uniqueKeys(elementWeather);
            return keys.map((key) =>
              isPresent(elementImages, key) ? (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  mt={1}
                  key={key}
                >
                  <CustomWeather
                    province={province}
                    weatherDate={key}
                    tehsilImages={elementImages}
                  />
                </Grid>
              ) : (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  mt={1}
                  key={key}
                >
                  <Typography>No data found!</Typography>
                </Grid>
              )
            );
          })()}
        </>
      ) : null}
    </>
  );
}
