import { Grid,Typography } from "@mui/material";

export default function Projects({image,des}) {
    return (
      <Grid
        item
        mt={5}
        xs={12}
        lg={2.4}
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "65vh",
          position: "relative",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            position: "absolute",
            bottom: "30px",
            left: "30px",
            color: "white",
          }}
        >
          {" "}
          <strong>{des}</strong>
        </Typography>
      </Grid>
    );
  }
  