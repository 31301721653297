import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Container } from 'react-bootstrap';
import pdfFile1 from "../../public/Emp of Irri Engr & Hydrologist.pdf";
import pdfFile2 from "../../public/Hydroligist.pdf";
import pdfFile3 from "../../public/Automation Engineer.pdf";

function truncateText(text, limit) {
  const words = text.split(' ');
  if (words.length > limit) {
    return words.slice(0, limit).join(' ') + '...';
  }
  return text;
}

function Career() {
  const [showModal, setShowModal] = useState(false);
  const [modalPdfUrl, setModalPdfUrl] = useState('');

  const cardData = [
    {
      title: 'JOB OPPORTUNITIES = Irrigation Engineer – Design Section',
      text: 'Publishing Date: 2024-04-25',
      text1: 'Closing Date: 2024-05-09',
      buttonText: 'Preview',
      logo1: "https://d3vnf7txgu507y.cloudfront.net/assets/images/fi-rr-time-forward.svg",
      logo2: "https://d3vnf7txgu507y.cloudfront.net/assets/images/vector.svg",
      modalPdfUrl: pdfFile1 // Replace with the PDF file URL for this card
    },
    {
      title: 'JOB OPPORTUNITIES = Hydrologist',
      text: 'Publishing Date: 2024-04-25',
      text1: 'Closing Date: 2024-05-09',
      buttonText: 'Preview',
      logo1: "https://d3vnf7txgu507y.cloudfront.net/assets/images/fi-rr-time-forward.svg",
      logo2: "https://d3vnf7txgu507y.cloudfront.net/assets/images/vector.svg",
      modalPdfUrl: pdfFile2 // Replace with the PDF file URL for this card
    },
    {
      title: 'CAREER OPPORTUNITY AS Automation Engineer',
      text: 'Publishing Date: 2024-04-17',
      text1: 'Closing Date: 2024-05-09',
      buttonText: 'Preview',
      logo1: "https://d3vnf7txgu507y.cloudfront.net/assets/images/fi-rr-time-forward.svg",
      logo2: "https://d3vnf7txgu507y.cloudfront.net/assets/images/vector.svg",
      modalPdfUrl: pdfFile3// Replace with the PDF file URL for this card
    },
    // {
    //   title: 'CAREER OPPORTUNITY AS Automation Engineer',
    //   text: 'Publishing Date: 2024-04-17',
    //   text1: 'Closing Date: 2024-05-02',
    //   buttonText: 'Preview',
    //   logo1: "https://d3vnf7txgu507y.cloudfront.net/assets/images/fi-rr-time-forward.svg",
    //   logo2: "https://d3vnf7txgu507y.cloudfront.net/assets/images/vector.svg",
    //   modalPdfUrl: pdfFile3// Replace with the PDF file URL for this card
    // }
  ];

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = (modalPdfUrl) => {
    setModalPdfUrl(modalPdfUrl);
    setShowModal(true);
  };

  return (
    <Container>
      <Row xs={1} md={3} className="g-4" style={{ marginTop: "20px" }}>
        {cardData.map((card, idx) => (
          <Col key={idx} xs={12} sm={6} md={4}>
            <Card style={{ background: "#F5F7FB", borderRadius: "25px", fontFamily: 'Poppins, sans-serif', fontSize: '14px',height:"390px" }}>
              <Card.Body>
                <Card.Title style={{ fontSize: '16px', marginBottom: '10px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{truncateText(card.title, 5)}</Card.Title>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <img src={card.logo1} alt="Logo" style={{ marginRight: '10px' }} />
                  <span>{card.text}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '50px' }}>
                  <img src={card.logo2} alt="Logo" style={{ marginRight: '10px' }} />
                  <span>{card.text1}</span>
                </div>
                <hr></hr>
              </Card.Body>
              <Card.Footer className="text-center" style={{ background: "#0e97ff", borderRadius: "17px", padding: "10px 20px",display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Button onClick={() => handleShowModal(card.modalPdfUrl)} style={{ borderRadius: "8px", width: "150px", background: "#e5e8ef", border: "1px solid #dfe2e", color: "black" }}>{card.buttonText}</Button>
                <a href={card.modalPdfUrl} download="example.pdf">
                  <img src="https://d3vnf7txgu507y.cloudfront.net/assets/images/careerdownload.svg" alt="Career Download" style={{ height: "50px", width: "auto", marginLeft: '10px' }} />
                </a>
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>
      <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal Title</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <iframe src={modalPdfUrl} style={{ width: '100%', height: '400px', border: 'none' }}></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Career;
