import { Box, Grid, Typography, Fab } from "@mui/material";
import BreadCrumbs from "./BreadCrumbs";
import Provinces from "./Provinces";
import { useState } from "react";
import Province from "./Province";
import Choice from "./Choice";
import Punjab from "../../public/land/provinces/Punjab.jpeg";
export default function Main() {
  const [province, setProvince] = useState({
    id: 1,
    image: Punjab,
    name: "Punjab",
  });
  const [choice, setChoice] = useState(false);
  const [dateFilter, setDateFilter] = useState("All");
  const [districtFilter, setDistrictFilter] = useState("All");
  const [elementFilter, setElementFilter] = useState("All");
  return (
    <>
      <Grid container justifyContent="center" alignItems="center" mt={10}>
        <Grid
          item
          xs={10}
          style={{
            borderBottom: "1px solid #0E97FF",
            marginBottom: "5px",
            paddingBottom: "5px",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "5px",
            }}
          >
            <Typography variant="h4" fontFamily="Poppins">
              <strong>{province == false ? "Provinces" : province.name}</strong>
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "5px",
            }}
          >
            <BreadCrumbs
              province={province}
              setProvince={setProvince}
              choice={choice}
              setChoice={setChoice}
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
              districtFilter={districtFilter}
              setDistrictFilter={setDistrictFilter}
              elementFilter={elementFilter}
              setElementFilter={setElementFilter}
            />
          </Box>
        </Grid>
      </Grid>
      {province.name != null && choice.name != null ? (
        <Province
          province={province}
          choice={choice}
          districtFilter={districtFilter}
          setDistrictFilter={setDistrictFilter}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          elementFilter={elementFilter}
          setElementFilter={setElementFilter}
        />
      ) : province.name != null && choice == false ? (
        <Choice setChoice={setChoice} />
      ) : (
        <Provinces setProvince={setProvince} />
      )}
    </>
  );
}
