import React, { useState, useEffect,useRef  } from 'react';
// import whiteLogo from '../../assets/white_logo.svg';
// import backgroundImage from '../../assets/background.jpg';
// import Title from '../utils/Title';
// import { Tilt } from 'react-tilt';
import * as L from 'leaflet'
import { Grid, Typography,Alert } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import { MapContainer, TileLayer, Marker, Popup,  useMapEvents,useMap } from "react-leaflet";
import './style.css'
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import { GeoSearchControl, OpenStreetMapProvider, EsriProvider } from "leaflet-geosearch";
import LeafletgeoSearch from './LeafletgeoSearch';
import MapControls from './MapControls';
import { Modal, Button, Drawer, Space } from 'antd';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Tour from 'reactour'
import Title from '../utils/Title';
import { useNavigate } from 'react-router-dom';
import './font.css'
import parse from 'html-react-parser';
import icon from "../utils/constants";
import RegistrationForm from './RegistrationForm';
const position = [30.33084, 71.24750]
export const AdvisoryModal = NiceModal.create(({ location,farm_location }) => {
  const modal = useModal();
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [message, setMessage] = useState('');
  const [severity,setSeverity] = useState('')
  const handleFormSubmit = (condition) => {
    if(condition){
      setMessage('Message sent successfully')
      setSeverity('success')
    }else{
      setMessage('Message sent failed.Please try again after 1 minutes')
      setSeverity('error')
    }
    setFormSubmitted(true);
  };
  useEffect(() => {
    if (isFormSubmitted) {
      const timeout = setTimeout(() => {
        setFormSubmitted(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [isFormSubmitted]);
    //console.log("KML",location)
    return (
      <Modal open={modal.visible}
      okButtonProps={{ style: { display: 'none' } }}
      cancelText="Close"
       title={" "}
       width={1300}
       onCancel={modal.hide}
       afterClose={modal.remove}>
     <RegistrationForm
           onFormSubmit={handleFormSubmit}
           boxShadow={false}
           kml_link={location}
           farm_location = {farm_location}
           />
           {isFormSubmitted && <Alert severity={severity}
          role="presentation"
          sx={{
            width:{
              lg:'18%'
            },
            position: "fixed",
            bottom: "5%",
            left: "2%",
            zIndex: 1,
            animation: 'fade-out 5s forwards'
          }}
          >
            {message}</Alert> }
      </Modal>
    );
  });
function RegistrationMapView() {
      const steps = [
        {
          selector: ".leaflet-geosearch-button",
          content:
           parse(`<span style="font-family:'Jameel Noori Nastaleeq Regular'; font-size:20px"}}>Geo locate Farm if you have Lat/Lng or with district name.</br>کوارڈینیٹس یا ضلع / تحصیل کے نام کا استعمال کرکے اپنا کھیت تلاش کریں</span>`),
        },
        {
          selector: ".leaflet-pm-toolbar",
          content:
            parse(`<span style="font-family:'Jameel Noori Nastaleeq Regular'; font-size:20px"}}>Draw map boundry using any of these tool<br/>اپنے فارم کی حدود لگائیں</span>`),
        },
        {
          selector: ".leaflet-pm-icon-polygon",
          content:
            parse(`<span style="font-family:'Jameel Noori Nastaleeq Regular'; font-size:20px"}}>Recommended is to use polygon shape to draw exact boundry<br/>یہ ٹول نشاندہی کے لئے بہترین ہے</span>`),
        },
        {
          selector: ".leaflet-pm-icon-delete",
          content:
            parse(`<span style="font-family:'Jameel Noori Nastaleeq Regular'; font-size:20px"}}>If boundry is marked incorrect, you can use this tool to remove it.<br/> اگر نشاندہی غلط مارک کی گئی ہو تو آپ اس ٹول کا استعمال کر کے اسے ہٹا سکتے ہیں۔</span>`),
        },
        {
          selector: ".step4",
          content:
            parse(`<span style="font-family:'Jameel Noori Nastaleeq Regular'; font-size:20px"}}>After completing boundry marking, fill necessary details with your message and you will be contacted shortly<br/>نشاندہی مکمل ہونے کے بعد، ضروری تفصیلات کے ساتھ آپ فارم بھریں اور آپ سے جلدی رابطہ کیا جائے گا۔</span>`),
        },
      ];
    const [isTourOpen, setIsTourOpen] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const handleOpenModal = () => {
      setModalOpen(true);
    };
    const handleCloseModal = () => {
      setModalOpen(false);
    };
    const [myPosition, setMyPosition] = useState([]);
    const [myLat, setMyLat] = useState(0);
    const [myLng, setMyLng] = useState(0);
    useEffect(() => {
      navigator.geolocation.getCurrentPosition(
        function(position) {
          setMyLat(position.coords.latitude);
          setMyLng(position.coords.longitude);
         // setMyPosition({lat: position.coords.latitude, lng:position.coords.longitude})
        },
        function(error) {
        }
      );
      //console.log(myPosition)
    }, [ ]);
    const navigate = useNavigate();
    const goBack = () => {
      navigate('/');
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Email:', email);
        console.log('Password:', password);
    };
  //   function LocationMarker(myPosition) {
  //     console.log("myPosition",myPosition)
  //    // const [position, setPosition] = useState(null)
  //     // const map = useMapEvents({
  //     //   click() {
  //     //     map.locate()
  //     //   },
  //     //   locationfound(e) {
  //     //     console.log("e.latlng",e.latlng,myPosition)
  //     //     setPosition(e.latlng)
  //     //     map.flyTo(e.latlng, map.getZoom())
  //     //   },
  //     // })
  //    return myPosition === null ? null : (
  //      <Marker icon={icon} position={[myPosition.lat, myPosition.lng]}>
  //    </Marker>
  //    )
  //  }
  const RecenterAutomatically = ({lat,lng}) => {
    const map = useMap();
     useEffect(() => {
       map.setView([lat, lng]);
       map.zoomIn(13)
     }, [lat, lng]);
     return null;
   }
    return (
        <>
        <Title title={'Wheat Farm Registration'}/>
         <div
        style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          zIndex: 1000,
          display: 'flex',
          alignItems: 'center',cursor:'pointer',
          borderRadius:'10px',
          padding:'10px',
          background:'#31313196'
        }}
      >
        <ArrowBackIcon
          style={{
            color: 'white',
            fontSize: '32px',
            marginRight: '5px'
          }}
        />
        <span  onClick={goBack} style={{ color: 'white', fontSize: '18px' }}>Go Back</span>
      </div>
        <MapContainer
            center={position}
            zoomControl={false}
            zoom={7}
            scrollWheelZoom={true}
            style={{ minHeight: "100vh", minWidth: "100vw" }}
        >
            <TileLayer
                url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                maxZoom={20}
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            />
             {/* <ZoomControl position="bottomright" /> */}
            <LeafletgeoSearch className="step-1" />
            <MapControls modal={AdvisoryModal}/>
            {
              myLat === 0 ? null : (  <RecenterAutomatically lat={myLat} lng={myLng} />)
            }
         </MapContainer>
            <Tour
                backdrop={"static"}
                steps={steps}
                onRequestClose={() => setIsTourOpen(false)}
                closeWithMask={false}
                isOpen={isTourOpen} />
        </>
    );
}
export default RegistrationMapView;