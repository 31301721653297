import React, {  useEffect  } from 'react';

 
function StartMonitoring() { 
  

   
    useEffect(() => {
        window.location.href = "https://limspakistan.com/agrimonitoring/index.php"; 
    }, [ ]);

  
    return (
        <>     
        </>
    );
}
export default StartMonitoring;
