import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function Element({elementFilter,setElementFilter}) {
  const handleChange = (event) => {
    setElementFilter(event.target.value);
  };

  return (
    <Box sx={{ width: 200 }} marginLeft={1}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Element</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={elementFilter}
          label="Element"
          onChange={handleChange}
        >
          <MenuItem value={'All'}>All</MenuItem>
          <MenuItem value={'B'}>Boron</MenuItem>
          <MenuItem value={'EC'}>Electronic Conductivity</MenuItem>
          <MenuItem value={'Fe'}>Iron</MenuItem>
          <MenuItem value={'Mn'}>Maganese</MenuItem>
          <MenuItem value={'OM'}>Organic Matter</MenuItem>
          <MenuItem value={'pH'}>PH</MenuItem>
          <MenuItem value={'P'}>Phosporus</MenuItem>
          <MenuItem value={'K'}>Potassium</MenuItem>
          <MenuItem value={'Zn'}>Zinc</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}