import * as React from "react";
import { Card, CardHeader, CardContent } from "@mui/material";
export default function More({ title, description }) {
  return (
    <Card
      sx={{
        maxWidth: 300,
        borderRadius: "23px",
        padding: "2%",
        height:'200px'
      }}
    >
      <CardContent style={{textAlign:'center',fontFamily:'Poppins'}}>
        <span style={{fontSize:'16px',lineHeight:'19px'}}><strong>{title}</strong></span>
        <br/>
        <span style={{
        fontSize:'13px',
        fontWeight:'300',
        lineHeight:'22px'}}>{description}</span>
        <br/>
        {/* <span style={{color: '#0E97FF',to:'20px',fontSize:'13px',fontWeight:'500',textTransform:'uppercase'}}>View More</span> */}
      </CardContent>
    </Card>
  );
}