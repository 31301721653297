import { Grid, Box, Typography } from "@mui/material";
import District from "./District";
import Element from "./Element";
import elementSoil from "./ElementSoilArray";
import districtSoil from "./DistrictSoilArray";
import tehsilSoil from "./TehsilSoilArray";
import useWindowDimensions from "../hooks/getScreenDimensions";
import CustomSelect from "./CustomSelect";
import "yet-another-react-lightbox/styles.css";
import Lightbox from "yet-another-react-lightbox";
import { Suspense, useState } from "react";
import CustomGallery from "./CustomGallery";
import Loader from '../utils/Loader'
import Marquee from "react-fast-marquee";
const CustomDistrict = ({
  province,
  districtCode,
  districtName,
  soilImages,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handlePopUpOpen = () => {
    setIsOpen(true);
  };
  let districtSoilImages = soilImages.filter((soil) => {
    return soil.district === districtCode;
  });

  const lightboxSources = districtSoilImages.map((soil) => ({ src: soil.src }));
  return (
    <>
      <Grid
        item
        xs={10}
        mt={1}
        mb={1}
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          borderBottom: "1px solid #0E97FF",
          marginBottom: "5px",
          paddingBottom: "5px",
        }}
      >
        <Typography variant="h5" fontFamily="Poppins">
          <strong>{districtName}</strong>
        </Typography>
      </Grid>
      <Grid item xs={10} fontSize={25}>
        {province.id == 1 ? (
          <Suspense fallback={<Loader/>}>
            <CustomGallery
            images={districtSoilImages}
            onClick={handlePopUpOpen}
            flexBasis={"33.33333%"}
          />
          </Suspense>
        ) : null}
      </Grid>
      <Lightbox
        open={isOpen}
        close={() => setIsOpen(false)}
        slides={lightboxSources}
      />
    </>
  );
};

const isPresent = (arr, district) => {
  return arr.some((obj) => obj.district === district);
};

export default function Soil({
  province,
  districtFilter,
  setDistrictFilter,
  elementFilter,
  setElementFilter,
}) {
  const { width } = useWindowDimensions();
  let soilImages;
  let districtImages;
  if (districtFilter === "All" && elementFilter === "All") {
    soilImages = elementSoil;
    districtImages = districtSoil
  } else {
    soilImages = elementSoil.filter((soil) => {
      return (
        (districtFilter === "All" || soil.district === districtFilter) &&
        (elementFilter === "All" || soil.element === elementFilter)
      );
    });
    districtImages = districtSoil.filter((soil) => {
      return (
        (elementFilter === "All" || soil.element === elementFilter)
      );
    });
  }

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" mt={3}>
        <Grid item xs={12}></Grid>
        {/* <Grid item xs={10} dir={"rtl"}>
            <Marquee speed={25} pauseOnHover={true} style={{
              overflowY:'hidden',
              paddingBottom:'5px'
            }}>
            <Typography variant="h4" fontFamily={"Jameel Noori Nastaleeq Regular"}>
            پاکستان کے مختلف اضلاع کی زرعی زمین کے نقشے۔ نیچے دی گئی معلومات کو دیکھ کر اپنی زمین تیار اور کاشت کریں۔
            </Typography>
            </Marquee>
        </Grid> */}
        <Grid
          item
          xs={10}
          mt={3}
          mb={1}
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            marginBottom: "5px",
            paddingBottom: "5px",
          }}
        >
          {width < 500 ? (
            <CustomSelect
              districtFilter={districtFilter}
              setDistrictFilter={setDistrictFilter}
              elementFilter={elementFilter}
              setElementFilter={setElementFilter}
            />
          ) : (
            <Box style={{ display: "flex" }}>
              <District
                districtFilter={districtFilter}
                setDistrictFilter={setDistrictFilter}
              />
              <Element
                elementFilter={elementFilter}
                setElementFilter={setElementFilter}
              />
            </Box>
          )}
        </Grid>

      </Grid>
      {districtFilter == "All" ? (
        <Grid container justifyContent="center" alignItems="center" mt={3}>
          <CustomDistrict
            province={province}
            districtCode={"PB"}
            districtName={"Punjab Districts"}
            soilImages={districtImages}
          />
        </Grid>
      ) : null}
      {districtFilter == "All" && elementFilter == "All" ? (
        <Grid container justifyContent="center" alignItems="center" mt={3}>
          <CustomDistrict
            province={province}
            districtCode={"PB"}
            districtName={"Punjab Tehsils"}
            soilImages={tehsilSoil}
          />
        </Grid>
      ) : null}

      {(districtFilter!='All' || elementFilter!='All') && isPresent(soilImages, "BHV") == true ? (
        <Grid container justifyContent="center" alignItems="center" mt={3}>
          <CustomDistrict
            province={province}
            districtCode={"BHV"}
            districtName={"Bahawalpur"}
            soilImages={soilImages}
          />
        </Grid>
      ) : null}
      {(districtFilter!='All' || elementFilter!='All') && isPresent(soilImages, "DGK") == true ? (
        <Grid container justifyContent="center" alignItems="center" mt={3}>
          <CustomDistrict
            province={province}
            districtCode={"DGK"}
            districtName={"Dera Gazi Khan"}
            soilImages={soilImages}
          />
        </Grid>
      ) : null}
      {(districtFilter!='All' || elementFilter!='All') && isPresent(soilImages, "FSD") == true ? (
        <Grid container justifyContent="center" alignItems="center" mt={3}>
          <CustomDistrict
            province={province}
            districtCode={"FSD"}
            districtName={"Faislabad"}
            soilImages={soilImages}
          />
        </Grid>
      ) : null}
      {(districtFilter!='All' || elementFilter!='All') && isPresent(soilImages, "GRW") == true ? (
        <Grid container justifyContent="center" alignItems="center" mt={3}>
          <CustomDistrict
            province={province}
            districtCode={"GRW"}
            districtName={"Gujranwala"}
            soilImages={soilImages}
          />
        </Grid>
      ) : null}
      {(districtFilter!='All' || elementFilter!='All') && isPresent(soilImages, "LHR") == true ? (
        <Grid container justifyContent="center" alignItems="center" mt={3}>
          <CustomDistrict
            province={province}
            districtCode={"LHR"}
            districtName={"Lahore"}
            soilImages={soilImages}
          />
        </Grid>
      ) : null}
      {(districtFilter!='All' || elementFilter!='All') && isPresent(soilImages, "MUL") == true ? (
        <Grid container justifyContent="center" alignItems="center" mt={3}>
          <CustomDistrict
            province={province}
            districtCode={"MUL"}
            districtName={"Multan"}
            soilImages={soilImages}
          />
        </Grid>
      ) : null}
      {(districtFilter!='All' || elementFilter!='All') && isPresent(soilImages, "RWP") == true ? (
        <Grid container justifyContent="center" alignItems="center" mt={3}>
          <CustomDistrict
            province={province}
            districtCode={"RWP"}
            districtName={"Rawalpindi"}
            soilImages={soilImages}
          />
        </Grid>
      ) : null}
      {(districtFilter!='All' || elementFilter!='All') && isPresent(soilImages, "SWH") == true ? (
        <Grid container justifyContent="center" alignItems="center" mt={3}>
          <CustomDistrict
            province={province}
            districtCode={"SWH"}
            districtName={"Sahiwal"}
            soilImages={soilImages}
          />
        </Grid>
      ) : null}
      {(districtFilter!='All' || elementFilter!='All') && isPresent(soilImages, "SGD") == true ? (
        <Grid container justifyContent="center" alignItems="center" mt={3}>
          <CustomDistrict
            province={province}
            districtCode={"SGD"}
            districtName={"Sarghoda"}
            soilImages={soilImages}
          />
        </Grid>
      ) : null}
    </>
  );
}
