export const modis_sep14 = [
  { DISTRICT: "Attock", MIN: -0.1858, MAX: 0.9121, MEAN: 0.3682 },
  { DISTRICT: "Bahawalnagar", MIN: -0.1908, MAX: 0.8741, MEAN: 0.4133 },
  { DISTRICT: "Bahawalpur", MIN: -0.1469, MAX: 0.8648, MEAN: 0.2688 },
  { DISTRICT: "Bhakkar", MIN: -0.1993, MAX: 0.7919, MEAN: 0.2674 },
  { DISTRICT: "Chakwal", MIN: -0.1892, MAX: 0.8369, MEAN: 0.3811 },
  { DISTRICT: "Chiniot", MIN: -0.121, MAX: 0.9066, MEAN: 0.566 },
  { DISTRICT: "Dera Ghazi Khan", MIN: -0.1984, MAX: 0.9025, MEAN: 0.3018 },
  { DISTRICT: "Faisalabad", MIN: -0.0852, MAX: 0.9107, MEAN: 0.4895 },
  { DISTRICT: "Gujranwala", MIN: -0.1994, MAX: 0.9278, MEAN: 0.546 },
  { DISTRICT: "Gujrat", MIN: -0.1035, MAX: 0.8696, MEAN: 0.4943 },
  { DISTRICT: "Hafizabad", MIN: -0.1479, MAX: 0.9354, MEAN: 0.7071 },
  { DISTRICT: "Jhang", MIN: -0.1858, MAX: 0.8987, MEAN: 0.4673 },
  { DISTRICT: "Jhelum", MIN: -0.1713, MAX: 0.836, MEAN: 0.4059 },
  { DISTRICT: "Kasur", MIN: -0.1991, MAX: 0.8793, MEAN: 0.5233 },
  { DISTRICT: "Khanewal", MIN: 0.0277, MAX: 0.8388, MEAN: 0.5416 },
  { DISTRICT: "Khushab", MIN: -0.1408, MAX: 0.9146, MEAN: 0.3574 },
  { DISTRICT: "Lahore", MIN: -0.1619, MAX: 0.8841, MEAN: 0.435 },
  { DISTRICT: "Layyah", MIN: -0.1998, MAX: 0.8407, MEAN: 0.3321 },
  { DISTRICT: "Lodhran", MIN: -0.0214, MAX: 0.8699, MEAN: 0.5927 },
  { DISTRICT: "Mandi Bahauddin", MIN: -0.1748, MAX: 0.9142, MEAN: 0.6167 },
  { DISTRICT: "Mianwali", MIN: -0.1998, MAX: 0.9827, MEAN: 0.3224 },
  { DISTRICT: "Multan", MIN: -0.1374, MAX: 0.8351, MEAN: 0.4982 },
  { DISTRICT: "Muzaffargarh", MIN: -0.1923, MAX: 0.8878, MEAN: 0.4105 },
  { DISTRICT: "Nankana Sahib", MIN: 0.0301, MAX: 0.9208, MEAN: 0.5999 },
  { DISTRICT: "Narowal", MIN: 0.0827, MAX: 0.9255, MEAN: 0.641 },
  { DISTRICT: "Okara", MIN: -0.1629, MAX: 0.7922, MEAN: 0.4964 },
  { DISTRICT: "Pakpattan", MIN: -0.1616, MAX: 0.8229, MEAN: 0.5035 },
  { DISTRICT: "Rahim Yar Khan", MIN: -0.1994, MAX: 0.8466, MEAN: 0.3914 },
  { DISTRICT: "Rajanpur", MIN: -0.2, MAX: 0.8916, MEAN: 0.2624 },
  { DISTRICT: "Rawalpindi", MIN: -0.0285, MAX: 0.9324, MEAN: 0.4906 },
  { DISTRICT: "Sahiwal", MIN: 0.0891, MAX: 0.815, MEAN: 0.5228 },
  { DISTRICT: "Sargodha", MIN: -0.0929, MAX: 0.9031, MEAN: 0.5367 },
  { DISTRICT: "Sheikhupura", MIN: -0.044, MAX: 0.9255, MEAN: 0.6021 },
  { DISTRICT: "Sialkot", MIN: -0.183, MAX: 0.9215, MEAN: 0.6161 },
  { DISTRICT: "Toba Tek Singh", MIN: 0.0192, MAX: 0.8897, MEAN: 0.5183 },
  { DISTRICT: "Vehari", MIN: -0.1919, MAX: 0.8747, MEAN: 0.5655 },
];

export const modis_sep30 = [
  { DISTRICT: "Attock", MIN: -0.1936, MAX: 0.8587, MEAN: 0.354 },
  { DISTRICT: "Bahawalnagar", MIN: -0.1952, MAX: 0.8257, MEAN: 0.4073 },
  { DISTRICT: "Bahawalpur", MIN: -0.1763, MAX: 0.8414, MEAN: 0.2564 },
  { DISTRICT: "Bhakkar", MIN: -0.199, MAX: 0.7179, MEAN: 0.2718 },
  { DISTRICT: "Chakwal", MIN: -0.1819, MAX: 0.8367, MEAN: 0.3524 },
  { DISTRICT: "Chiniot", MIN: -0.0152, MAX: 0.8019, MEAN: 0.5328 },
  { DISTRICT: "Dera Ghazi Khan", MIN: -0.1998, MAX: 0.7638, MEAN: 0.2752 },
  { DISTRICT: "Faisalabad", MIN: -0.1089, MAX: 0.794, MEAN: 0.4911 },
  { DISTRICT: "Gujranwala", MIN: -0.196, MAX: 0.8323, MEAN: 0.496 },
  { DISTRICT: "Gujrat", MIN: -0.1924, MAX: 0.8579, MEAN: 0.47 },
  { DISTRICT: "Hafizabad", MIN: -0.0574, MAX: 0.8352, MEAN: 0.6031 },
  { DISTRICT: "Jhang", MIN: -0.1958, MAX: 0.8079, MEAN: 0.4612 },
  { DISTRICT: "Jhelum", MIN: -0.1994, MAX: 0.8139, MEAN: 0.3961 },
  { DISTRICT: "Kasur", MIN: -0.1473, MAX: 0.8445, MEAN: 0.4923 },
  { DISTRICT: "Khanewal", MIN: -0.0484, MAX: 0.8083, MEAN: 0.5192 },
  { DISTRICT: "Khushab", MIN: -0.1973, MAX: 0.8176, MEAN: 0.362 },
  { DISTRICT: "Lahore", MIN: -0.1957, MAX: 0.8548, MEAN: 0.4264 },
  { DISTRICT: "Layyah", MIN: -0.2, MAX: 0.7914, MEAN: 0.302 },
  { DISTRICT: "Lodhran", MIN: -0.0882, MAX: 0.8384, MEAN: 0.556 },
  { DISTRICT: "Mandi Bahauddin", MIN: -0.1991, MAX: 0.836, MEAN: 0.5767 },
  { DISTRICT: "Mianwali", MIN: -0.2, MAX: 0.7754, MEAN: 0.313 },
  { DISTRICT: "Multan", MIN: -0.1695, MAX: 0.8055, MEAN: 0.4618 },
  { DISTRICT: "Muzaffargarh", MIN: -0.2, MAX: 0.8005, MEAN: 0.3824 },
  { DISTRICT: "Nankana Sahib", MIN: -0.1173, MAX: 0.8021, MEAN: 0.5541 },
  { DISTRICT: "Narowal", MIN: -0.051, MAX: 0.8332, MEAN: 0.5901 },
  { DISTRICT: "Okara", MIN: -0.1896, MAX: 0.7999, MEAN: 0.4699 },
  { DISTRICT: "Pakpattan", MIN: -0.1536, MAX: 0.7941, MEAN: 0.4882 },
  { DISTRICT: "Rahim Yar Khan", MIN: -0.1989, MAX: 0.8038, MEAN: 0.3612 },
  { DISTRICT: "Rajanpur", MIN: -0.1971, MAX: 0.7684, MEAN: 0.2426 },
  { DISTRICT: "Rawalpindi", MIN: -0.0515, MAX: 0.8742, MEAN: 0.4715 },
  { DISTRICT: "Sahiwal", MIN: 0.0883, MAX: 0.798, MEAN: 0.5199 },
  { DISTRICT: "Sargodha", MIN: -0.1518, MAX: 0.8364, MEAN: 0.4903 },
  { DISTRICT: "Sheikhupura", MIN: -0.1059, MAX: 0.8448, MEAN: 0.5334 },
  { DISTRICT: "Sialkot", MIN: -0.1922, MAX: 0.8536, MEAN: 0.5758 },
  { DISTRICT: "Toba Tek Singh", MIN: 0.0169, MAX: 0.8177, MEAN: 0.515 },
  { DISTRICT: "Vehari", MIN: -0.198, MAX: 0.8449, MEAN: 0.5712 },
];

export const modis_oct16 = [
  { DISTRICT: "Attock", MIN: -0.1965, MAX: 0.8768, MEAN: 0.3445 },
  { DISTRICT: "Bahawalnagar", MIN: -0.1381, MAX: 0.7628, MEAN: 0.351 },
  { DISTRICT: "Bahawalpur", MIN: -0.1841, MAX: 0.7781, MEAN: 0.2403 },
  { DISTRICT: "Bhakkar", MIN: -0.19, MAX: 0.7239, MEAN: 0.268 },
  { DISTRICT: "Chakwal", MIN: -0.1974, MAX: 0.8086, MEAN: 0.3379 },
  { DISTRICT: "Chiniot", MIN: -0.1423, MAX: 0.7256, MEAN: 0.4607 },
  { DISTRICT: "Dera Ghazi Khan", MIN: -0.2, MAX: 0.6869, MEAN: 0.251 },
  { DISTRICT: "Faisalabad", MIN: -0.1356, MAX: 0.7767, MEAN: 0.4447 },
  { DISTRICT: "Gujranwala", MIN: -0.1998, MAX: 0.7832, MEAN: 0.3877 },
  { DISTRICT: "Gujrat", MIN: -0.2, MAX: 0.8166, MEAN: 0.4404 },
  { DISTRICT: "Hafizabad", MIN: -0.1631, MAX: 0.7385, MEAN: 0.4482 },
  { DISTRICT: "Jhang", MIN: -0.171, MAX: 0.7133, MEAN: 0.386 },
  { DISTRICT: "Jhelum", MIN: -0.1986, MAX: 0.8169, MEAN: 0.3975 },
  { DISTRICT: "Kasur", MIN: -0.1834, MAX: 0.8268, MEAN: 0.4253 },
  { DISTRICT: "Khanewal", MIN: -0.0152, MAX: 0.7247, MEAN: 0.4192 },
  { DISTRICT: "Khushab", MIN: -0.1998, MAX: 0.7723, MEAN: 0.3498 },
  { DISTRICT: "Lahore", MIN: -0.1702, MAX: 0.8231, MEAN: 0.3963 },
  { DISTRICT: "Layyah", MIN: -0.1976, MAX: 0.6694, MEAN: 0.2815 },
  { DISTRICT: "Lodhran", MIN: 0.0091, MAX: 0.7645, MEAN: 0.4881 },
  { DISTRICT: "Mandi Bahauddin", MIN: -0.1985, MAX: 0.8146, MEAN: 0.5139 },
  { DISTRICT: "Mianwali", MIN: -0.1993, MAX: 0.7512, MEAN: 0.304 },
  { DISTRICT: "Multan", MIN: -0.0647, MAX: 0.7518, MEAN: 0.4198 },
  { DISTRICT: "Muzaffargarh", MIN: -0.1995, MAX: 0.75, MEAN: 0.3523 },
  { DISTRICT: "Nankana Sahib", MIN: -0.0446, MAX: 0.7297, MEAN: 0.4227 },
  { DISTRICT: "Narowal", MIN: -0.0042, MAX: 0.7938, MEAN: 0.5039 },
  { DISTRICT: "Okara", MIN: -0.1988, MAX: 0.7961, MEAN: 0.3811 },
  { DISTRICT: "Pakpattan", MIN: -0.1685, MAX: 0.7549, MEAN: 0.4226 },
  { DISTRICT: "Rahim Yar Khan", MIN: -0.1962, MAX: 0.7708, MEAN: 0.325 },
  { DISTRICT: "Rajanpur", MIN: -0.1961, MAX: 0.7579, MEAN: 0.2229 },
  { DISTRICT: "Rawalpindi", MIN: -0.0956, MAX: 0.8591, MEAN: 0.4419 },
  { DISTRICT: "Sahiwal", MIN: 0.0839, MAX: 0.7787, MEAN: 0.438 },
  { DISTRICT: "Sargodha", MIN: -0.1927, MAX: 0.8096, MEAN: 0.4494 },
  { DISTRICT: "Sheikhupura", MIN: -0.0844, MAX: 0.7429, MEAN: 0.4219 },
  { DISTRICT: "Sialkot", MIN: -0.1989, MAX: 0.8032, MEAN: 0.4813 },
  { DISTRICT: "Toba Tek Singh", MIN: 0.0564, MAX: 0.7556, MEAN: 0.4596 },
  { DISTRICT: "Vehari", MIN: -0.1419, MAX: 0.7953, MEAN: 0.5032 },
];

export const modis_nov01 = [
  { DISTRICT: "Attock", MIN: -0.1958, MAX: 0.857, MEAN: 0.3449 },
  { DISTRICT: "Bahawalnagar", MIN: -0.1355, MAX: 0.7294, MEAN: 0.2606 },
  { DISTRICT: "Bahawalpur", MIN: -0.1998, MAX: 0.7995, MEAN: 0.2102 },
  { DISTRICT: "Bhakkar", MIN: -0.1996, MAX: 0.7409, MEAN: 0.2445 },
  { DISTRICT: "Chakwal", MIN: -0.1831, MAX: 0.8394, MEAN: 0.3563 },
  { DISTRICT: "Chiniot", MIN: -0.1907, MAX: 0.776, MEAN: 0.4294 },
  { DISTRICT: "Dera Ghazi Khan", MIN: -0.1994, MAX: 0.6801, MEAN: 0.2343 },
  { DISTRICT: "Faisalabad", MIN: -0.0929, MAX: 0.7402, MEAN: 0.3995 },
  { DISTRICT: "Gujranwala", MIN: -0.1976, MAX: 0.7775, MEAN: 0.3104 },
  { DISTRICT: "Gujrat", MIN: -0.1988, MAX: 0.8348, MEAN: 0.3991 },
  { DISTRICT: "Hafizabad", MIN: -0.1676, MAX: 0.6557, MEAN: 0.3106 },
  { DISTRICT: "Jhang", MIN: -0.1885, MAX: 0.7609, MEAN: 0.3267 },
  { DISTRICT: "Jhelum", MIN: -0.2, MAX: 0.8115, MEAN: 0.3952 },
  { DISTRICT: "Kasur", MIN: -0.1718, MAX: 0.8441, MEAN: 0.4349 },
  { DISTRICT: "Khanewal", MIN: -0.1172, MAX: 0.7766, MEAN: 0.3263 },
  { DISTRICT: "Khushab", MIN: -0.1993, MAX: 0.7947, MEAN: 0.3178 },
  { DISTRICT: "Lahore", MIN: -0.1723, MAX: 0.8167, MEAN: 0.3775 },
  { DISTRICT: "Layyah", MIN: -0.1994, MAX: 0.694, MEAN: 0.2395 },
  { DISTRICT: "Lodhran", MIN: -0.1048, MAX: 0.7472, MEAN: 0.3288 },
  { DISTRICT: "Mandi Bahauddin", MIN: -0.1993, MAX: 0.7871, MEAN: 0.44 },
  { DISTRICT: "Mianwali", MIN: -0.1998, MAX: 0.7888, MEAN: 0.2796 },
  { DISTRICT: "Multan", MIN: -0.1107, MAX: 0.7851, MEAN: 0.3198 },
  { DISTRICT: "Muzaffargarh", MIN: -0.2, MAX: 0.8596, MEAN: 0.2939 },
  { DISTRICT: "Nankana Sahib", MIN: -0.0508, MAX: 0.7508, MEAN: 0.3243 },
  { DISTRICT: "Narowal", MIN: -0.0288, MAX: 0.7685, MEAN: 0.3896 },
  { DISTRICT: "Okara", MIN: -0.1975, MAX: 0.8197, MEAN: 0.3818 },
  { DISTRICT: "Pakpattan", MIN: -0.1377, MAX: 0.7196, MEAN: 0.3619 },
  { DISTRICT: "Rahim Yar Khan", MIN: -0.1991, MAX: 0.7404, MEAN: 0.286 },
  { DISTRICT: "Rajanpur", MIN: -0.1997, MAX: 0.7063, MEAN: 0.2093 },
  { DISTRICT: "Rawalpindi", MIN: -0.1285, MAX: 0.8706, MEAN: 0.4248 },
  { DISTRICT: "Sahiwal", MIN: -0.0291, MAX: 0.7599, MEAN: 0.3785 },
  { DISTRICT: "Sargodha", MIN: -0.1977, MAX: 0.7963, MEAN: 0.4262 },
  { DISTRICT: "Sheikhupura", MIN: -0.0761, MAX: 0.7785, MEAN: 0.3312 },
  { DISTRICT: "Sialkot", MIN: -0.1804, MAX: 0.8017, MEAN: 0.3913 },
  { DISTRICT: "Toba Tek Singh", MIN: 0.0439, MAX: 0.7329, MEAN: 0.38 },
  { DISTRICT: "Vehari", MIN: -0.1986, MAX: 0.7818, MEAN: 0.393 },
];

export const modis_nov17 = [
  { DISTRICT: "Attock", MIN: -0.1987, MAX: 0.8353, MEAN: 0.3601 },
  { DISTRICT: "Bahawalnagar", MIN: -0.036, MAX: 0.6677, MEAN: 0.264 },
  { DISTRICT: "Bahawalpur", MIN: -0.1226, MAX: 0.908, MEAN: 0.2209 },
  { DISTRICT: "Bhakkar", MIN: -0.1994, MAX: 0.7167, MEAN: 0.2517 },
  { DISTRICT: "Chakwal", MIN: -0.1908, MAX: 0.852, MEAN: 0.3602 },
  { DISTRICT: "Chiniot", MIN: -0.0812, MAX: 0.8213, MEAN: 0.418 },
  { DISTRICT: "Dera Ghazi Khan", MIN: -0.1996, MAX: 0.6564, MEAN: 0.2535 },
  { DISTRICT: "Faisalabad", MIN: -0.0351, MAX: 0.8195, MEAN: 0.386 },
  { DISTRICT: "Gujranwala", MIN: -0.1971, MAX: 0.7723, MEAN: 0.3223 },
  { DISTRICT: "Gujrat", MIN: -0.1991, MAX: 0.7937, MEAN: 0.3826 },
  { DISTRICT: "Hafizabad", MIN: -0.1393, MAX: 0.7045, MEAN: 0.3074 },
  { DISTRICT: "Jhang", MIN: -0.1935, MAX: 0.7654, MEAN: 0.3165 },
  { DISTRICT: "Jhelum", MIN: -0.1997, MAX: 0.8924, MEAN: 0.3948 },
  { DISTRICT: "Kasur", MIN: -0.0221, MAX: 0.8845, MEAN: 0.4346 },
  { DISTRICT: "Khanewal", MIN: -0.0826, MAX: 0.8171, MEAN: 0.3206 },
  { DISTRICT: "Khushab", MIN: -0.1947, MAX: 0.7863, MEAN: 0.3174 },
  { DISTRICT: "Lahore", MIN: -0.0787, MAX: 0.7641, MEAN: 0.3483 },
  { DISTRICT: "Layyah", MIN: -0.2, MAX: 0.7248, MEAN: 0.2532 },
  { DISTRICT: "Lodhran", MIN: 0.034, MAX: 0.8839, MEAN: 0.3393 },
  { DISTRICT: "Mandi Bahauddin", MIN: -0.1991, MAX: 0.795, MEAN: 0.3996 },
  { DISTRICT: "Mianwali", MIN: -0.2, MAX: 0.748, MEAN: 0.2886 },
  { DISTRICT: "Multan", MIN: -0.1532, MAX: 0.7983, MEAN: 0.3246 },
  { DISTRICT: "Muzaffargarh", MIN: -0.1994, MAX: 0.7604, MEAN: 0.3046 },
  { DISTRICT: "Nankana Sahib", MIN: -0.069, MAX: 0.7423, MEAN: 0.3506 },
  { DISTRICT: "Narowal", MIN: -0.0888, MAX: 0.7418, MEAN: 0.3134 },
  { DISTRICT: "Okara", MIN: -0.1622, MAX: 0.8732, MEAN: 0.4236 },
  { DISTRICT: "Pakpattan", MIN: 0.0033, MAX: 0.8051, MEAN: 0.3662 },
  { DISTRICT: "Rahim Yar Khan", MIN: -0.1892, MAX: 0.8677, MEAN: 0.2969 },
  { DISTRICT: "Rajanpur", MIN: -0.1978, MAX: 0.7206, MEAN: 0.2474 },
  { DISTRICT: "Rawalpindi", MIN: -0.1465, MAX: 0.9853, MEAN: 0.4349 },
  { DISTRICT: "Sahiwal", MIN: 0.0504, MAX: 0.8737, MEAN: 0.3747 },
  { DISTRICT: "Sargodha", MIN: -0.1752, MAX: 0.7745, MEAN: 0.4283 },
  { DISTRICT: "Sheikhupura", MIN: -0.0777, MAX: 0.726, MEAN: 0.3364 },
  { DISTRICT: "Sialkot", MIN: -0.1945, MAX: 0.782, MEAN: 0.3391 },
  { DISTRICT: "Toba Tek Singh", MIN: 0.0357, MAX: 0.7865, MEAN: 0.3725 },
  { DISTRICT: "Vehari", MIN: -0.1982, MAX: 0.8807, MEAN: 0.3752 },
];

export const modis_dec03 = [
  { DISTRICT: "Attock", MIN: -0.1991, MAX: 0.8459, MEAN: 0.3752 },
  { DISTRICT: "Bahawalnagar", MIN: -0.1729, MAX: 0.7736, MEAN: 0.3227 },
  { DISTRICT: "Bahawalpur", MIN: -0.1727, MAX: 0.8297, MEAN: 0.2417 },
  { DISTRICT: "Bhakkar", MIN: -0.1989, MAX: 0.7078, MEAN: 0.2841 },
  { DISTRICT: "Chakwal", MIN: -0.1922, MAX: 0.8636, MEAN: 0.3729 },
  { DISTRICT: "Chiniot", MIN: -0.096, MAX: 0.7877, MEAN: 0.4398 },
  { DISTRICT: "Dera Ghazi Khan", MIN: -0.1998, MAX: 0.7734, MEAN: 0.2853 },
  { DISTRICT: "Faisalabad", MIN: -0.108, MAX: 0.7724, MEAN: 0.4013 },
  { DISTRICT: "Gujranwala", MIN: -0.1956, MAX: 0.7734, MEAN: 0.423 },
  { DISTRICT: "Gujrat", MIN: -0.1946, MAX: 0.7768, MEAN: 0.4166 },
  { DISTRICT: "Hafizabad", MIN: -0.133, MAX: 0.7077, MEAN: 0.3539 },
  { DISTRICT: "Jhang", MIN: -0.1988, MAX: 0.7598, MEAN: 0.3625 },
  { DISTRICT: "Jhelum", MIN: -0.1914, MAX: 0.7816, MEAN: 0.3952 },
  { DISTRICT: "Kasur", MIN: -0.1512, MAX: 0.8229, MEAN: 0.4437 },
  { DISTRICT: "Khanewal", MIN: -0.1593, MAX: 0.7821, MEAN: 0.4226 },
  { DISTRICT: "Khushab", MIN: -0.1971, MAX: 0.7662, MEAN: 0.3263 },
  { DISTRICT: "Lahore", MIN: -0.1914, MAX: 0.7868, MEAN: 0.3587 },
  { DISTRICT: "Layyah", MIN: -0.1998, MAX: 0.7467, MEAN: 0.3034 },
  { DISTRICT: "Lodhran", MIN: -0.0553, MAX: 0.7797, MEAN: 0.4279 },
  { DISTRICT: "Mandi Bahauddin", MIN: -0.1909, MAX: 0.7916, MEAN: 0.4012 },
  { DISTRICT: "Mianwali", MIN: -0.2, MAX: 0.8075, MEAN: 0.3315 },
  { DISTRICT: "Multan", MIN: -0.1645, MAX: 0.7978, MEAN: 0.4023 },
  { DISTRICT: "Muzaffargarh", MIN: -0.2, MAX: 0.8041, MEAN: 0.3777 },
  { DISTRICT: "Nankana Sahib", MIN: -0.1358, MAX: 0.7773, MEAN: 0.3725 },
  { DISTRICT: "Narowal", MIN: -0.0433, MAX: 0.7416, MEAN: 0.3546 },
  { DISTRICT: "Okara", MIN: -0.1929, MAX: 0.8443, MEAN: 0.4746 },
  { DISTRICT: "Pakpattan", MIN: -0.0364, MAX: 0.8108, MEAN: 0.4364 },
  { DISTRICT: "Rahim Yar Khan", MIN: -0.1987, MAX: 0.8397, MEAN: 0.3425 },
  { DISTRICT: "Rajanpur", MIN: -0.1996, MAX: 0.852, MEAN: 0.2872 },
  { DISTRICT: "Rawalpindi", MIN: -0.1782, MAX: 0.985, MEAN: 0.4551 },
  { DISTRICT: "Sahiwal", MIN: -0.0664, MAX: 0.8001, MEAN: 0.4245 },
  { DISTRICT: "Sargodha", MIN: -0.1161, MAX: 0.7874, MEAN: 0.4403 },
  { DISTRICT: "Sheikhupura", MIN: -0.1694, MAX: 0.7651, MEAN: 0.3637 },
  { DISTRICT: "Sialkot", MIN: -0.1721, MAX: 0.7723, MEAN: 0.3773 },
  { DISTRICT: "Toba Tek Singh", MIN: -0.0785, MAX: 0.7953, MEAN: 0.4347 },
  { DISTRICT: "Vehari", MIN: -0.1758, MAX: 0.8113, MEAN: 0.4103 },
];
