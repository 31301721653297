import React, { useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { Card, Grid, Typography } from "@mui/material";
import useWindowDimensions from "../hooks/getScreenDimensions";
import "yet-another-react-lightbox/styles.css";
import Weather from '../../public/land/weather.png'
import Soil from '../../public/land/soil.png'
const choiceCard = [
  {
    id: 1,
    image: Weather,
    name: "Weather Maps",
  },
  {
    id : 2,
    image : Soil,
    name:"Soil Maps"
  }
  
];
export default function Choice({setChoice}) {
  const handlePopUpOpen = (card) => {
    setChoice(
      {
        id : card.id,
        name : card.name
      }
    )
  };
  const { width } = useWindowDimensions();

  const options = {
    margin: 10,
    responsive: {
      0: { items: 1, dots: false },
      500: { items: 2, dots: false },
      900: { items: 3, dots: false },
      1050: { items: 4 },
      2500: { items: 5 },
    },
  };
  return (
    <>
      <Grid container justifyContent="center" alignItems="center" mt={3}>
        <Grid item xs={12}></Grid>
        <Grid item xs={10}>
          <OwlCarousel className="owl-theme" {...options}>
            {choiceCard.map((card, index) => (
              <div
                key={card.id}
                className="item"
                style={{ cursor: "pointer" }}
                onClick={() => handlePopUpOpen(card)}
              >
                <Card
                  style={{
                    marginBottom: "10px",
                    height:
                      width < 800
                        ? "350px"
                        : width < 1800
                        ? "300px"
                        : width < 2500
                        ? "350px"
                        : "550px",
                  }}
                >
                  <img
                    src={card.image}
                    alt={`Image${card.id}`}
                    style={{
                      width: "100%",
                      height:
                        width < 800
                          ? "350px"
                          : width < 1800
                          ? "300px"
                          : width < 2500
                          ? "350px"
                          : "550px",
                    }}
                  />
                </Card>
                <Typography variant="h9" fontFamily="Poppins">
                  <strong>{card.name}</strong>
                </Typography>
              </div>
            ))}
          </OwlCarousel>
        </Grid>
      </Grid>
    </>
  );
}
