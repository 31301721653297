import * as React from 'react';
import { FormControl, FormLabel, Button } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { BASE_URL, END_POINT,getUserFarm } from "../utils/constants";
import InputMask from 'react-input-mask';
import Header from '../utils/Header'
import Footer from '../utils/Footer';
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Pagination } from "@mui/material";
import usePagination from "../utils/Pagination";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from "@mui/material";
import { Container } from "react-bootstrap";
import FarmRecommendation from '../../public/images/farm_recommendation.jpg';
import Tour from 'reactour';
import parse from 'html-react-parser';
import Title from "../utils/Title";
import DataTable from 'react-data-table-component';
import { MapContainer, TileLayer, Marker, Popup,  useMapEvents,useMap } from "react-leaflet";
const useStyles = makeStyles({
    formOverall: {
        borderRadius: "15px",
        width: "70%",
        padding: "50px",


        "@media (min-width: 960px)": {
            width: "50%",
        },
        "@media (min-width: 1280px)": {
            width: "30%",
        }
    },
    formControl: {
        border: "1px solid rgb(216, 216, 216)",
        borderRadius: "6px",
        padding: "7px 10px",
        color: "rgb(102, 116, 136)",
        fontSize: "14px",
        fontWeight: "400",
        height: "50px",
        lineHeight: "21px",
        marginBottom: "20px",
        fontFamily: "Poppins",
    },
    formLabel: {
        color: "rgb(37, 45, 64)",
        fontSize: "12px",
        fontWeight: "600",
        lineHeight: "18px",
        marginBottom: "10px",
        fontFamily: "Poppins",
    },
    redSpan: {
        color: "red",
    },
    captchaClass: {
        transform: "scale(0.7)",
        transformOrigin: "0 0",
        "@media (min-width: 960px)": {
            transform: "scale(0.75)",
        },
        "@media (min-width: 1280px)": {
            transform: "scale(1)",
        },
    },
});
export default function WheatRecommendation({
    location,
    area,
    onFormSubmit,
    boxShadow,
}) {
 
    const jsonData = {
        "status": true,
        "data": [
          {
            "data_id": 12,
            "kml_link": "https:\/\/limspakistan.com\/admin\/public\/uploads\/kml\/Farmer_Rawalpindi.kml",
            "farm_name": "Rawalpindi-Farmer",
            "farm_location": "{\"type\":\"Feature\",\"properties\":{},\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[73.075295,33.587409],[73.075295,33.58743],[73.07534,33.58743],[73.07534,33.587409],[73.075295,33.587409]]]}}",
            "additional_information": "{\"Farmer Name\":\"Farmer\",\"Farmer Contact\":\"03068994327\",\"Focal person\":\"Nabeek\",\"Focal Mobile\":\"03068994327\",\"District\":\"Rawalpindi\",\"Mouza\":\"Rawalpindi\",\"Tehsil\":\"Rawalpindi\",\"Sowing Data\":\"2023-10-12\"}",
            "created_at": "2023-10-12 10:02:04",
            "cnic": "37405-9670962-3"
          },
          {
            "data_id": 13,
            "kml_link": "https:\/\/limspakistan.com\/admin\/public\/uploads\/kml\/hammad_rwp.kml",
            "farm_name": "rwp-hammad",
            "farm_location": "{\"type\":\"Feature\",\"properties\":{},\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[73.075295,33.587409],[73.075295,33.58743],[73.07534,33.58743],[73.07534,33.587409],[73.075295,33.587409]]]}}",
            "additional_information": "{\"Farmer Name\":\"hammad\",\"Farmer Contact\":\"03068994327\",\"Focal person\":\"Nabeel\",\"Focal Mobile\":\"03068994327\",\"District\":\"rwp\",\"Mouza\":\"rwp\",\"Tehsil\":\"rwp\",\"Sowing Data\":\"2023-10-11\"}",
            "created_at": "2023-10-12 10:03:56",
            "cnic": "37405-9670962-3"
          }
        ]
      };
   
     

    const steps = [
        {
            selector: ".makeStyles-formControl-2",
            content:
                parse(`<span style="font-family:'Jameel Noori Nastaleeq Regular'; font-size:30px"}}>.مشورہ کے لئے اپنا شناختی کارڈ نمبر فراہم کریں</span>`),
        },
        {
            selector: ".MyBtn",
            content:
                parse(`<span style="font-family:'Jameel Noori Nastaleeq Regular'; font-size:30px"}}>.اس بٹن پر کلک کر کے,تصدیق کریں اور اپنے زرعی فارم کی مشورے دیکھیں</span>`),
        },

    ];

    const [isTourOpen, setIsTourOpen] = useState(true);
    const classes = useStyles();
    const [cnic, setCnic] = useState("");
    const [name, setName] = useState("");
    const [tableDisbaled, setTableDisabled] = useState(true);
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [myMessage, setMyMessage] = useState("");
    const [myCaptcha, setMyCaptcha] = useState(true);
    const [isVerified, setIsVerified] = useState(false);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState();
    const [open, setOpen] = React.useState(false);
    const cnicRegex = /^[0-9]{5}-[0-9]{7}-[0-9]$/;
    const nameRegex = /^[a-zA-Z\s]+$/;
    const mobileRegex = /^\d{11}$/;
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    useEffect(() => {
        setIsVerified(cnic && myCaptcha);
        

    }, [cnic,myCaptcha]);
 

    const validateFields = () => {
        if (!cnicRegex.test(cnic)) {
            return false;
        }     
        return true;
    };


    
    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    const handleSendMessage = () => {
       // setMyCaptcha(false);
        if (!validateFields()) {
            return;
        }
 
        const formData = new FormData(); 
        formData.append('cnic', cnic);  
        axios
            .post(BASE_URL + getUserFarm, formData)
            .then((response) => {
            
            const responseData = response.data;
        
            const myData = responseData.data.map(item => ({
                id: item.data_id,
                title: JSON.parse(item.additional_information)["Farmer Name"],
                agriofficer: <span style={{ fontFamily: 'Jameel Noori Nastaleeq Regular', fontSize: '18px' }}>  {JSON.parse(item.additional_information)["Focal person"] + " / "+JSON.parse(item.additional_information)["Focal Mobile"]} -زرعی افسر</span> ,
                location: JSON.parse(item.additional_information)["District"], 
                  lastOpened: <Button   
                      style={{
                          color: "white",
                          backgroundColor: "rgb(14, 151, 255)",
                          padding: "13px 15px",
                          marginTop: "20px",
                          width: "180px",
                          height: "40px",
                      }}
                      onClick={handleClickOpen}
                  >  
                      <strong style={{ fontFamily: 'Jameel Noori Nastaleeq Regular', fontSize: '22px' }}>زمین کی تیاری کے طریقے</strong>
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="10"
                          viewBox="0 0 24 10"
                          fill="none"
                          style={{ paddingLeft: "10px" }}
                      >
                          <path
                              d="M23.4133 3.6066L20.8333 0.999933C20.7714 0.937448 20.6976 0.887851 20.6164 0.854006C20.5351 0.82016 20.448 0.802734 20.36 0.802734C20.272 0.802734 20.1849 0.82016 20.1036 0.854006C20.0224 0.887851 19.9486 0.937448 19.8867 0.999933C19.7625 1.12484 19.6928 1.29381 19.6928 1.46993C19.6928 1.64606 19.7625 1.81502 19.8867 1.93993L22.26 4.33327H0.666667C0.489856 4.33327 0.320286 4.40351 0.195262 4.52853C0.0702377 4.65355 0 4.82312 0 4.99993C0 5.17674 0.0702377 5.34631 0.195262 5.47134C0.320286 5.59636 0.489856 5.6666 0.666667 5.6666H22.3L19.8867 8.07327C19.8242 8.13524 19.7746 8.20898 19.7407 8.29022C19.7069 8.37146 19.6895 8.45859 19.6895 8.5466C19.6895 8.63461 19.7069 8.72175 19.7407 8.80299C19.7746 8.88422 19.8242 8.95796 19.8867 9.01993C19.9486 9.08242 20.0224 9.13202 20.1036 9.16586C20.1849 9.19971 20.272 9.21713 20.36 9.21713C20.448 9.21713 20.5351 9.19971 20.6164 9.16586C20.6976 9.13202 20.7714 9.08242 20.8333 9.01993L23.4133 6.43327C23.7879 6.05827 23.9982 5.54994 23.9982 5.01993C23.9982 4.48993 23.7879 3.9816 23.4133 3.6066Z"
                              fill="#FFFFFF"
                          ></path>
                      </svg>
            
                  </Button>,
            
              }));
                   setData(myData);
              //   window.grecaptcha.reset();
              //   setMyCaptcha(false)
              //   resetFormFields();
              //   onFormSubmit(true);
              setColumns([  
                  {
                      name: 'Farmer Name',
                      selector: (row) => row.title,
                      sortable: true,
                      grow: 2,
                      style: {
                          color: '#202124',
                          fontSize: '14px',
                          fontWeight: 500,
                      },
                  },
                  {
                      name: 'Farmer Location',
                      selector: (row) => row.location,
                      sortable: true,
                      grow: 2,
                      style: {
                          color: '#202124',
                          fontSize: '14px',
                          fontWeight: 500,
                      },
                  },
                  {
                      name: 'Agri officer name / contact number',
                      selector: (row) => row.agriofficer,
                      sortable: true,
                      grow: 2,
                      style: {
                          color: '#202124',
                          fontSize: '14px',
                          fontWeight: 500,
                      },
                  },
                  {
                      name: 'Sowing Date',
                      selector: (row) => row.lastOpened,
                      sortable: true,
                      style: {
                          color: 'rgba(0,0,0,.54)',
                      },
                  },
                  // {
                  //     name: 'Check Advisory',
                  //     selector: (row) => row.lastOpened,
                  //     sortable: true,
                  //     style: {
                  //         color: 'rgba(0,0,0,.54)',
                  //     },
                  // },
                 
              ])
              setTableDisabled(false)
              //  console.log("DATa,",columns)
              //  console.log("DATa,",data)
            })
            .catch((error) => {
                console.log("ERROR",error)
               // onFormSubmit(false);
            });
    };
    const resetFormFields = () => {
        setCnic("");
        setName("");
        setMobile("");
        setEmail("");
        setMyMessage("");
        setMyCaptcha(false);
    };

    const customStyles = {
        rows: {
            style: {
                minHeight: '72px', // override the row height
              
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                fontSize:'18px',
                display:'none'
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };
    return (
        <>
            <Title title={'Wheat Farm Prepration'} />
            <Header />

            <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ direction: 'rtl'}}>
        <strong style={{ fontFamily: 'Jameel Noori Nastaleeq Regular', fontSize: '26px' }}>زمین کی تیاری کے طریقے</strong>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ direction: 'rtl'}}>
          <strong style={{ fontFamily: 'Jameel Noori Nastaleeq Regular', fontSize: '26px' }}>زمین کی تیاری کے طریقے</strong>
          <strong style={{ fontFamily: 'Jameel Noori Nastaleeq Regular', fontSize: '26px' }}>زمین کی تیاری کے طریقے</strong>
          <strong style={{ fontFamily: 'Jameel Noori Nastaleeq Regular', fontSize: '26px' }}>زمین کی تیاری کے طریقے</strong>
          <strong style={{ fontFamily: 'Jameel Noori Nastaleeq Regular', fontSize: '26px' }}>زمین کی تیاری کے طریقے</strong>
          <strong style={{ fontFamily: 'Jameel Noori Nastaleeq Regular', fontSize: '26px' }}>زمین کی تیاری کے طریقے</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ direction: 'rtl'}}>
          <Button onClick={handleClose}>Close</Button>
           
        </DialogActions>
      </Dialog>
            <Card className="bg-dark text-white" style={{ marginTop: "150px" }}>
                <Card.Img
                    src={FarmRecommendation}
                    alt="Farm Recommendation"
                    height="350px"
                    style={{
                        borderRadius: "0px",
                        background: "rgba(25, 54, 95, 0.8)",
                    }}
                />
                <Card.ImgOverlay>
                    <Card.Title style={{ color: "white" }}>
                        <Typography
                            fontFamily="Poppins"
                            variant="h3"
                            style={{
                                textAlign: "center",
                                display: "block",
                                fontSize: "40px",
                                lineHeight: "90px",
                                letterSpacing: ".01em",
                                fontWeight: 800,
                            }}
                            sx={{ marginTop: { xs: "100px", md: "100px" } }}
                        >
                            Farm{" "}
                            <span
                                style={{
                                    WebkitTextStroke: "1px",
                                    WebkitTextFillColor: "transparent",
                                }}
                            >
                            Recommendation
                            </span>
                        </Typography>
                    </Card.Title>
                    <Card.Text></Card.Text>

                </Card.ImgOverlay>
            </Card>

            <FormControl
                style={{
                    direction: 'rtl', margin: '0 auto',
                    display: 'grid',
                    padding: '2em',
                }}
                className={classes.formOverall}
            >
                <FormLabel className={classes.formLabel} style={{ direction: 'rtl' }}>
                    <strong style={{ fontFamily: 'Jameel Noori Nastaleeq Regular', lineHeight: '42px', fontSize: '32px' }}>شناختی کارڈ
                    <span className={classes.redSpan}>*</span></strong>
                </FormLabel>
                <InputMask
                    style={{ direction: 'ltr', }}
                    mask="99999-9999999-9"
                    placeholder="XXXXX-XXXXXXX-X"
                    className={`${classes.formControl} ${!cnicRegex.test(cnic) ? classes.redSpan : ""
                        }`}
                    value={cnic}
                    onChange={(e) => setCnic(e.target.value)}
                />
                <Button
                    disabled={!isVerified}
                    onClick={handleSendMessage}
                    className="MyBtn"
                    style={{

                        color: "white",
                        backgroundColor: isVerified
                            ? "rgb(14, 151, 255)"
                            : "rgb(171, 193, 209)",
                        padding: "13px 15px",
                        marginTop: "20px",
                        width: "180px",
                        height: "40px",
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="10"
                        viewBox="0 0 24 10"
                        fill="none"
                        style={{ paddingLeft: "10px" }}
                    >
                        <path
                            d="M23.4133 3.6066L20.8333 0.999933C20.7714 0.937448 20.6976 0.887851 20.6164 0.854006C20.5351 0.82016 20.448 0.802734 20.36 0.802734C20.272 0.802734 20.1849 0.82016 20.1036 0.854006C20.0224 0.887851 19.9486 0.937448 19.8867 0.999933C19.7625 1.12484 19.6928 1.29381 19.6928 1.46993C19.6928 1.64606 19.7625 1.81502 19.8867 1.93993L22.26 4.33327H0.666667C0.489856 4.33327 0.320286 4.40351 0.195262 4.52853C0.0702377 4.65355 0 4.82312 0 4.99993C0 5.17674 0.0702377 5.34631 0.195262 5.47134C0.320286 5.59636 0.489856 5.6666 0.666667 5.6666H22.3L19.8867 8.07327C19.8242 8.13524 19.7746 8.20898 19.7407 8.29022C19.7069 8.37146 19.6895 8.45859 19.6895 8.5466C19.6895 8.63461 19.7069 8.72175 19.7407 8.80299C19.7746 8.88422 19.8242 8.95796 19.8867 9.01993C19.9486 9.08242 20.0224 9.13202 20.1036 9.16586C20.1849 9.19971 20.272 9.21713 20.36 9.21713C20.448 9.21713 20.5351 9.19971 20.6164 9.16586C20.6976 9.13202 20.7714 9.08242 20.8333 9.01993L23.4133 6.43327C23.7879 6.05827 23.9982 5.54994 23.9982 5.01993C23.9982 4.48993 23.7879 3.9816 23.4133 3.6066Z"
                            fill="#FFFFFF"
                        ></path>
                    </svg>   <strong style={{ fontFamily: 'Jameel Noori Nastaleeq Regular', fontSize: '22px' }}>تصدیق کریں</strong>

                </Button>

                <Tour
                    backdrop={"static"}
                    steps={steps}
                    onRequestClose={() => setIsTourOpen(false)}
                    closeWithMask={false}
                    isOpen={isTourOpen} />

            </FormControl> 

   
            <DataTable
                disabled={tableDisbaled}
                direction="ltr"  
                columns={columns}
                data={data}
                pagination
                customStyles={customStyles}
                highlightOnHover
                pointerOnHover
            /> 
         
            <Footer />
        </>
    );
}