import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import * as XLSX from "xlsx";
import Header from "../utils/Header";
import FooterAgri from "../utils/FooterAgri";
import ImageWithOverlayPunjabAgri from "../utils/ImageWithOverlayPunjabAgri";
import AboutUs from "../../public/images/AboutUs.jpg";
import logoLeft from "../../public/images/limsLogoGroup.png";
import logoRight from "../../public/images/punjabAgriGroup.png";

import {
  modis_sep14,
  modis_sep30,
  modis_oct16,
  modis_nov01,
  modis_nov17,
  modis_dec03,
} from "./ModisData";

import modis14SepMap from "../../../src/public/punjabAgri/modis_14_Sep_2023.png";
import modis30SepMap from "../../../src/public/punjabAgri/modis_30_Sep_2023.png";
import modis16OctMap from "../../../src/public/punjabAgri/modis_16_Oct_2023.png";
import modis01NovMap from "../../../src/public/punjabAgri/modis_01_Nov_2023.png";
import modis17NovMap from "../../../src/public/punjabAgri/modis_17_Nov_2023.png";
import modis03DecMap from "../../../src/public/punjabAgri/modis_03_Dec_2023.png";
import modis14SepTable from "../../../src/public/punjabAgri/modis_table_14_Sep.png";
import modis30SepTable from "../../../src/public/punjabAgri/modis_table_30_Sep.png";
import modis16OctTable from "../../../src/public/punjabAgri/modis_table_16_Oct.png";
import modis01NovTable from "../../../src/public/punjabAgri/modis_table_01_Nov.png";
import modis17NovTable from "../../../src/public/punjabAgri/modis_table_17_Nov.png";
import modis03DecTable from "../../../src/public/punjabAgri/modis_table_03_Dec.png";
import Box from "@mui/material/Box";

import excelfile1 from "../../../src/public/excelFiles/lims_modis_sep14.xlsx";
import excelfile2 from "../../../src/public/excelFiles/lims_modis_sep30.xlsx";
import excelfile3 from "../../../src/public/excelFiles/lims_modis_oct16.xlsx";
import excelfile4 from "../../../src/public/excelFiles/lims_modis_nov01.xlsx";
import excelfile5 from "../../../src/public/excelFiles/lims_modis_nov17.xlsx";
import excelfile6 from "../../../src/public/excelFiles/lims_modis_dec03.xlsx";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function Modis(props) {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [width, setWidth] = useState(window.innerWidth);

  const [dataExcel1, setDataExcel1] = useState([]);
  const [dataExcel2, setDataExcel2] = useState([]);
  const [dataExcel3, setDataExcel3] = useState([]);
  const [dataExcel4, setDataExcel4] = useState([]);
  const [dataExcel5, setDataExcel5] = useState([]);
  const [dataExcel6, setDataExcel6] = useState([]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);

      // loadExcelFile();
      const readExcelFile1 = async () => {
        try {
          const response = await fetch(excelfile1);
          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          setDataExcel1(jsonData);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      };

      const readExcelFile2 = async () => {
        try {
          const response = await fetch(excelfile2);
          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          setDataExcel2(jsonData);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      };

      const readExcelFile3 = async () => {
        try {
          const response = await fetch(excelfile3);
          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          setDataExcel3(jsonData);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      };

      const readExcelFile4 = async () => {
        try {
          const response = await fetch(excelfile4);
          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          setDataExcel4(jsonData);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      };

      const readExcelFile5 = async () => {
        try {
          const response = await fetch(excelfile5);
          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          setDataExcel5(jsonData);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      };

      const readExcelFile6 = async () => {
        try {
          const response = await fetch(excelfile6);
          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          setDataExcel6(jsonData);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      };

      readExcelFile1();
      readExcelFile2();
      readExcelFile3();
      readExcelFile4();
      readExcelFile5();
      readExcelFile6();
    };
  }, []);

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [selectedMonth, setSelectedMonth] = useState("September");

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  // New Table Style
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const downloadCSV1 = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      modis_sep14.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "modis_sep14.csv");
    document.body.appendChild(link);
    link.click();
  };

  const downloadCSV2 = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      modis_sep30.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "modis_sep30.csv");
    document.body.appendChild(link);
    link.click();
  };

  const downloadCSV3 = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      modis_oct16.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "modis_oct16.csv");
    document.body.appendChild(link);
    link.click();
  };

  const downloadCSV4 = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      modis_nov01.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "modis_nov01.csv");
    document.body.appendChild(link);
    link.click();
  };

  const downloadCSV5 = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      modis_nov17.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "modis_nov17.csv");
    document.body.appendChild(link);
    link.click();
  };

  const downloadCSV6 = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      modis_dec03.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "modis_dec03.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <Header />
      <ImageWithOverlayPunjabAgri
        image={AboutUs}
        height="300px"
        title="Remote Sensing of Punjab"
        titlePartOne="Remote Sensing of"
        titlePartTwo="Punjab"
        logoLeft={logoLeft}
        logoRight={logoRight}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <img
              src={selectedImage}
              alt="Full Size"
              style={{ maxWidth: "100%" }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <div className="my-4 container">
        <select
          className={
            width < 768 ? "form-select w-50 mx-3" : "form-select w-25 mx-3"
          }
          value={selectedMonth}
          onChange={handleMonthChange}
        >
          <option value="September">September</option>
          <option value="October">October</option>
          <option value="November">November</option>
          <option value="December">December</option>
        </select>

        {selectedMonth === "September" ? (
          <div>
            {/* 14 Sep */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "25px",
              }}
            >
              <h2 style={{ marginTop: 0 }} className="mx-3">
                Modis NDVI for 14 Sep 2023
              </h2>
              <button
                variant="contained"
                color="primary"
                className="btn btn-primary"
                sx={{
                  backgroundColor: "#007bff",
                  "&:hover": { backgroundColor: "#0056b3" },
                }}
                onClick={downloadCSV1}
              >
                Download CSV
              </button>
            </Box>{" "}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div onClick={() => handleImageClick(modis14SepMap)}>
                  <img
                    src={modis14SepMap}
                    alt="Image 1"
                    style={{
                      maxWidth: "100%",
                      height: "670px",
                      cursor: "pointer",
                      width: "100%",
                    }}
                  />
                  {/* <Typography variant="body1">Landsat</Typography> */}
                </div>
              </Grid>

              {/* New excel table 1  */}
              <Grid item xs={12} md={6} className="mt-4">
                <div>
                  <TableContainer component={Paper} sx={{ height: 650 }}>
                    {modis_sep14.length > 0 ? (
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          height: 650,
                          overflowX: isLargeScreen ? "hidden" : "auto",
                        }} // aria-label="customized table"
                      >
                        <TableHead stickyHeader>
                          <TableRow>
                            {modis_sep14.length > 0 &&
                              Object.keys(modis_sep14[0]).map((key, index) => (
                                <StyledTableCell key={index}>
                                  {key}
                                </StyledTableCell>
                              ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {modis_sep14.length > 0 ? (
                            modis_sep14.map((row, index) => (
                              <StyledTableRow key={index}>
                                {Object.values(row).map((value, index) => (
                                  <StyledTableCell key={index}>
                                    {value}
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            ))
                          ) : (
                            <StyledTableRow>
                              <StyledTableCell
                                colSpan={Object.keys(modis_sep14[0]).length}
                              >
                                <Typography>Loading...</Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
            {/* 30 Sep */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                // marginTop: "35px",
              }}
              className="mt-5"
            >
              <h2 style={{ marginTop: 0 }} className="mx-3">
                Modis NDVI for 30 Sep 2023
              </h2>
              <button
                variant="contained"
                color="primary"
                className="btn btn-primary"
                sx={{
                  backgroundColor: "#007bff",
                  "&:hover": { backgroundColor: "#0056b3" },
                }}
                onClick={downloadCSV2}
              >
                Download CSV
              </button>
            </Box>{" "}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div onClick={() => handleImageClick(modis30SepMap)}>
                  <img
                    src={modis30SepMap}
                    alt="Image 1"
                    style={{
                      maxWidth: "100%",
                      height: "670px",
                      cursor: "pointer",
                      width: "100%",
                    }}
                  />
                  {/* <Typography variant="body1">Landsat</Typography> */}
                </div>
              </Grid>

              {/* New excel table 2  */}
              <Grid item xs={12} md={6} className="mt-4">
                <div>
                  <TableContainer component={Paper} sx={{ height: 650 }}>
                    {modis_sep30.length > 0 ? (
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          height: 650,
                          overflowX: isLargeScreen ? "hidden" : "auto",
                        }} // aria-label="customized table"
                      >
                        <TableHead stickyHeader>
                          <TableRow>
                            {modis_sep30.length > 0 &&
                              Object.keys(modis_sep30[0]).map((key, index) => (
                                <StyledTableCell key={index}>
                                  {key}
                                </StyledTableCell>
                              ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {modis_sep30.length > 0 ? (
                            modis_sep30.map((row, index) => (
                              <StyledTableRow key={index}>
                                {Object.values(row).map((value, index) => (
                                  <StyledTableCell key={index}>
                                    {value}
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            ))
                          ) : (
                            <StyledTableRow>
                              <StyledTableCell
                                colSpan={Object.keys(modis_sep30[0]).length}
                              >
                                <Typography>Loading...</Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : selectedMonth === "October" ? (
          <div>
            {/* 16 Oct */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                // marginTop: "35px",
              }}
              className="mt-5"
            >
              <h2 style={{ marginTop: 0 }} className="mx-3">
                Modis NDVI for 16 Oct 2023
              </h2>
              <button
                variant="contained"
                color="primary"
                className="btn btn-primary"
                sx={{
                  backgroundColor: "#007bff",
                  "&:hover": { backgroundColor: "#0056b3" },
                }}
                onClick={downloadCSV3}
              >
                Download CSV
              </button>
            </Box>{" "}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div onClick={() => handleImageClick(modis16OctMap)}>
                  <img
                    src={modis16OctMap}
                    alt="Image 1"
                    style={{
                      height: "670px",
                      maxWidth: "100%",
                      cursor: "pointer",
                      width: "100%",
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={6} className="mt-4">
                <div>
                  <TableContainer component={Paper} sx={{ height: 650 }}>
                    {modis_oct16.length > 0 ? (
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          height: 650,
                          overflowX: isLargeScreen ? "hidden" : "auto",
                        }} // aria-label="customized table"
                      >
                        <TableHead stickyHeader>
                          <TableRow>
                            {modis_oct16.length > 0 &&
                              Object.keys(modis_oct16[0]).map((key, index) => (
                                <StyledTableCell key={index}>
                                  {key}
                                </StyledTableCell>
                              ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {modis_oct16.length > 0 ? (
                            modis_oct16.map((row, index) => (
                              <StyledTableRow key={index}>
                                {Object.values(row).map((value, index) => (
                                  <StyledTableCell key={index}>
                                    {value}
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            ))
                          ) : (
                            <StyledTableRow>
                              <StyledTableCell
                                colSpan={Object.keys(modis_oct16[0]).length}
                              >
                                <Typography>Loading...</Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : selectedMonth === "November" ? (
          <div>
            {/* 01 Nov*/}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                // marginTop: "35px",
              }}
              className="mt-5"
            >
              <h2 style={{ marginTop: 0 }} className="mx-3">
                Modis NDVI for 01 Nov 2023
              </h2>
              <button
                variant="contained"
                color="primary"
                className="btn btn-primary"
                sx={{
                  backgroundColor: "#007bff",
                  "&:hover": { backgroundColor: "#0056b3" },
                }}
                onClick={downloadCSV4}
              >
                Download CSV
              </button>
            </Box>{" "}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div onClick={() => handleImageClick(modis01NovMap)}>
                  <img
                    src={modis01NovMap}
                    alt="Image 1"
                    style={{
                      height: "670px",
                      maxWidth: "100%",
                      cursor: "pointer",
                      width: "100%",
                    }}
                  />
                  {/* <Typography variant="body1">Landsat</Typography> */}
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div
                  className="mt-4 text-center"
                  // onClick={() => handleImageClick(modis01NovTable)}
                >
                  <TableContainer component={Paper} sx={{ height: 650 }}>
                    {modis_nov01.length > 0 ? (
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          height: 650,
                          overflowX: isLargeScreen ? "hidden" : "auto",
                        }} // aria-label="customized table"
                      >
                        <TableHead stickyHeader>
                          <TableRow>
                            {modis_nov01.length > 0 &&
                              Object.keys(modis_nov01[0]).map((key, index) => (
                                <StyledTableCell key={index}>
                                  {key}
                                </StyledTableCell>
                              ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {modis_nov01.length > 0 ? (
                            modis_nov01.map((row, index) => (
                              <StyledTableRow key={index}>
                                {Object.values(row).map((value, index) => (
                                  <StyledTableCell key={index}>
                                    {value}
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            ))
                          ) : (
                            <StyledTableRow>
                              <StyledTableCell
                                colSpan={Object.keys(modis_nov01[0]).length}
                              >
                                <Typography>Loading...</Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </TableContainer>
                  {/* <Typography variant="body1">Modis</Typography> */}
                </div>
              </Grid>
            </Grid>
            {/* 17Nov */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                // marginTop: "35px",
              }}
              className="mt-5"
            >
              <h2 style={{ marginTop: 0 }} className="mx-3">
                Modis NDVI for 17 Nov 2023
              </h2>
              <button
                variant="contained"
                color="primary"
                className="btn btn-primary"
                sx={{
                  backgroundColor: "#007bff",
                  "&:hover": { backgroundColor: "#0056b3" },
                }}
                onClick={downloadCSV5}
              >
                Download CSV
              </button>
            </Box>{" "}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div onClick={() => handleImageClick(modis17NovMap)}>
                  <img
                    src={modis17NovMap}
                    alt="Image 1"
                    style={{
                      height: "670px",
                      maxWidth: "100%",
                      cursor: "pointer",
                      width: "100%",
                    }}
                  />
                  {/* <Typography variant="body1">Landsat</Typography> */}
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div
                  className="mt-4  text-center"
                  // onClick={() => handleImageClick(modis17NovTable)}
                >
                  <TableContainer component={Paper} sx={{ height: 650 }}>
                    {modis_nov17.length > 0 ? (
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          height: 650,
                          overflowX: isLargeScreen ? "hidden" : "auto",
                        }} // aria-label="customized table"
                      >
                        <TableHead stickyHeader>
                          <TableRow>
                            {modis_nov17.length > 0 &&
                              Object.keys(modis_nov17[0]).map((key, index) => (
                                <StyledTableCell key={index}>
                                  {key}
                                </StyledTableCell>
                              ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {modis_nov17.length > 0 ? (
                            modis_nov17.map((row, index) => (
                              <StyledTableRow key={index}>
                                {Object.values(row).map((value, index) => (
                                  <StyledTableCell key={index}>
                                    {value}
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            ))
                          ) : (
                            <StyledTableRow>
                              <StyledTableCell
                                colSpan={Object.keys(modis_nov17[0]).length}
                              >
                                <Typography>Loading...</Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </TableContainer>
                  {/* <Typography variant="body1">Modis</Typography> */}
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div>
            {/* 03 Dec  */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                // marginTop: "35px",
              }}
              className="mt-5"
            >
              <h2 style={{ marginTop: 0 }} className="mx-3">
                Modis NDVI for 03 Dec 2023
              </h2>
              <button
                variant="contained"
                color="primary"
                className="btn btn-primary"
                sx={{
                  backgroundColor: "#007bff",
                  "&:hover": { backgroundColor: "#0056b3" },
                }}
                onClick={downloadCSV6}
              >
                Download CSV
              </button>
            </Box>{" "}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <div onClick={() => handleImageClick(modis03DecMap)}>
                  <img
                    src={modis03DecMap}
                    alt="Image 1"
                    style={{
                      height: "670px",
                      maxWidth: "100%",
                      cursor: "pointer",
                      width: "100%",
                    }}
                  />
                  {/* <Typography variant="body1">Landsat</Typography> */}
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div
                  className="mt-4  text-center"
                  // onClick={() => handleImageClick(modis03DecTable)}
                >
                  <TableContainer component={Paper} sx={{ height: 650 }}>
                    {modis_dec03.length > 0 ? (
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          height: 650,
                          overflowX: isLargeScreen ? "hidden" : "auto",
                        }} // aria-label="customized table"
                      >
                        <TableHead stickyHeader>
                          <TableRow>
                            {modis_dec03.length > 0 &&
                              Object.keys(modis_dec03[0]).map((key, index) => (
                                <StyledTableCell key={index}>
                                  {key}
                                </StyledTableCell>
                              ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {modis_dec03.length > 0 ? (
                            modis_dec03.map((row, index) => (
                              <StyledTableRow key={index}>
                                {Object.values(row).map((value, index) => (
                                  <StyledTableCell key={index}>
                                    {value}
                                  </StyledTableCell>
                                ))}
                              </StyledTableRow>
                            ))
                          ) : (
                            <StyledTableRow>
                              <StyledTableCell
                                colSpan={Object.keys(modis_dec03[0]).length}
                              >
                                <Typography>Loading...</Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </TableContainer>
                  {/* <Typography variant="body1">Modis</Typography> */}
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      <FooterAgri />
    </>
  );
}
