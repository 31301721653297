import Header from "../utils/Header";
import Footer from "../utils/Footer";
import AboutUs from "../../public/images/AboutUs.jpg";
import remote_sensing from "../../public/images/remote_sensing.jpeg";
import Card from "react-bootstrap/Card";
import { Grid, Typography } from "@mui/material";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Title from "../utils/Title";
 
export default function CompleteAbout() {
  
   useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <Header />
      {/* <ImageWithOverlay image={AboutUs} height="300px" title="About us!" /> */}

      <Card className="bg-dark text-white" style={{ marginTop: "100px" }}>
        <Card.Img src={AboutUs} alt="Card image" height={"300px"} />
        <Card.ImgOverlay
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(2, 15, 35, 0.5), rgba(2, 15, 35, 0.5))`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: 0,
          }}
        >
          <Card.Title style={{ color: "white" }}>
            <Typography
              fontFamily="Poppins"
              variant="h3"
              style={{
                textAlign: "center",
                display: "block",
                fontSize: "40px",
                lineHeight: "46px",
                letterSpacing: ".01em",
                fontWeight: 800,
              }}
              sx={{ marginTop: { xs: "100px", md: "100px" } }}
            >
              Crop Management Using{" "}
              <span
                style={{
                  WebkitTextStroke: "1px", // Use camelCase for CSS property names in inline styles
                  WebkitTextFillColor: "transparent", // Use camelCase for CSS property names in inline styles
                }}
              >
                Remote Sensing
              </span>
            </Typography>
          </Card.Title>
          <Card.Text></Card.Text>
        </Card.ImgOverlay>
      </Card>

      <Container fluid>
        <Title title={"Crop Monitoring"} />
        <Row style={{ marginTop: "30px" }}>
          <Col xs={12}>
            <Typography align="center" mt="10px" variant="h5">
              <strong>Introduction</strong>
            </Typography>
          </Col>
        </Row>
        <Row style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Col sx={12} style={{ display: "flex", justifyContent: "center" }}>
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "15px",
                lineHeight: "27px",
                textAlign: "center",
              }}
            >
              Remote sensing, the process of acquiring information about an
              object or phenomenon without direct physical contact, has emerged
              as a powerful tool for monitoring and managing agricultural
              activities. The agricultural sector has witnessed a
              transformational shift in farming practices in recent years.
              Farmers and agricultural stakeholders can make informed decisions,
              optimize resource allocation, and enhance productivity by
              extracting information from satellites, drones, and other sensing
              platforms. Following are a few of the benefits and applications of
              remote sensing in monitoring agriculture. <a href="https://limspakistan.com/agrimonitoring/img/Vegetation_Indices.pdf" target="_blank">Click here to read more about crop monitoring using remote sensing...</a>
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Typography align="center" mt="30px" variant="h5">
              <strong>The Power of Remote Sensing</strong>
            </Typography>
          </Col>
        </Row>
        <Row style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Col sx={12} style={{ display: "flex", justifyContent: "center" }}>
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "15px",
                lineHeight: "27px",
                textAlign: "center",
              }}
            >
              Remote sensing offers a wealth of advantages for monitoring
              agriculture. It enables the collection of data over large areas
              and provides near-real-time information, allowing for timely
              interventions. With various sensors capturing data across the
              electromagnetic spectrum, remote sensing can detect subtle changes
              in vegetation health, soil moisture, temperature, and more. This
              data is then processed and analyzed to generate actionable
              insights that guide agricultural activities. Sophisticated
              procedures have been and are being developed for transforming
              georeferenced data recorded by sensors into information that can
              be used in decision-making. Accuracies of these procedures depend
              upon image resolution, atmospheric and crop conditions, and the
              analytical techniques being employed, that is, deep learning,
              regression-based or simulations. The following figure presents
              optimal spatial and temporal resolution ranges along with spectral
              resolution (can be accessed through the platform) needed for
              precision agriculture and allied disciplines.

             <br/>

             <img src={remote_sensing} style={{width:'80vh'}} alt=""/>

            </p>
  
          </Col>
         
        </Row>
        <Row>
          <Col sx={12}>
            <Typography align="center" variant="h5" mt="50px">
              <strong>Crop Health Assessment</strong>
            </Typography>
          </Col>
        </Row>
        <Row style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Col sx={12} style={{ display: "flex", justifyContent: "center" }}>
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "15px",
                lineHeight: "27px",
                textAlign: "center",
              }}
            >
              One of the foremost applications of remote sensing in agriculture
              is crop health assessment. Satellite imagery and drone-based
              sensors can monitor plant vigor, detect stressors such as pests
              and diseases, and assess nutrient deficiencies. By identifying
              these issues early, farmers can implement targeted interventions,
              minimizing crop losses and reducing the need for indiscriminate
              pesticide use. This leads to improved sustainability and reduced
              environmental impact..
            </p>
          </Col>
        </Row>

        <Row>
          <Col sx={12}>
            <Typography align="center" variant="h5" mt="50px">
              <strong>Precision Agriculture</strong>
            </Typography>
          </Col>
        </Row>
        <Row style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Col sx={12} style={{ display: "flex", justifyContent: "center" }}>
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "15px",
                lineHeight: "27px",
                textAlign: "center",
              }}
            >
              Remote sensing plays a pivotal role in precision agriculture, a
              practice that tailors farming practices to specific field
              conditions. By analyzing data on vegetation condition, soil, and
              weather, farmers can precisely apply water, fertilizers, and
              pesticides only where, when and in the right amount needed. This
              results in optimal resource utilization, reduced input costs, and
              increased yield. Remote sensing technologies aid in creating
              detailed field maps, enabling farmers to understand variability
              and plan accordingly.
            </p>
          </Col>
        </Row>

        <Row>
          <Col sx={12}>
            <Typography align="center" variant="h5" mt="50px">
              <strong>Drought and Water Management</strong>
            </Typography>
          </Col>
        </Row>
        <Row style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Col sx={12} style={{ display: "flex", justifyContent: "center" }}>
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "15px",
                lineHeight: "27px",
                textAlign: "center",
              }}
            >
              Monitoring drought conditions and managing water resources are
              critical challenges in agriculture. Remote sensing can provide
              valuable insights into soil moisture levels and vegetation stress,
              helping farmers anticipate droughts and manage irrigation more
              efficiently. This proactive approach prevents overwatering,
              conserving water resources and ensuring the health of crops.{" "}
            </p>
          </Col>
        </Row>

        <Row>
          <Col sx={12}>
            <Typography align="center" variant="h5" mt="50px">
              <strong>Yield Estimation and Forecasting</strong>
            </Typography>
          </Col>
        </Row>
        <Row style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Col sx={12} style={{ display: "flex", justifyContent: "center" }}>
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "15px",
                lineHeight: "27px",
                textAlign: "center",
              }}
            >
              Estimating crop yield is essential for planning logistics,
              storage, and marketing strategies. Remote sensing aids in accurate
              yield estimation by tracking crop development, canopy cover, and
              biomass accumulation. When integrated with historical data and
              weather forecasts, this information enables farmers to make
              informed decisions about harvesting timelines and distribution
              strategies.
            </p>
          </Col>
        </Row>

        <Row>
          <Col sx={12}>
            <Typography align="center" variant="h5" mt="50px">
              <strong>Challenges and Future Directions</strong>
            </Typography>
          </Col>
        </Row>
        <Row style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Col sx={12} style={{ display: "flex", justifyContent: "center" }}>
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "15px",
                lineHeight: "27px",
                textAlign: "center",
              }}
            >
              While remote sensing holds immense potential for transforming
              agriculture, challenges persist. Interpreting complex data
              requires specialized knowledge, and access to technology might be
              limited in certain regions. Additionally, processing large
              datasets demands substantial computational resources. To address
              these challenges, ongoing research focuses on improving data
              processing techniques, developing user-friendly interfaces, and
              increasing accessibility to remote sensing tools. Furthermore,
              advancements in machine learning and artificial intelligence are
              being integrated to automate data analysis and enhance predictive
              capabilities.
            </p>
          </Col>
        </Row>

        <Row>
          <Col sx={12}>
            <Typography align="center" variant="h5" mt="50px">
              <strong>Conclusion</strong>
            </Typography>
          </Col>
        </Row>
        <Row style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Col sx={12} style={{ display: "flex", justifyContent: "center" }}>
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "15px",
                lineHeight: "27px",
                textAlign: "center",
              }}
            >
              Remote sensing has ushered in a new era of precision and
              data-driven decision-making in agriculture. By providing
              comprehensive insights into crop health, soil conditions, water
              management, and yield estimation, remote sensing empowers farmers
              and stakeholders to manage their operations more effectively. As
              technology continues to evolve and become more accessible, the
              potential for remote sensing to revolutionize agriculture and
              contribute to global food security becomes increasingly evident.  
              </p>
              
          </Col>
         
        </Row>
        {<a style={{textAlign: 'center',display: 'block'}} href="https://limspakistan.com/agrimonitoring/img/sample_report.pdf" target="_blank">Download Sample Report</a>}

      </Container>

      <Footer />
    </>
  );
}
