import React, { useState, useEffect,useRef  } from 'react';
import * as L from 'leaflet'
import { Grid, Typography,Alert } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import { GeoSearchControl, OpenStreetMapProvider, EsriProvider } from "leaflet-geosearch";
import LeafletgeoSearch from './LeafletgeoSearch';
import MapControls from './MapControls';
import { Modal, Button, Drawer, Space } from 'antd';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import ContactForm from '../contact/ContactForm';
import Tour from 'reactour'
import Title from '../utils/Title';
import { useNavigate } from 'react-router-dom'; 
import './font.css'
import parse from 'html-react-parser';

const position = [30.33084, 71.24750]

export const AdvisoryModal = NiceModal.create(({ location }) => {
  const modal = useModal();
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [message, setMessage] = useState('');
  const [severity,setSeverity] = useState('')
  const handleFormSubmit = (condition) => {
    if(condition){
      setMessage('Message sent successfully')
      setSeverity('success')
    }else{
      setMessage('Message sent failed.Please try again after 1 minutes')
      setSeverity('error')
    }
    setFormSubmitted(true);
  };
  useEffect(() => {
    if (isFormSubmitted) {
      const timeout = setTimeout(() => {
        setFormSubmitted(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [isFormSubmitted]);
 
    //console.log("KML",location)
    return (
      <Modal open={modal.visible}
      okButtonProps={{ style: { display: 'none' } }}  
      cancelText="Close"  
       title={" "} 
       width={1300} 
       onCancel={modal.hide} 
       afterClose={modal.remove}>
     

     <ContactForm  
           onFormSubmit={handleFormSubmit}
           boxShadow={false} 
           location={location} 
           area={'na'}/>

           {isFormSubmitted && <Alert severity={severity}
          role="presentation"
          sx={{
            width:{
              lg:'18%'
            },
            position: "fixed",
            bottom: "5%",
            left: "2%",
            zIndex: 1,
            animation: 'fade-out 5s forwards'
          }}
          >
            {message}</Alert> }
      </Modal>
    );
  });
  
function MapView() { 
    const steps1 = [
        {
          selector: '.leaflet-geosearch-button',
          content: 'Geo locate Farm if you have Lat/Lng or with district name. (\n \nکوارڈینیٹس یا ضلع / تحصیل کے نام کا استعمال کرکے اپنا کھیت تلاش کریں)',
        },
        {
          selector: '.leaflet-pm-toolbar',
          content: 'Draw map boundry using any of these tool.(\n\n\n\n\n\nاپنے فارم کی حدود لگائیں)',
        },
        {
          selector: '.leaflet-pm-icon-polygon',
          content: 'Recommended is to use polygon shape to draw exact boundry.(\n\n\n\n\n\nیہ ٹول کی نشاندہی کے لئے بہترین ہے۔)',
        },
        {
            selector: '.step4',
            content: 'After completing boundry marking, fill necessary details with your message and you will be contacted shortly.(\n\n\n\n\n\nنشاندہی مکمل ہونے کے بعد، ضروری تفصیلات کے ساتھ آپ کا پیغام بھریں اور آپ کو جلدی سے رابطہ کیا جائے گا۔)',
        },
      ];


      const steps = [
        {
          selector: ".leaflet-geosearch-button",
          content:
           parse(`<span style="font-family:'Jameel Noori Nastaleeq Regular'; font-size:20px"}}>Geo locate Farm if you have Lat/Lng or with district name.</br>کوارڈینیٹس یا ضلع / تحصیل کے نام کا استعمال کرکے اپنا کھیت تلاش کریں</span>`),
        },
        {
          selector: ".leaflet-pm-toolbar",
          content:
            parse(`<span style="font-family:'Jameel Noori Nastaleeq Regular'; font-size:20px"}}>Draw map boundry using any of these tool<br/>اپنے فارم کی حدود لگائیں</span>`),
        },
        {
          selector: ".leaflet-pm-icon-polygon",
          content:
            parse(`<span style="font-family:'Jameel Noori Nastaleeq Regular'; font-size:20px"}}>Recommended is to use polygon shape to draw exact boundry<br/>یہ ٹول نشاندہی کے لئے بہترین ہے</span>`),
        },
        {
          selector: ".step4",
          content:
            parse(`<span style="font-family:'Jameel Noori Nastaleeq Regular'; font-size:20px"}}>After completing boundry marking, fill necessary details with your message and you will be contacted shortly<br/>نشاندہی مکمل ہونے کے بعد، ضروری تفصیلات کے ساتھ آپ فارم بھریں اور آپ سے جلدی رابطہ کیا جائے گا۔</span>`),
        },
      ]; 
    const [isTourOpen, setIsTourOpen] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => {
      setModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setModalOpen(false);
    };

   
    useEffect(() => {
     
    }, [ ]);

  
    const navigate = useNavigate(); 

    const goBack = () => {
      navigate('/v2/home');
    };
    const handleSubmit = (e) => {
        e.preventDefault(); 
        console.log('Email:', email);
        console.log('Password:', password);
    };
 
    return (
        <> 
        <Title title={'Advisory Service'}/>
         <div
        style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          zIndex: 1000,
          display: 'flex',
          alignItems: 'center',cursor:'pointer',
          borderRadius:'10px',
          padding:'10px',
          background:'#31313196' 
        }}
      >
        
        <ArrowBackIcon
          style={{
            color: 'white',
            fontSize: '32px',  
            marginRight: '5px' 
          }}
        />
         
        <span  onClick={goBack} style={{ color: 'white', fontSize: '18px' }}>Go Back</span>
      </div>
        <MapContainer
            center={position}
            zoomControl={false}
            zoom={7}
            scrollWheelZoom={true}
            style={{ minHeight: "100vh", minWidth: "100vw" }}
        >    
            <TileLayer
                url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                maxZoom={20}
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            />
             {/* <ZoomControl position="bottomright" /> */}
            <LeafletgeoSearch className="step-1" />
            <MapControls modal={AdvisoryModal}/>
         </MapContainer>
            <Tour
                backdrop={"static"}
                steps={steps}
                onRequestClose={() => setIsTourOpen(false)}
                closeWithMask={false}
                isOpen={isTourOpen} />
        </>
    );
}
export default MapView;
