import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
export default function Date({dateFilter,setDateFilter}) {
  const handleChange = (event) => {
    setDateFilter(event.target.value);
  };
  return (
    <Box sx={{ width: 200 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Date</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={dateFilter}
          label="Date"
          onChange={handleChange}
        >
          <MenuItem value={'All'}>All</MenuItem>
          <MenuItem value={'10/3/2023 to 10/12/2023'}>10/3/2023 to 10/12/2023</MenuItem>
          <MenuItem value={'11/13/2023 to 11/20/2023'}>11/13/2023 to 11/20/2023</MenuItem>
          <MenuItem value={'11/21/2023 to 11/27/2023'}>11/21/2023 to 11/27/2023</MenuItem>
          <MenuItem value={'11/28/2023 to 12/4/2023'}>11/28/2023 to 12/4/2023</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}