import React from "react";
import useWindowDimensions from "../hooks/getScreenDimensions";
import { Box } from "@mui/material";

export default function CustomGallery({ images, onClick, flexBasis }) {
  const { width } = useWindowDimensions();
  const handleImageClick = (index) => {
    if (onClick) {
      onClick(index);
    }
  };

  return (
    <ul
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "start",
        alignItems: "center",
        paddingInlineStart: "0px",
      }}
    >
      {images.map((image, index) => (
        <li
          key={index}
          onClick={() => handleImageClick(index)}
          style={{
            cursor: "pointer",
            position: "relative",
            listStyle: "none",
            padding: 1,
            flexBasis:
              width <= 650
                ? "100%"
                : width <= 1200
                ? "50%"
                : width <= 1800
                ? flexBasis
                : "25%",
          }}
        >
          <img
            style={{
              objectFit: "cover",
              maxWidth: "100%",
              verticalAlign: "middle",
            }}
            src={image.src}
            alt={`Image ${index + 1}`}
            loading={"lazy"}
          />
          <Box
            style={{
              fontSize: "14px",
              fontFamily: "Poppins",
              position: "absolute",
              bottom: 10,
              left: 12,
              padding: "8px",
              background: "rgba(0, 0, 0, 0.65)",
              color: "yellow",
              borderRadius: "10px",
            }}
          >
            {image.tags[0].value}
          </Box>
        </li>
      ))}
    </ul>
  );
}
